<div [ngClass]="theme.getThemeClass(true)" class="notifications">
  <section [ngClass]="theme.getThemeClass(true)" class="notification-list">
    <article
      [ngClass]="theme.getThemeClass(true)"
      [ngClass]="(notification.status == NotificationStatuses.SEEN) ? 'unread' : ''"
      class="notification"
      (click)="setNotificationAsRead(notification.id)"
      *ngFor="let notification of this.notificationService.notifications"
    >
      <div class="notification-icon">
        <img [src]="notification.icon" alt="Notification Icon" class="small-icon">
        <!-- Apply the 'small-icon' class directly to the img element -->
      </div>
      <div [ngClass]="theme.getThemeClass(true)" class="notification-content">
        <h2 [ngClass]="theme.getThemeClass(true)">{{ notification.title }}</h2>
        <p [ngClass]="theme.getThemeClass(true)">{{ notification.body }}</p>
        <p [ngClass]="theme.getThemeClass(true)">{{ getTimestampDisplay(notification.timestamp) }}</p>
      </div>
    </article>
  </section>
</div>

