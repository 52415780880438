import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { CognitoService } from '../service/cognito.service';
import { LocalStorageService } from '../local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../service/theme.service';
import { SystemMessageService } from '../service/system-message.service';
import { ErrorModalService } from '../service/error-modal.service';
import { environment } from '../environments/environment';
import { DashboardService } from '../service/dashboard.service';
import { DriverService, Route } from '../service/driver.service';

@Component({
  selector: 'driver-active-route',
  templateUrl: './driver-active-route.component.html',
  styleUrl: './driver-active-route.component.css'
})
export class DriverActiveRouteComponent implements OnInit{
  routes:Route[] = [];      //Array of routes
  routeNum:number = 0;      //Number of routes
  routeSelected = false;    //Selected route status


  constructor(
    public cognitoService: CognitoService,
    private localStorageService: LocalStorageService,
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    public theme: ThemeService,
    public systemMessage: SystemMessageService,
    public dashboard: DashboardService,
    private errorModal: ErrorModalService,
    public DriverService: DriverService
  ){

  }

  ngOnInit(){
    //initialize the route array
    this.routes = [
      {
        routeID: 'WC001',
        name: 'Residential Area',
        date: '22/07/24',
        driver: 'Jane Smith',
        stops: [ {
          stopNum: 1,
          client: 'Client A',
          device: 'MWS-BO1-001',
          fillRate: 0.8,
          address: '123 Raya Lucaria St'
        },
        {
          stopNum: 2,
          client: 'Client B',
          device: 'MWS-BO1-002',
          fillRate: 0.6,
          address: '456 Elm St'
        },
        {
          stopNum: 3,
          client: 'Client C',
          device: 'MWS-BO1-003',
          fillRate: 0.4,
          address: '789 Oak St'
        },
        {
          stopNum: 4,
          client: 'Client D',
          device: 'MWS-BO1-004',
          fillRate: 0.9,
          address: '321 Pine St'
        },
        {
          stopNum: 5,
          client: 'Client E',
          device: 'MWS-BO1-005',
          fillRate: 0.7,
          address: '654 Cedar St'
        },
        {
          stopNum: 6,
          client: 'Client F',
          device: 'MWS-BO1-006',
          fillRate: 0.7,
          address: '654 Cedar St'
        }],
        distance: 15,
        routeUrl: '/my-routes/WC001'
      },
      {
        routeID: 'WC002',
        name: 'Commercial District',
        date: '25/07/24',
        driver: 'Jane Smith',
        stops: [ {
          stopNum: 1,
          client: 'Client A',
          device: 'MWS-001',
          fillRate: 0.8,
          address: '123 Main St'
        },
        {
          stopNum: 2,
          client: 'Client B',
          device: 'MWS-002',
          fillRate: 0.6,
          address: '456 Elm St'
        },
        {
          stopNum: 3,
          client: 'Client C',
          device: 'MWS-003',
          fillRate: 0.4,
          address: '789 Oak St'
        },
        {
          stopNum: 4,
          client: 'Client D',
          device: 'MWS-004',
          fillRate: 0.9,
          address: '321 Pine St'
        },
        {
          stopNum: 5,
          client: 'Client E',
          device: 'MWS-005',
          fillRate: 0.7,
          address: '654 Cedar St'
        }],
        distance: 10,
        routeUrl: '/my-routes/WC002'
      },
      {
        routeID: 'WC003',
        name: 'Industrial Zone',
        date: '01/08/24',
        driver: 'Jane Smith',
        stops: [ {
          stopNum: 1,
          client: 'Client A',
          device: 'MWS-001',
          fillRate: 0.8,
          address: '123 Main St'
        },
        {
          stopNum: 2,
          client: 'Client B',
          device: 'MWS-002',
          fillRate: 0.6,
          address: '456 Elm St'
        },
        {
          stopNum: 3,
          client: 'Client C',
          device: 'MWS-003',
          fillRate: 0.4,
          address: '789 Oak St'
        },
        {
          stopNum: 4,
          client: 'Client D',
          device: 'MWS-004',
          fillRate: 0.9,
          address: '321 Pine St'
        },
        {
          stopNum: 5,
          client: 'Client E',
          device: 'MWS-005',
          fillRate: 0.7,
          address: '654 Cedar St'
        }],
        distance: 20,
        routeUrl: '/my-routes/WC003'
      },
      {
        routeID: 'WC004',
        name: 'Hazardous Waste',
        date: '13/07/24',
        driver: 'Jane Smith',
        stops: [ {
          stopNum: 1,
          client: 'Client A',
          device: 'MWS-001',
          fillRate: 0.8,
          address: '123 Main St'
        },
        {
          stopNum: 2,
          client: 'Client B',
          device: 'MWS-002',
          fillRate: 0.6,
          address: '456 Elm St'
        },
        {
          stopNum: 3,
          client: 'Client C',
          device: 'MWS-003',
          fillRate: 0.4,
          address: '789 Oak St'
        },
        {
          stopNum: 4,
          client: 'Client D',
          device: 'MWS-004',
          fillRate: 0.9,
          address: '321 Pine St'
        },
        {
          stopNum: 5,
          client: 'Client E',
          device: 'MWS-005',
          fillRate: 0.7,
          address: '654 Cedar St'
        }],
        distance: 12,
        routeUrl: '/my-routes/WC004'
      },
      {
        routeID: 'WC005',
        name: 'Weekend Cleanup',
        date: '07/07/24',
        driver: 'Jane Smith',
        stops: [ {
          stopNum: 1,
          client: 'Client A',
          device: 'MWS-001',
          fillRate: 0.8,
          address: '123 Main St'
        },
        {
          stopNum: 2,
          client: 'Client B',
          device: 'MWS-002',
          fillRate: 0.6,
          address: '456 Elm St'
        },
        {
          stopNum: 3,
          client: 'Client C',
          device: 'MWS-003',
          fillRate: 0.4,
          address: '789 Oak St'
        },
        {
          stopNum: 4,
          client: 'Client D',
          device: 'MWS-004',
          fillRate: 0.9,
          address: '321 Pine St'
        },
        {
          stopNum: 5,
          client: 'Client E',
          device: 'MWS-005',
          fillRate: 0.7,
          address: '654 Cedar St'
        }],
        distance: 18,
        routeUrl: '/my-routes/WC005'
      }


      ,{
        routeID: 'WC006',
        name: 'Residential Area',
        date: '08/07/24',
        driver: 'Jane Smith',
        stops: [ {
          stopNum: 1,
          client: 'Client A',
          device: 'MWS-001',
          fillRate: 0.8,
          address: '123 Main St'
        },
        {
          stopNum: 2,
          client: 'Client B',
          device: 'MWS-002',
          fillRate: 0.6,
          address: '456 Elm St'
        },
        {
          stopNum: 3,
          client: 'Client C',
          device: 'MWS-003',
          fillRate: 0.4,
          address: '789 Oak St'
        },
        {
          stopNum: 4,
          client: 'Client D',
          device: 'MWS-004',
          fillRate: 0.9,
          address: '321 Pine St'
        },
        {
          stopNum: 5,
          client: 'Client E',
          device: 'MWS-005',
          fillRate: 0.7,
          address: '654 Cedar St'
        }],
        distance: 15,
        routeUrl: '/my-routes/WC006'
      },
      {
        routeID: 'WC007',
        name: 'Commercial District',
        date: '29/07/24',
        driver: 'Jane Smith',
        stops: [ {
          stopNum: 1,
          client: 'Client A',
          device: 'MWS-001',
          fillRate: 0.8,
          address: '123 Main St'
        },
        {
          stopNum: 2,
          client: 'Client B',
          device: 'MWS-002',
          fillRate: 0.6,
          address: '456 Elm St'
        },
        {
          stopNum: 3,
          client: 'Client C',
          device: 'MWS-003',
          fillRate: 0.4,
          address: '789 Oak St'
        },
        {
          stopNum: 4,
          client: 'Client D',
          device: 'MWS-004',
          fillRate: 0.9,
          address: '321 Pine St'
        },
        {
          stopNum: 5,
          client: 'Client E',
          device: 'MWS-005',
          fillRate: 0.7,
          address: '654 Cedar St'
        }],
        distance: 10,
        routeUrl: '/my-routes/WC007'
      },
      {
        routeID: 'WC008',
        name: 'Industrial Zone',
        date: '05/08/24',
        driver: 'Jane Smith',
        stops: [ {
          stopNum: 1,
          client: 'Client A',
          device: 'MWS-001',
          fillRate: 0.8,
          address: '123 Main St'
        },
        {
          stopNum: 2,
          client: 'Client B',
          device: 'MWS-002',
          fillRate: 0.6,
          address: '456 Elm St'
        },
        {
          stopNum: 3,
          client: 'Client C',
          device: 'MWS-003',
          fillRate: 0.4,
          address: '789 Oak St'
        },
        {
          stopNum: 4,
          client: 'Client D',
          device: 'MWS-004',
          fillRate: 0.9,
          address: '321 Pine St'
        },
        {
          stopNum: 5,
          client: 'Client E',
          device: 'MWS-005',
          fillRate: 0.7,
          address: '654 Cedar St'
        }],
        distance: 20,
        routeUrl: '/my-routes/WC008'
      },
      {
        routeID: 'WC009',
        name: 'Hazardous Waste',
        date: '31/07/24',
        driver: 'Jane Smith',
        stops: [ {
          stopNum: 1,
          client: 'Client A',
          device: 'MWS-001',
          fillRate: 0.8,
          address: '123 Main St'
        },
        {
          stopNum: 2,
          client: 'Client B',
          device: 'MWS-002',
          fillRate: 0.6,
          address: '456 Elm St'
        },
        {
          stopNum: 3,
          client: 'Client C',
          device: 'MWS-003',
          fillRate: 0.4,
          address: '789 Oak St'
        },
        {
          stopNum: 4,
          client: 'Client D',
          device: 'MWS-004',
          fillRate: 0.9,
          address: '321 Pine St'
        },
        {
          stopNum: 5,
          client: 'Client E',
          device: 'MWS-005',
          fillRate: 0.7,
          address: '654 Cedar St'
        }],
        distance: 12,
        routeUrl: '/my-routes/WC009'
      },
      {
        routeID: 'WC010',
        name: 'Weekend Cleanup',
        date: '03/08/24',
        driver: 'Jane Smith',
        stops: [ {
          stopNum: 1,
          client: 'Client A',
          device: 'MWS-001',
          fillRate: 0.8,
          address: '123 Main St'
        },
        {
          stopNum: 2,
          client: 'Client B',
          device: 'MWS-002',
          fillRate: 0.6,
          address: '456 Elm St'
        },
        {
          stopNum: 3,
          client: 'Client C',
          device: 'MWS-003',
          fillRate: 0.4,
          address: '789 Oak St'
        },
        {
          stopNum: 4,
          client: 'Client D',
          device: 'MWS-004',
          fillRate: 0.9,
          address: '321 Pine St'
        },
        {
          stopNum: 5,
          client: 'Client E',
          device: 'MWS-005',
          fillRate: 0.7,
          address: '654 Cedar St'
        }],
        distance: 18,
        routeUrl: '/my-routes/WC010'
      }
    ];
    this.routeNum = this.routes.length
  }
/**
 * Function that selects a route on user click on list element
 * @param routeID the ID if the selected route
 * */

  selectRoute(routeID:string){
    this.DriverService.selectedRoute = this.routes.find(route => route.routeID === routeID) as Route //Find and select correct route
    console.log(this.DriverService.selectedRoute)
    this.DriverService.isRouteSelected= true;                                                        //route is selected
  }
}
