<mat-accordion [multi]="true" class="progress-tracker">
  <mat-expansion-panel
    *ngFor="let group of taskGroups; let i = index"
    [expanded]="getGroupStatus(group.tasks) == TaskStatus.InProgress"
    [ngClass]="themeService.getThemeClass()"
    class="task-group">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon [ngClass]="getTaskStatusClass(getGroupStatus(group.tasks))">
          {{ getTaskStatusIcon(getGroupStatus(group.tasks)) }}
        </mat-icon>
      </mat-panel-title>
      <mat-panel-description [ngClass]="themeService.getThemeClass()">
        {{ group.name$ | async }}
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div
      *ngFor="let task of group.tasks"
      [ngClass]="[themeService.getThemeClass(), getTaskStatusClass(task.status)]"
      class="individual-task">
      <div class="task-title">
        <mat-icon [ngClass]="getTaskStatusClass(task.status)">
          {{ getTaskStatusIcon(task.status) }}
        </mat-icon>
        <span>
            {{ task.name$ | async }}
        </span>
      </div>
      <!-- Hide description if completed or if end task (unless end task is in-progress) -->
      <div class="task-description" *ngIf="task.status != 'C' && !(task.id.startsWith('_END') && task.status != 'I')">
        <span [innerHTML]="task.description$ | async"></span>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
