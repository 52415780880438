import { Component, Inject, forwardRef, OnInit } from '@angular/core';
import { RoleService } from '../service/role.service';
import { CognitoService } from '../service/cognito.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../local-storage.service';
import { ClientService } from '../service/client.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from '../service/validation.service';
import { Router } from '@angular/router';
import { ThemeService } from '../service/theme.service';
import { NavigationService } from '../service/navigation.service';
import { SystemMessageService } from '../service/system-message.service';
import { LocalizationService } from '../service/localization.service';
import { TruckBrands } from '../constants/truck-brands';
import { TruckTypes } from '../constants/truck-types';
import { TruckStatus } from '../constants/truck-status';
import { TruckService } from '../service/truck.service';
import { OperatorService, Operator } from '../service/operator.service';
import { environment } from '../environments/environment';


@Component({
  selector: 'operator-create-truck',
  templateUrl: './operator-create-truck.component.html',
  styleUrls: ['./operator-create-truck.component.css', '../../global-elements.css']
})
export class OperatorCreateTruckComponent implements OnInit {
  public form: FormGroup;
  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

   truckBrands = TruckBrands; //list of truck brands not to be confused with truckBrand (singular)
   truckTypes = TruckTypes; //list of truck types not to be confused with truckType (singular)
   truckStatuses = TruckStatus;//list of truck status not to be confused with truckStatus (singular)
   maxTruckYear = new Date().getFullYear() + 2;// Maximum year number for truck input

   operatorArray: Operator[] = [];

  constructor(
    private localStorageService: LocalStorageService,
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    public cognitoService: CognitoService,
    public roleService: RoleService,
    public clientService: ClientService,
    private formBuilder: FormBuilder,
    public validationService: ValidationService,
    private router: Router,
    public theme: ThemeService,
    public navigationService: NavigationService,
    public systemMessageService: SystemMessageService,
    public localizationService: LocalizationService,
    public truckService: TruckService,
    private operatorService: OperatorService
  ) {

    // Create a form using the FormBuilder
    this.form = this.formBuilder.group({
      // Define form controls and their initial values
      client_name: ['', Validators.required],
      legal_name: [''],
      email: ['', [Validators.required, Validators.email]],
      phone_number: [''],
      address: ['']
    });

    // Reset the truck object when the form is created or reset
    this.truckService.truck = {
      truck_id: '',
      operator_id: '',
      driver_id: '',
      nickname: '',
      vin: '',
      brand: '',
      type: '',
      size: 0,
      capacity: 0,
      year: 0,
      status: '',
      username: '',
      routes: [],
    }

    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null){
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
    this.systemMessageService.buttonBlocked = false;
  }

  async ngOnInit(){
    // Get user type, roles, confir that user is valid in cognito
    await this.cognitoService.getUserType();
    await this.cognitoService.confirmValidUser();
    await this.roleService.getRoles();

    // Check with user roles and type if he's alowed in that page, if not he'll be redirect to his dashboard
    await this.cognitoService.getCurrentRole([environment.users.role.administrator, environment.users.role.operations],
                                              [environment.users.superAdmin, environment.users.maintenance],
                                              true, this.roleService.roles);

    // Set the operator array used in select operator options for muirwood use
    this.operatorArray = await this.operatorService.getOperators();
  }

  /**
   * Function that takes you to previous page
   */
  toPreviousPage(){
    this.systemMessageService.buttonBlocked = false;
    const from = sessionStorage.getItem('createTruck') //get route to previous page (truck list componenent)
    if(from){
      //navigate back to truck-list if route exist
      this.router.navigate(['/truck-list'])
    }else{
      //navigate to dashboard
      this.router.navigate(['/admin']);
    }
    sessionStorage.removeItem('createTruck')
  }

  /**
   * Function that disable the create truck button after first click
   */
  disableButton(){
    this.systemMessageService.buttonBlocked = true;
  }

/**
 * Function that creates a new truck
 */
  async createTruck(){
    await this.checkInputs();
    if(
      this.validationService.truckNivValid   &&
      this.validationService.truckNicknameValid   &&
      this.validationService.truckBrandValid &&
      this.validationService.truckTypeValid  &&
      this.validationService.truckSizeValid  &&
      this.validationService.truckCapacityValid &&
      this.validationService.truckYearValid &&
      this.validationService.truckStatusValid
    ){
      try {
        this.truckService.createTruck();
        this.toPreviousPage();
      } catch (error) {
        console.log('Something went wrong while creating the truck',error);
      }
    }
  }

  checkYear(event: any): void {
    const selectedYear = event.target.value;

    // Perform validation for truck year
    if (selectedYear < 1900 || selectedYear > this.maxTruckYear) {
      switch(true){
        case selectedYear < 1900:
           this.truckService.truck.year = 1900
           break;
        case selectedYear > this.maxTruckYear:
          this.truckService.truck.year = this.maxTruckYear
           break;
      }
    }
  }

 // Function that triggers input validation
  async checkInputs(){
    // Condition that are promises goes in here
    await Promise.all(
      [this.validationService.validateTruckNIV(this.truckService.truck.vin, this.truckService.truck.truck_id)]
    );
    this.validationService.validateTruckNickname(this.truckService.truck.nickname);
    this.validationService.validateTruckBrand(this.truckService.truck.brand);
    this.validationService.validateTruckType(this.truckService.truck.type);
    this.validationService.validateTruckSize(this.truckService.truck.size);
    this.validationService.validateTruckCapacity(this.truckService.truck.capacity);
    this.validationService.validateTruckYear(this.truckService.truck.year);
    this.validationService.validateTruckStatus(this.truckService.truck.status);




    // Will send an error to the muirwood user if he doesn't select an operator and put the operator_id of the user if he's not a muirwood
    if(this.cognitoService.userType === 'muirwood' && this.truckService.truck.operator_id === ''){
      this.systemMessageService.selectRibbon('danger', 'needToSelectOperator');
    }else if(this.cognitoService.userType !== 'muirwood'){
      this.truckService.truck.operator_id = this.cognitoService.operatorId;
    }

     // Define a mapping of error codes to corresponding error messages
     const errorMappings: Record<string, string> = {
      'createTruckVinInputError': 'invalidVIN',
      'createTruckNicknameInputError': 'invalidNickname',
      'createTruckBrandInputError': 'invalidBrand',
      'createTruckTypeInputError': 'invalidType',
      'createTruckSizeInputError': 'invalidSize',
      'createTruckCapacityInputError': 'invalidCapacity',
      'createTruckYearInputError': 'invalidYear',
      'createTruckStatusInputError': 'invalidStatus',
    };

    // Retrieve the first validation error from the array
    const validationError = this.validationService.validationErrorArray[0];
    console.log(this.validationService.validationErrorArray);

    // Check if the validation error code exists in the mapping
    if (errorMappings[validationError]) {
      this.systemMessageService.buttonBlocked = true;
      // If so, display a danger ribbon message with the corresponding key
      this.systemMessageService.selectRibbon('danger', errorMappings[validationError]);
    }
  }

  get displayYear() {
    return this.truckService.truck.year || '';
  }
}
