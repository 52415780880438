import { Injectable } from '@angular/core';
import { CognitoService } from "./cognito.service";

// region types
/**
 * Type that defines the websocket callbacks expected my the subscription system
 */
export type WebSocketCallback = (msg: BaseMessage) => void;

/**
 * Type that defines the subscriber data dictionary
 */
type WebSocketSubscriptions = {
  [messageType: string]: {
    [subscriber: string]: WebSocketCallback[];
  }
};
//endregion types

//region interfaces
/**
 * Defines the base format for a websocket Message
 */
export interface BaseMessage {
  ACTION: string;
  CONTENT: { [key: string]: any };
}

export interface GenericUpdatesMessage {
  ACTION: string;
  CONTENT: {
    CHANGES: {
      [key: string]: any
    }[]
  };
}

export interface RouteUpdateMessage extends BaseMessage {
  ACTION: "ROUTE_UPDATE";
  CONTENT: {
    ROUTE_ID: string
    ROUTE_STOP_ID: string;
    STATUS: string;
  };
}

export interface NotificationMessage extends BaseMessage {
  ACTION: "NOTIFICATION";
  CONTENT: {
    ID: string,
    TITLE: string,
    BODY: string,
    // LEVEL: string,
    // REQUIRE_ACKNOWLEDGEMENT: string,
    TIMESTAMP: number,
    ICON: string,
  };
}


/**
 * Defines the WebSocketSubscription object used by the subscription system
 */
export interface WebSocketSubscription {
  messageType: string;
  subscriber: string;
  callback: WebSocketCallback;
}
//endregion interfaces

export const ALL_MESSAGE_TYPES = "_ALL_MESSAGE_TYPES_";

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private userSub: string = "";
  private socket!: WebSocket;
  private subscriptions: WebSocketSubscriptions = {};

  // Properties to handle the automatic reconnections
  private readonly reconnectDelay: number = 1000; // 1sec delay
  private readonly maxRetries: number = -1; // no maximum tries
  private retryCount: number = 0;
  private reconnecting: boolean = false;

  constructor(private cognitoService: CognitoService) {
    this.cognitoService.getCurrentUserSub().then(userSub => {
      this.userSub = userSub;
      this.connectToWebsocket();
    });
  }

  connectToWebsocket() {
    if(this.socket)
      this.socket.close();

    this.socket = new WebSocket('wss://ifm-devapi.mwstudio.io:8100');
    // this.socket = new WebSocket('wss://127.0.0.1:8100');
    // this.socket = new WebSocket('wss://local-ifm-api:8101');

    this.socket.onopen = () => {
      this.retryCount = 0; // Reset retry count on successful connection
      this.reconnecting = false;

      console.log('WebSocket connection established');

      let login = {
        ACTION: "LOGIN",
        CONTENT:{
          USER: this.userSub,
          KEY: "someKey",
          TYPE: "ANGULAR_CLIENT",
        }
      };

      // Envoyer l'ID Cognito après la connexion
      this.socket?.send(JSON.stringify(login));
    };

    this.socket.onclose = (event) => {
      if (event.wasClean) {
        console.log('The connection was closed.');
      } else {
        console.log('Connection failure'); // for example, the server process is "killed"
      }
      this.reconnecting = false;

      // for automatically reconnecting
      this.scheduleReconnect();
    };

    this.socket.onmessage = async (event) => {
      if (typeof event.data === "string") {
        let msg = JSON.parse(event.data) as BaseMessage;
        let messageType = msg.ACTION as string;
        // Take all those subscribed to messageType
        let subscribers = this.subscriptions[messageType] ?? {};
        // Add callbacks that listen to all message types (normally used for debugging)
        subscribers = {...subscribers, ...this.subscriptions[ALL_MESSAGE_TYPES] ?? {}};

        // Iterate over all subscribers, and call all their callbacks
        for (let subscriber in subscribers) {
            subscribers[subscriber].forEach(func => func(msg));
        }
      }
      else console.log("Invalid data format from server", event.data)
    };

    this.socket.onerror = (error: any) => {
      console.log('Error', error);
      this.reconnecting = false;

      // Ensure the connection gets closed to trigger `onclose` and initiate reconnect
      if(this.socket)
        this.socket.close();
      else
        this.scheduleReconnect();
    };
  }

  /**
   * Closes the connection
   */
  close() {
    this.socket?.close();
  }

  /**
   * Setups and handles to auto-reconnect behaviour when the connections fails
   * @private
   */
  private scheduleReconnect() {
    // Skip if already connected/connecting
    if (this.socket?.readyState === WebSocket.CONNECTING || this.socket?.readyState === WebSocket.OPEN || this.reconnecting)
      return;

    // Check if we reached max attempts
    if(this.maxRetries != -1 && this.retryCount >= this.maxRetries){
      console.error("Maximum connection attempt reached. WebSocket failed to connect too many times.");
    }

    this.reconnecting = true;
    this.retryCount += 1;
    setTimeout(() => {
      console.log(`Reconnecting attempt #${this.retryCount}`);
      this.connectToWebsocket();
    }, this.reconnectDelay);
  }

  /**
   * Send the Message to the websocket server
   * @param message
   */
  public send(message: BaseMessage) {
    if (!(this.socket && this.socket.readyState === WebSocket.OPEN)) {
      console.warn("WebSocket is not open. Unable to send message.");
      return;
    }

    const jsonMessage = JSON.stringify(message);
    console.log(jsonMessage);
    this.socket.send(jsonMessage);
  }

  /**
   * Allows services/components to subscribe to specific incoming message types
   * @param subscription
   */
  public subscribe(subscription: WebSocketSubscription){
    let subscriber = subscription.subscriber;
    let messageType = subscription.messageType;

    if (!this.subscriptions[messageType]) {
      this.subscriptions[messageType] = {};
    }
    if (!this.subscriptions[messageType][subscriber]) {
        this.subscriptions[messageType][subscriber] = [];
    }
    this.subscriptions[messageType][subscriber].push(subscription.callback);
  }

  /**
   * Allows services/components to unsubscribe from specific incoming message types
   * This prevents keeping unnecessary callback references
   * @param subscription
   */
  public unsubscribe(subscription: WebSocketSubscription): void {
    let subscriber = subscription.subscriber;
    let messageType = subscription.messageType;

    if (this.subscriptions[messageType] && this.subscriptions[messageType][subscriber]) {
      delete this.subscriptions[messageType][subscriber];

      // Clean up the messageType entry if it has no more subscribers
      if (Object.keys(this.subscriptions[messageType]).length === 0) {
        delete this.subscriptions[messageType];
      }
    }
  }
}


/**
 * Class holding Type Guards for WebSocket message types, to help differentiate them from BaseMessage
 * <br>
 * These have the added bonus of enabling proper autocomplete from IDEs
 */
export class WebSocketTypeGuards {
  public static isNotificationMsg(msg: BaseMessage): msg is NotificationMessage {
    return msg.ACTION === "NOTIFICATION";
  }

  public static isRouteUpdateMsg(msg: BaseMessage): msg is RouteUpdateMessage {
    return msg.ACTION === "ROUTE_UPDATE";
  }
}
