import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ThemeService } from '../service/theme.service';
import { SystemMessageService } from '../service/system-message.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../local-storage.service';
import { Route, RouteService, Stop } from '../service/route.service';
import { OperatorService } from '../service/operator.service';
import { CognitoService } from '../service/cognito.service';
import { RoleService } from '../service/role.service';
import { Truck, TruckService } from '../service/truck.service';
import { DistributorsService } from '../service/distributors.service';
import { BaseMessage, WebSocketService, WebSocketSubscription, WebSocketTypeGuards } from "../service/websocket.service";
import { BinUsage } from 'src/app/constants/bin-usage';
import { formatTaskStatus, Task, TaskGroup, TaskStatus } from '../progress-tracker/progress-tracker.component';
import { map } from "rxjs/operators";
import { of } from "rxjs";

@Component({
  selector: 'app-dispatcher-control-center',
  templateUrl: './dispatcher-control-center.component.html',
  styleUrl: './dispatcher-control-center.component.css',
})
export class DispatcherControlCenterComponent implements OnInit, OnDestroy {
  // Holds websocket subscription to ROUTE_UPDATE messages
  private wsRouteUpdate: WebSocketSubscription = {
    subscriber: "dispatcher-control-center",
    messageType: "ROUTE_UPDATE",
    callback: this.wsRouteUpdateHandler,
  };


  public truckList: Truck[] = [];
  public routeList: Route[] = [];
  public routeArray: Route[] = [];

  public selectedRoute: Route | null = null;
  public selectedTruck: Truck | null = null;
  public selectedTruckItinerary: TaskGroup[] = [];

  fillLevelBigTruck: number = 100;
  startLevel: number = 0;

  fillingPercentage: any; //TODO I think this could be converted to local vars instead
  calculatedWeight: any;

  public loading: boolean = true;
  public noInfo: boolean = true;
  public showAssignRouteModal: boolean = false;

  // Reference to the scroll container element in the template, using ViewChild to get the ElementRef
  @ViewChild('scrollContainer', { read: ElementRef }) scrollContainer!: ElementRef;

  constructor(
    public cognitoService: CognitoService,
    public operatorService: OperatorService,
    public routeService: RouteService,
    public theme: ThemeService,
    public systemMessage: SystemMessageService,
    private translate: TranslateService,
    //@Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    //@Inject(forwardRef(() => TranslateService)) @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private roleService: RoleService,
    public truckService: TruckService,
    public distributorService: DistributorsService,
    public webSocketService: WebSocketService,
  ) { }

  ngOnDestroy() {
    this.webSocketService.unsubscribe(this.wsRouteUpdate);
  }

  async ngOnInit() {
    this.webSocketService.subscribe(this.wsRouteUpdate);

    // Confirm that the user is valid using the Cognito service
    await this.cognitoService.confirmValidUser();

    // Retrieve the user's type using the Cognito service
    await this.cognitoService.getUserType();

    // Fetch the roles associated with the user using the Role service
    await this.roleService.getRoles();

    // Extract the operator ID from the user's information
    const operatorId = this.cognitoService.userInfos?.attributes?.['custom:operations_id'];

    // This is needed for getCurrentTruckDriverName() to work
    await this.operatorService.getDriversByOperatorId(operatorId);

    // Get the list of trucks associated with the operator ID using the Truck service
    this.truckList = await this.truckService.getTrucksByOperatorId(operatorId);

    // Add missing parameters that will be used for each object //TODO fix source, those should probably not be missing
    this.truckList.forEach((truck: Truck) => {
      truck.closedRoute = false;
      truck.routes = [];
    });

    // Fetch today's routes using the Route service (with geometry is much heavier)
    this.routeList = (await this.routeService.getTodayRoutesByOperatorId(operatorId, false)).map((route: Route) => {
      return {
        ...route,
        formattedTotalTime: 0,
        formattedTimestamp: this.formatTimestamp(route.creation_date ?? ""),
      };
    });

    // Fetch the routes' stops
    for (const [i, route] of this.routeList.entries()) {
      this.routeList[i].stops = await this.routeService.getRouteStopByRouteId(this.routeList[i].route_id);
      this.routeList[i].formattedTotalTime = this.calculateTotalTime(route);
    }

    // Group the routes in their respective trucks
    this.truckList.forEach((truck: Truck) => {
      truck.routes = [];
      truck.routes.push(...this.routeList.filter((route) => route.truck_id === truck.truck_id));
    });

    // reorder this.truckList based on routes quantity
    this.truckList = this.reorderTruckListByRoutes(this.truckList);

    // display the first truck on the right panel
    this.showInfoTruck(this.truckList[0]);

    // remove loading screen
    this.loading = false;

    // show welcome message - instruction message about click on trucks or routes
    this.systemMessage.selectRibbon('info', "dispatcherWelcome");
  }

  getCurrentTruckDriverName(user: any): string | null {
    const driver = this.operatorService.operatorDriversArray.find(driver => driver.user === user);

    if (driver) {
      return `${driver.given_name} ${driver.family_name}`;
    }

    return null; // Retorna null se o user não for encontrado
  }

  reorderTruckListByRoutes(truckList: any[]): any[] {
    return truckList.sort((a, b) => {
      const routesA = a.routes?.length ?? 0;
      const routesB = b.routes?.length ?? 0;

      // Sorting in ascending order based on the number of routes
      return routesB - routesA;
    });
  }

  formatTimestamp(timestamp: string): string {
    const date = new Date(parseInt(timestamp, 10) * 1000); // Multiply by 1000 to convert from seconds to milliseconds
    return date.toLocaleDateString('en-CA', { // Canadian date format as example (yyyy-mm-dd)
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
  }

  groupRoutesByRouteId(items: any[]): any[] {
    const grouped: { [key: string]: any[] } = {};

    items.forEach(item => {
      if (!grouped[item.route_id]) {
        grouped[item.route_id] = [];
      }
      grouped[item.route_id].push(item);
    });

    // Transform object into an array, preserving independent and grouped items
    return Object.values(grouped);
  }

  assignDriver(truck: any) {
    this.showAssignRouteModal = true;
    this.selectedTruck = truck;//.nickname;
  }

  showInfoRoute(newSelectedRoute: Route) {
    // Unselect if already selected
    if(this.selectedRoute?.route_id === newSelectedRoute.route_id){
      this.selectedRoute = null;
      this.noInfo = true;
      return;
    }
    this.selectedRoute = newSelectedRoute;

    // Find the selected route's truck
    let truck = this.truckList.find((truck) => truck.truck_id === this.selectedRoute?.truck_id);
    if(truck){
      // Only change truck if its different from current truck
      if(this.selectedTruck?.truck_id !== newSelectedRoute.truck_id){
        this.selectedTruck = truck;

        // Since truck changed, we have to recompute the itinerary
        this.updateTruckItinerary();
      }
    }
    //this.selectedTruck = null;

    this.noInfo = false;
  }

  convertSecondsToMinutesOnly(seconds: number): number {
    return Math.floor(seconds / 60);
  }

  // Function to calculate the total approximate_time in seconds and convert it to minutes
  calculateTotalTime(route: Route): number {
    let totalTime = 0;
    route.stops.forEach((stop) => totalTime += (+stop.approximate_time));
    return this.convertSecondsToMinutesOnly(totalTime);
  }


  showInfoTruck(newSelectedTruck: Truck) {
    this.calculatedWeight = 0;
    this.fillingPercentage = 0;
    this.fillLevelBigTruck = 0;
    this.selectedTruck = newSelectedTruck;

    // Clear the previously selected route information
    this.selectedRoute = null;

    // Set the flag indicating that information is available
    this.noInfo = false;

    // Calculate the total weight of all bins (lixeiras) in the truck
    this.calculatedWeight = this.calculateTotalWeight(newSelectedTruck);

    // Calculate the filling percentage of the truck
    this.fillingPercentage = this.getFillingPercentage(newSelectedTruck);

    // Set the fill level of the big truck to the calculated filling percentage
    this.fillLevelBigTruck = this.fillingPercentage;

    // Simulate the filling of the big truck based on the calculated fill level
    this.simulateFillingBigTruck();

    // Optionally, you can uncomment these lines to log the calculated values to the console for debugging
    // console.log('newSelectedTruck', newSelectedTruck);
    // console.log(`Total Weight: ${this.calculatedWeight}`);
    // console.log(`Filling Percentage: ${this.fillingPercentage}%`);

    this.updateTruckItinerary();
  }

  /**
   * Updates the displayed truck itinerary with the selectedTruck's routes' content
   */
  updateTruckItinerary(){
    // Map truck's routes as TaskGroups
    this.selectedTruckItinerary = this.selectedTruck?.routes?.map((route: Route, i: number) => {
      // If any stop is in-progress, delayed or completed, than the route is started
      let routeStarted = route.stops.some((stop: Stop) =>
        stop.status == TaskStatus.InProgress || stop.status == TaskStatus.Delayed || stop.status == TaskStatus.Completed
      );
      // If all stops are completed or cancelled, then the route is ending or has ended
      let allCompleted = route.stops.every((stop: Stop) => stop.status == TaskStatus.Completed || stop.status == TaskStatus.Cancelled);
      //let routeHasEnded = route.status == "C"; //TODO Detect if route is completed/done (like if truck is back or something)

      let startTaskDescription = this.translate.stream(["operator", "truckNickname", "VIN", "Year"]).pipe(
        map((t) => {
          return `${t["operator"]}: ${this.selectedTruck?.operator_id}<br>` +
            `${t["truckNickname"]}: ${this.selectedTruck?.nickname}<br>` +
            `${t["VIN"]}: ${this.selectedTruck?.vin}<br>` +
            `${t["Year"]}: ${this.selectedTruck?.year}`;
          }
        )
      );

      let startTask: Task = {
        id: "_START_" + route.route_id,
        status: routeStarted ? TaskStatus.Completed : TaskStatus.InProgress,
        description$: startTaskDescription,
        name$: routeStarted ? this.translate.stream("Departure") : this.translate.stream("Departure Information:"),
      };
      let endTask: Task = {
        id: "_END_",
        status: allCompleted ? TaskStatus.InProgress : TaskStatus.Pending, //TODO Detect when truck is back to mark as Completed
        description$: of("need to add description about ending like return address or something"),
        name$: this.translate.stream("Arrival"),
      };

      // Map route's stops as Tasks. First sort stops by index than map them
      let routeTasks: Task[] = route.stops.sort((a: Stop, b: Stop) => a.index - b.index).map((stop: Stop) => {
        return {
          id: stop.route_stop_id,
          name$: of(stop.bin_address),
          description$: this.translate.stream(["Estimated stint time", "minutes"]).pipe(
            map((t) => {
              return `${t["Estimated stint time"]} : ${this.convertSecondsToMinutesOnly(+stop.approximate_time)} ${t["minutes"]}`;
            })),
          status: formatTaskStatus(stop.status),
        };
      });

      return {
        id: route.route_id,
        name$: this.translate.stream("Route").pipe(map((routeTranslation) => `${routeTranslation} #${i + 1}`)),
        tasks: [
          startTask,
          ...routeTasks,
          endTask,
        ],
      } as TaskGroup;
    }) ?? [];

    console.log(this.selectedTruckItinerary);
  }

  calculateRouteVolume(route: any): number {
    let totalWeight = 0;

    // Check if info is an array or just a single object
    const routes = Array.isArray(route) ? route : [route];

    // Checks if the route array exists and contains items
    if (routes && routes.length > 0) {
        // Iterates over the routes and calculates the total weight
        totalWeight = routes.reduce((sum: number, route: any) => {
            // Gets the total volume and threshold, converting to number
            const volume = parseFloat(route.total_volume) || 0;
            const threshold = parseFloat(route.threshold) || 0;

            // Calculates weight based on volume and threshold percentage
            const weight = volume * (threshold / 100);

            // Add the weight to the total
            return sum + weight;
        }, 0);
    }

    // Returns the calculated total weight
    return totalWeight;
  }

  calculateTotalWeight(info: any): number {
    let totalWeight = 0;

    // Check if the routes array exists and has at least one item
    if (info.routes && info.routes.length > 0) {
      // Flatten the array if it contains arrays inside it
      const flattenedRoutes = info.routes.flat();

      // Iterate over the flattened routes array and calculate the total weight of all bins
      totalWeight = flattenedRoutes.reduce((sum: number, route: any) => {
        // Calculate the weight of each bin as total_volume * (threshold / 100)
        const volume = parseFloat(route.total_volume) || 0;
        const threshold = parseFloat(route.threshold) || 0;
        const weight = volume * (threshold / 100);
        // Add the weight of the current bin to the sum
        return sum + weight;
      }, 0);
    }

    // Return the total calculated weight
    return totalWeight;
  }

  getFillingPercentage(info: any): number {
    // Calculate the total weight of all bins in the truck
    const totalWeight = this.calculateTotalWeight(info);

    // Convert the truck's capacity to a number (assuming it's a string)
    const capacity = parseFloat(info.capacity);

    // Calculate the filling percentage by dividing the total weight by the truck's capacity and multiplying by 100
    const fillingPercentage = (totalWeight / capacity) * 100;

    // Round the filling percentage to the nearest whole number and return it
    return Math.round(fillingPercentage);
  }

  // This function handles the drag-and-drop events for moving items (routes and trucks)
  // within or between containers.
  drop(event: CdkDragDrop<any[], any[]> | CdkDragDrop<any[], Truck[]> | CdkDragDrop<Truck[], any[]> | CdkDragDrop<Truck[], Truck[]>) {

    // remove comments to debug
    // console.log('event', event)
    // console.log('event.item.data[0].bin_usage', event.item.data[0].bin_usage)
    // console.log('event.previousContainer.data', event.previousContainer.data)
    // console.log('event.container.data', event.container.data)

    // getting the data array
    const data = event.container.data;

    // Access the first sub-array
    const dataArray = data[0] || data;

    // Get the dragged item as a RouteInfo object
    const route = event.item.data as any;
    this.showInfoRoute(route);

    if (event.previousContainer === event.container) {
      // If the item is being sorted within the same container
      // moveItemInArray(event.container.data as RouteInfo[], event.previousIndex, event.currentIndex);
      moveItemInArray(event.container.data as any[], event.previousIndex, event.currentIndex);

    } else {
      // If the item is being moved to a different container
      // const route = event.item.data as RouteInfo;
      const route = event.item.data as any;

      const previousTruck = this.truckList.find(truck => truck.routes === event.previousContainer.data);
      const currentTruck = this.truckList.find(truck => truck.routes === event.container.data);

      // Check if the current truck's route is locked
      if(currentTruck?.closedRoute) {
        this.systemMessage.selectRibbon('danger', "truckLocked");
        return;
      }
      if (event.previousContainer.id === 'routesList') {
        // Moving from route list to a truck
        // verify if the operator is trying to mix different types of bin_usage , if yes block the operation
        if (event.item.data[0].bin_usage !== undefined && dataArray[0] && dataArray[0].bin_usage !== undefined) {
          if (event.item.data[0].bin_usage != dataArray[0].bin_usage) {
              this.systemMessage.selectRibbon('danger', "differentBinUsageType");
              return;
          }
        }

        transferArrayItem(
          this.routeList,//this.routeArray,// TODO I think i missed it during refactor, should be routeList instead
          event.container.data as any[],
          event.previousIndex,
          event.currentIndex
        );
      } else if (event.container.id === 'routesList') {
        // Moving from a truck to the route list
        if (previousTruck) {
          transferArrayItem(
            previousTruck.routes ?? [],
            this.routeList,//this.routeArray,// TODO I think i missed it during refactor, should be routeList instead
            event.previousIndex,
            event.currentIndex
            );
        }
      } else if (previousTruck && currentTruck) {
        // Moving from one truck to another truck
        // verify if the operator is trying to mix different types of bin_usage , if yes block the operation
        if (event.item.data[0].bin_usage !== undefined && dataArray[0] && dataArray[0].bin_usage !== undefined) {
          if (event.item.data[0].bin_usage != dataArray[0].bin_usage) {
              this.systemMessage.selectRibbon('danger', "differentBinUsageType");
              return;
          }
        }

        transferArrayItem(
          previousTruck.routes ?? [],
          currentTruck.routes ?? [],
          event.previousIndex,
          event.currentIndex
        );
      }
    }
  }

  toggleTruckLock(truck: any): void {
    // Toggle the lock status of the truck's route
    // If the route is locked (closedRoute is true), it will be unlocked (closedRoute will become false), and vice versa
    truck.closedRoute = !truck.closedRoute;

    switch(truck.status) {
      case '0':
        truck.status = '4';
      break;

      case '4':
        truck.status = '0';
      break;
    }

  }

  scrollLeft() {
    // Check if the scroll container and its native element exist
    if (this.scrollContainer && this.scrollContainer.nativeElement) {
        // Scroll the container to the left by 190 pixels
        this.scrollContainer.nativeElement.scrollBy({
            left: -190, // Scroll to the left by 190 pixels
            behavior: 'smooth' // Ensure the scrolling is smooth
        });
    }
  }

  scrollRight() {
    // Check if the scroll container and its native element exist
    if (this.scrollContainer && this.scrollContainer.nativeElement) {
        // Scroll the container to the right by 180 pixels
        this.scrollContainer.nativeElement.scrollBy({
            left: 180, // Scroll to the right by 180 pixels
            behavior: 'smooth' // Ensure the scrolling is smooth
        });
    }
  }

  // Scroll the truck list to the left
  scrollLeftTrucks(): void {
    // Find the element that contains the truck columns
    const truckColumns = document.querySelector('.truck-columns') as HTMLElement;

    // Scroll the truck columns to the left by 150 pixels
    truckColumns.scrollBy({
      left: -150, // Scroll to the left by 150 pixels
      behavior: 'smooth' // Ensure the scrolling is smooth
    });
  }

  // Scroll the truck list to the right
  scrollRightTrucks(): void {
    // Find the element that contains the truck columns
    const truckColumns = document.querySelector('.truck-columns') as HTMLElement;
    // Scroll the truck columns to the right by 150 pixels
    truckColumns.scrollBy({
      left: 150, // Scroll to the right by 150 pixels
      behavior: 'smooth' // Ensure the scrolling is smooth
    });
  }

  scrollUp(truckId: string) {
    // Find the element that matches the specified truck ID
    const list = document.querySelector(`.vertical-list[data-truck-id="${truckId}"]`);
    // If the element is found, scroll it up by 100 pixels
    if (list) {
      list.scrollBy({
        top: -100,  // Scroll up by 100 pixels
        behavior: 'smooth' // Ensure the scrolling is smooth
      });
    }
  }

  scrollDown(truckId: string) {
    // Find the element that matches the specified truck ID
    const list = document.querySelector(`.vertical-list[data-truck-id="${truckId}"]`);
    // If the element is found, scroll it up by 100 pixels
    if (list) {
        list.scrollBy({
            top: 100, // Scroll up by 100 pixels
            behavior: 'smooth' // Ensure the scrolling is smooth
        });
    }
  }

  simulateFillingBigTruck(): void {
    const interval = setInterval(() => {
      if (this.startLevel <= this.fillLevelBigTruck ) {
        this.startLevel += (this.fillLevelBigTruck/4);
      } else {
        clearInterval(interval);
      }
    }, 200);

    // comment line below to see FULL fill level animation
    // this.fillLevelBigTruck = 100;
    // console.log(this.fillLevelBigTruck);

  }


  // Method to return the translated label of the acronym
  getBinUsageLabel(binUsageAcronym: string): string {
    const bin = BinUsage.find(item => item.value === binUsageAcronym);
    return bin ? bin.label : binUsageAcronym; // Returns the translation key (label) or the acronym itself if not found
  }

  // Updates a stop's status from the websocket
  private wsRouteUpdateHandler(msg: BaseMessage) {
    if (WebSocketTypeGuards.isRouteUpdateMsg(msg)) {
      // Loop through trucks/routes to find the one matching the one from WS
      // Update with value from WS
      for (let truck of this.truckList) {
        for (let route of truck.routes) {
          if (route.route_id == msg.CONTENT.ROUTE_ID) {
            for (let stop of route.stops) {
              if (stop.route_stop_id === msg.CONTENT.ROUTE_STOP_ID) {
                stop.status = msg.CONTENT.STATUS;
                this.updateTruckItinerary();
              }
            }
          }
        }
      }
    }
  }
}

