import { Injectable } from '@angular/core';

//TODO Merge/replace with RouteInterface from route service
export interface Route {
  routeID:string,   //routeID
  name:string,      //route name
  date:string,      //route date
  driver:string,    //assigned driver
  stops:any[],      //list of stops
  distance:number   //route total distance in miles
  routeUrl:string   // Url to the route details
}

export interface DriverInfo {
  id: string;
  user: string;               // Unique identifier for the driver
  given_name: string;
  family_name: string;
  licenseNumber: string;     // Driver's license number (could map to another field if available)
  phoneNumber: string;       // Driver's contact phone number (phone_number in the log)
  email?: string;            // Driver's email address
  address?: string;          // Driver's physical address
  created: string;           // Date when the driver was created
  modified: string;          // Date when the driver was last modified
  currentRole: string;       // Driver's current role (current_role in the log)
  operatorId: string;        // ID of the operator associated with the driver
  username: string;          // Username of the driver
  userType: string;          // Type of user (e.g., 'operator')
  enable: string;            // Indicates if the driver is enabled (1 for enabled)
  role: string;              // Role ID associated with the driver
  distributorId?: string;    // ID of the distributor (if applicable)
  clientId?: string;
}


@Injectable({
  providedIn: 'root'
})
export class DriverService {
 selectedRoute:Route = {
   routeID: '',
   name: '',
   date: '',
   driver: '',
   stops: [],
   distance: 0,
   routeUrl: ''
 };  //the selected route
isRouteSelected:boolean =false;
  constructor() { }


}
