import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  map,
  Subject,
  firstValueFrom, tap
} from 'rxjs';
import { Amplify, Auth } from 'aws-amplify';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import * as AWS from 'aws-sdk';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom, from } from 'rxjs';
import { WorkOrderService } from './work-order.service';
import * as qrcode from 'qrcode';
import { SystemMessageService } from './system-message.service';
import { error } from 'jquery';
import { Types } from '../constants/user-type';
import { formatDate } from '@angular/common';

//User Object Declaration
export interface IUser {
  email: string;
  password: string;
  code: string;
  name: string;
}

export interface CognitoIdentityServiceProvider {
  listUsers(
    params: AWS.CognitoIdentityServiceProvider.Types.ListUsersRequest
  ): AWS.Request<
    AWS.CognitoIdentityServiceProvider.Types.ListUsersResponse,
    AWS.AWSError
  >;
}

export interface AlertPreference {
  label: string,
  value: number
}

export interface CognitoUser {
  username: string;
  given_name: string;
  family_name: string;
  legal_name: string;
  email: string;
  phone_number: string;
  address: string;
  custom_role: string;
  custom_user_type: string;
  custom_client_id?: string;
  custom_distributor_id?: string;
  custom_operator_id?: string;
  created: string;
  modified: string;
  user_status: string;
  custom_current_role: string;
  sub: string;
  enabled: string;
}

export interface UpdatedAttributes {
  email: string;
  phone_number: string;
  given_name: string;
  family_name: string;
  middle_name: string;
  picture: string;
  address: string;
  role: string;
}

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  cognitoUserData = {
    username: '',
    password: '',
    given_name: '',
    family_name: '',
    address: '',
    email: '',
    phone_number: '',
    custom_current_role: '',
    custom_user_type: '',
    custom_client_id: '',
    custom_role: '',
    custom_distributor_id: '',
    custom_operator_id: '',
    sub: '',
    client_name: '',
    enabled: '',
  };

  // for general use
  private successMessage: any;
  private userSub: string = "";
  public userInfos: any;

  public allUserRolesDeleted: boolean = false;
  public clientUserUpdated: boolean = false;
  public temporarySub: any;
  public temporaryRole: any;
  public muirwoodUserCreated: boolean = false;
  public clientUserCreated: boolean = false;
  public distributorUserCreated: boolean = false;
  public roleUserArray: any[] = [];
  public cognitoUsersArray: CognitoUser[] = [];
  public temporaryArray: any[] = [];
  public clientLegalName: any;
  public distributorLegalname: any;
  public operatorLegalName: string = '';
  public roleArrayToLambda: any[] = [];
  public legalName: string = '';

  private authenticationSubject: BehaviorSubject<any>;
  public challengeName: string = '';
  public currentRole: string = '';

  // Variables used in MFA sing-in sign-up functions
  public imgUrl: any;
  public userName: string = '';
  public userMFAPreference: string = '';
  public qrCodeImgActive: boolean = false;
  public confirmMFAMessage: string = '';
  public challengeParamDestynation: string = '';
  public userMFA: any;

  // Fucntions that will trigger system message to the user
  private wrongMFACode = new Subject<void>();
  wrongMFACode$ = this.wrongMFACode.asObservable();
  private MFASignUpSuccess = new Subject<void>();
  MFASignUpSuccess$ = this.MFASignUpSuccess.asObservable();

  public userRolesForUpdate: any;
  public userAllowed: boolean = false;

  // Variables used to get the user type
  public userType: string = '';
  public userRoles: any[] = [];

  // Variables used to get the client or distributor or operator ID
  public clientId: string = '';
  public distributorId: string = '';
  public operatorId: string = '';

  // Array used to have all client and distributor info's
  public distributorsInfosArray: any;
  public clientsInfosArray: any;
  public operatorsInfosArray: any;

  private userObject: any;
  private oldPassword: string = '';
  public emailValidationResult: String = '';
  public phoneNumberValidationResult: string = '';

  public alertPreferencesArray: AlertPreference[] = []
  public deviceUpdateEmail: number = 0;
  public deviceUpdateSms: number = 0;
  public workOrderUpdateEmail: number = 0;
  public workOrderUpdateSms: number = 0;
  public monthlyReportEmail: number = 0;
  public monthlyReportSms: number = 0;
  public alertPreferencesResult: any[] = [];
  public preferedLanguage: string = '';

  private array: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    public workOrderService: WorkOrderService,
    private systemMessageService: SystemMessageService
  ) {
    //Set configs for communicating with Cognito, Most of this will be changed when this app is hosted
    Amplify.configure({
      Auth: environment.cognito
    });
    // Set up AWS configuration
    AWS.config.update({
      region: environment.iot.region,
      sessionToken: sessionStorage.getItem('sessionToken') || '',
      accessKeyId: sessionStorage.getItem('accessKeyId') || '',
      secretAccessKey: sessionStorage.getItem('secretAccessKey') || '',
      credentials: {
        sessionToken: sessionStorage.getItem('sessionToken') || '',
        accessKeyId: sessionStorage.getItem('accessKeyId') || '',
        secretAccessKey: sessionStorage.getItem('secretAccessKey') || '',
      },
    });

    //Init the authenticationSubject
    this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }

  // Function used to set environment configurations
  async configure(){
    Amplify.configure({
      cognito: environment.cognito
    })
    //Returns temporary credentials for authenticated users
    const credentials = await Auth.currentCredentials();
    AWS.config.update({
      region: environment.iot.region,
      sessionToken: credentials.sessionToken,
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      credentials: {
        sessionToken: credentials.sessionToken,
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
      },
    });
  }

  //Fetch authenticated user's temporary credential and set Session variables
  public async getKeys(errorCode: string = '') {
    //Returns temporary credentials for authenticated users
    const credentials = await Auth.currentCredentials();

    //Set Session variables
    sessionStorage.setItem('accessKeyId', credentials.accessKeyId);
    sessionStorage.setItem('secretAccessKey', credentials.secretAccessKey);
    sessionStorage.setItem('sessionToken', credentials.sessionToken);
    sessionStorage.setItem('cognitoUserIdentityId', credentials.identityId);

    AWS.config.update({
      region: environment.iot.region,
      sessionToken: credentials.sessionToken,
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      credentials: {
        sessionToken: credentials.sessionToken,
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
      },
    });

    //Return credentials in an Object for use (if necessary)
    return {
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken,
    };
  }
getuserObject(){
  return this.userObject
}
  // Function used to refresh user token in AWS cognito
  async refreshToken() {
    try {
      //Refresh the session tokens if refresh token is still valid
      await Auth.currentAuthenticatedUser({ bypassCache: true });

    } catch (error) {
      this.checkForbidden(error);
      console.error('Error refreshing token:', error);
      // Handle the error, e.g., redirect to login or re-authenticate the user
      Auth.signOut()
          .then((res) => { this.router.navigate(['/login']);})
          .catch((error) => {
            console.error(error);
      });
    }
  }

   //Verify the Authenticated User Session. Force sign out when authentication expired.
   async verifySession(){
      //Are session keys set?
      if(
        sessionStorage.getItem("sessionToken") === undefined ||
        sessionStorage.getItem("accessKeyId") === undefined ||
        sessionStorage.getItem("secretAccessKey") === undefined
      ){
        //Get and set keys is session variables have not been set.
        await this.getKeys();
        window.location.reload();
      }

    try {

      const session = await Auth.currentSession();
      const expirationTime = session.getIdToken().getExpiration();

      // Check if the token is expired or close to expiration
      const currentTime = Math.floor(Date.now() / 1000);

      const timeUntilExpiration = expirationTime - currentTime;

      if (timeUntilExpiration < 6000) {
        // Token is about to expire, initiate refresh
        await this.refreshToken();
        const credentials = await Auth.currentCredentials();

        //Set Session variables
        sessionStorage.setItem('accessKeyId', credentials.accessKeyId);
        sessionStorage.setItem(
          'secretAccessKey',
          credentials.secretAccessKey
        );
        sessionStorage.setItem('sessionToken', credentials.sessionToken);
        sessionStorage.setItem(
          'cognitoUserIdentityId',
          credentials.identityId
        );
        // Set up AWS configuration
        AWS.config.update({
          region: environment.iot.region,
          sessionToken: credentials.sessionToken,
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          credentials: {
            sessionToken: credentials.sessionToken,
            accessKeyId: credentials.accessKeyId,
            secretAccessKey: credentials.secretAccessKey,
          },
        });

        if (window.location.pathname === '/login') {
            Auth.signOut()
              .then((res) => {  this.router.navigate(['/login']);})
              .catch((error) => {
                console.error(error);
              });
        } else {
            //reload page to apply restored session
            window.location.reload();
        }

      }
    } catch (error) {
      // If an error occurs, force signOut()
      Auth.signOut()
        .then((res) => {})
        .catch((error) => {
          console.error(error);
          this.router.navigate(['/login']);
        });
    }
  }

  //Check if a user is logged in
  public async confirmValidUser() {
    try {
      //Attempt to fetch active user information
      if (await this.getUser()) {
        //Verify the authenticated user session. Force SignOut if expired.
        this.verifySession();

        return true;
      } else {
        //Force Signout if getUser fails
        this.signOut()
          .then((response) => {
            this.router.navigate(['/login']);
          })
          .catch((error) => {
            console.error(error);
            this.router.navigate(['/login']);
          });
        //If no active user, sends the user back to /login
        return false;
      }
    } catch (error) {
      // In the event of an error, default to /login page and force SignOut
      this.checkForbidden(error);
      console.error('Error: ', error);
      this.signOut()
        .then((response) => {
          this.router.navigate(['/login']);
        })
        .catch((error) => {
          console.error(error);
          this.router.navigate(['/login']);
        });
      return false;
    }
  }

  //Returns active user information. This is how we get the User ID. Only fetches once, stores result for future calls
  public async getUser(): Promise<any> {
    // Set a variable of userInfos to avoid multiple calls
    if(!this.userInfos)
      this.userInfos = await Auth.currentUserInfo();

    return this.userInfos;
  }

  //Get Authenticated user credentials. Return Promise
  public async getCredentials(): Promise<any> {
    return await Auth.currentCredentials();
  }

  // Return user gicen name and familly name by his user ID
  getUserNameBySub(sub: string): string {
    const user = this.cognitoUsersArray.find((u) => u.sub === sub);

    //Check if user sub exist in userArray
    if (user) {
      // Return user given name and familly name
      return `${user.given_name} ${user.family_name}`;
    } else {
      // Return user not found if he's not
      return 'User not found';
    }
  }

  //Sets Object Values and pushes a CognitoUser Object to an array of type CognitoUser[]
  public async buildUserObjectArray(users:any){

    for(const user of users){
      // Create a CognitoUser object and add it to the array
      const cognitoUser: CognitoUser = {
        // Populate CognitoUser properties
        username:user.username || '',
        given_name: user.given_name || '',
        family_name: user.family_name || '',
        email: user.email || '',
        phone_number: user.phone_number || '',
        address: user.address || '',
        custom_role: user.current_role || '',
        custom_user_type: user.user_type || '',
        legal_name: user.legal_name,
        created: user.created || '',
        modified: user.modified || '',
        user_status: user.enable || '',
        custom_current_role: user.current_role,
        sub: user.user,
        custom_client_id: user.client_id,
        custom_distributor_id: user.distributor_id,
        custom_operator_id: user.operator_id,
        enabled: user.enable || '',
      };

      //Push populated Object
      this.cognitoUsersArray.push(cognitoUser);
    }

  }

  //Returns all Cognito Users
  public async getAllUsers() {


    try {

        //API for user list
        const url = environment.api.stage + environment.api.route.getAllUsers;

        //Convert an Observable<Object> to a Promise to guarantee data presence
        const response = lastValueFrom(this.http.get(url))

        //Use the Promise to force asynchronous handling of the result set and return the results as a constant
        const users = response.then(async (data:any)=>{
          //Stringify Object then Parse the JSON to create an iterable Array.
          data = JSON.parse(JSON.stringify(data))

          //Use asynchronous function to populate the cognitoUsersArray with User Objects using a loop
          await this.buildUserObjectArray(data);

          //Return populated Object Array
          return this.cognitoUsersArray;
        }).catch((error)=>{
          console.log(error)
          //Return an empty Object Array to avoid unexpected Void function errors
          return this.cognitoUsersArray;
        })


         // Return the array of CognitoUser objects
        return users;

    } catch (error) {
      // Handle errors during user retrieval
      this.checkForbidden(error);
      console.error('Error getting user list:', error);
      this.verifySession();
      throw error;
    }
  }

  //Formats string by adding quotes.
  addQuotes(str: string): string {
    return `'${str}'`;
  }

  //Returns ID of authenticated User
  public async getCurrentUserSub() {
    // If already fetch, simply return the info
    if(this.userSub != ""){
      return this.userSub;
    }

    // Get the current user information
    const currentUserInfo = await this.getUser();

    //region TODO Why is this here?
    this.workOrderService.workOrderData.creator_entity_type =
      currentUserInfo.attributes['custom:user_type'];

    let clientID = currentUserInfo.attributes['custom:client_id'];
    this.workOrderService.clientIDArray.push(clientID);
    this.workOrderService.clientIDArrayFixed.push(this.addQuotes(clientID));

    switch (true) {
      case Boolean(currentUserInfo.attributes['custom:client_id']):
        this.workOrderService.workOrderData.creator_entity_id =
          currentUserInfo.attributes['custom:client_id'];
        break;

      case Boolean(currentUserInfo.attributes['custom:distributor_id']):
        this.workOrderService.workOrderData.creator_entity_id =
          currentUserInfo.attributes['custom:distributor_id'];
        break;
    }
    //endregion

    this.userSub = currentUserInfo.attributes.sub;
    return this.userSub;
  }

  // Function called to clear cognitoUserData
  public clearCognitoUserData() {
    this.cognitoUserData = {
      username: '',
      password: '',
      given_name: '',
      family_name: '',
      address: '',
      email: '',
      phone_number: '',
      custom_current_role: '',
      custom_user_type: '',
      custom_client_id: '',
      custom_role: '',
      custom_distributor_id: '',
      custom_operator_id: '',
      sub: '',
      client_name: '',
      enabled: '',
    };
  }

  // Funcion called to get Legal name for operators, distributors and clients
  public async getLegalName(id: string) {
    let baseUrl = '';
    let url = '';

    switch (id.substring(0, 2)) {
      case 'OP':
        // Construct th URL for the lambda function that retrive the operator legal name
        baseUrl = environment.api.stage + environment.api.route.getOperatorLegalName;
        url = `${baseUrl}&operator_id=${id}`;
        break;

      case 'CL':
        // Construct th URL for the lambda function that retrive the operator legal name
        baseUrl = environment.api.stage + environment.api.route.getClientLegalName;
        url = `${baseUrl}&client_id=${id}`;
        break;

      case 'DI':
        // Construct th URL for the lambda function that retrive the operator legal name
        baseUrl = environment.api.stage + environment.api.route.getDistributorLegalName;
        url = `${baseUrl}&distributor_id=${id}`;
        break;
    }

    try {
      console.log(id)
      // Make the http get request to the lambda function
      const response$ = this.http.get(url);

      // Wait for the response and convert it into json array
      let LegalNameValueString = JSON.stringify(await lastValueFrom(response$));
      const jsonArray = JSON.parse(LegalNameValueString);
      console.log(jsonArray)
      // Check if the json array has item
      if (jsonArray.length > 0) {
        // Extract and store operator legal name
        this.legalName = jsonArray[0].legal_name;
      }
      // Log a message if there is no item in the array
      return response$;
    } catch (error) {
      // Log an error message and re-throw the error for the calling code to handle
      this.checkForbidden(error);
      console.error('API Error:', error);
      this.verifySession(); // Re-throw the error for the calling code to handle
      throw error;
    }
  }

  //Validates the current user's role attributes against the expected role attributes for a given component or element
  public async getCurrentRole(
    validRole: string[],
    validUserType: string[],
    redirect: boolean = true,
    userRoles: string[] = [],
  ) {
   // console.log(userRoles)
    try {
      let result: any;
      //Get the actual user whit Cognito AWS
      result = await this.getUser();

      let currentRoles: any;
      let roleOK: boolean = false;

      //Assign result of Cognito actual user to currentRole & currentUserType variable
      if(userRoles.length !== 0){
        // Check if the role number are in validRole
        if(userRoles.some((role: any) => {return validRole.includes(role)})){
          roleOK = true;
        }
      }else{
        currentRoles = result.attributes['custom:current_role'];
        if(validRole.includes(currentRoles)){
          roleOK = true;
        }
      }

      const currentUserType = result.attributes['custom:user_type'];

      // Check if the current role matches the valid role
      if (roleOK && validUserType.includes(currentUserType)) {
        this.userAllowed = true; // Role is valid
      } else {
        // If roles don't match and redirect is true, navigate to the dashboard
        if (redirect) {
          this.router.navigate(['/dashboard']);
        }

        // Role is not valid
        this.userAllowed = false;
      }
    } catch (error) {
      // Handle errors during user retrieval
      this.checkForbidden(error);
      console.error('Error getting user: ', error);
      // Sign out the user and navigate to the login page
      this.signOut().then((response) => {
        this.router.navigate(['/login']);
      });
      this.userAllowed = false; // Role check failed due to error
    }
  }

  //Check if user was logged in successfully and is active
  public async isAuthenticated(): Promise<boolean> {
    //Check Authentication Subject Object. This is set in the signIn() function
    if (this.authenticationSubject.value) {
      //Return the Promise to confirm success
      return Promise.resolve(true);
    } else {
      //If the Authentication Subject Object is false, verify authentication with AWS
      return await this.getUser()
        .then((user: any) => {
          //Verify that an active user object was returned
          if (user) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          this.checkForbidden(error);
          //log errors and return false to trigger conditional logic in the LoginComponent
          console.error('Error:', error);
          return false;
        });
    }
  }

  //Used for debugging sessions. Only logs session information and errors
  public async currentSession(): Promise<any> {
    // Retrieve the current session
    await Auth.currentSession()
      .then((currentSession: any) => {
        // Extract tokens from the resolved session object
        const idToken = currentSession.getIdToken().getJwtToken();
        const accessToken = currentSession.getAccessToken().getJwtToken();
        const refreshToken = currentSession.getRefreshToken().getToken();
      })
      .catch((error) => {
        this.checkForbidden(error);
        //Log errors
        console.error('Error getting session:', error);
        this.verifySession();
      });
  }

  //Sends an email to the user containing a passwor reset code
  public async forgotPassword(username: string): Promise<any> {
    try {
      //Request to AWS via Amplify to send a user a reset code by email or sms
      const forgotPassword = await Auth.forgotPassword(username);
      return forgotPassword;
    } catch (error) {
      this.checkForbidden(error);
      console.error('Something went wrong: ', error);
      return false;
    }
  }

  // Function to generate a random temporary password with specific criteria
  generateTemporaryPassword() {
    const length = 12; // Set the desired password length
    const charset =
      '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+=<>?';
    const specialChars = '!@#$%^&*()_-+=<>?';

    let temporaryPassword = '';

    // Ensure inclusion of at least one number
    const randomDigit = Math.floor(Math.random() * 10); // Generate a random number from 0 to 9
    temporaryPassword += charset[randomDigit];

    // Ensure inclusion of at least one special character
    const randomSpecialChar = Math.floor(Math.random() * specialChars.length);
    temporaryPassword += specialChars[randomSpecialChar];

    // Generate the remaining characters of the password
    for (let i = 2; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      temporaryPassword += charset[randomIndex];
    }
    return temporaryPassword; // Return the generated temporary password
  }

  // Function that set role to the oposit of witch it is
  toggleRole(role: any) {
    // Check if the role is already in the array of user roles
    if (this.roleUserArray.includes(role)) {
      // The role is already in the array, so remove it
      this.roleUserArray = this.roleUserArray.filter((r) => r !== role);
    } else {
      // The role is not in the array, so add it
      this.roleUserArray.push(role);
    }
  }

  // Function called to disable user access in cognito
  async disableUserAccess(username: string): Promise<string> {
    await this.configure();
    // Initialize Cognito Identity Service Provider with the appropriate region
    const cognitoIdentityServiceProvider =
      new AWS.CognitoIdentityServiceProvider({
        region: environment.iot.region,
      });

    // Set parameters for the user deletion request
    const params = {
      UserPoolId: environment.cognito.userPoolId,
      Username: username,
    };

    // Create a promise to handle the asynchronous operation
    return new Promise(async (resolve, reject) => {
      // Make an adminDeleteUser request to delete the specified user
      cognitoIdentityServiceProvider.adminDisableUser(
        params,
        (err: any, data: any) => {
          if (err) {
            // Log an error message if deletion fails
            console.error('Error disable user:', err);
            // Reject the promise with the error
            reject(err);
          } else {
            // Initialize Cognito Identity Service Provider with the appropriate region
            const cognitoIdentityServiceProvider2 =
              new AWS.CognitoIdentityServiceProvider({
                region: environment.iot.region,
              });
            // If we are able to disable user in cognito we need to set his attribute enable to '0' so we know he's disabled
            const updateParams = {
              UserPoolId: environment.cognito.userPoolId,
              Username: username,
              UserAttributes: [
                {
                  Name: 'custom:enable',
                  Value: '0',
                },
              ],
            };
            try {
              // Update user attributes in Cognito User Pool
              const enableResultUpdate = cognitoIdentityServiceProvider2
                .adminUpdateUserAttributes(updateParams)
                .promise();
              // Resolve the promise if the user is deleted successfully
              resolve('success');
            } catch (error) {
              this.checkForbidden(error);
              console.error(error);
            }
          }
        }
      );
      this.cognitoUserData.enabled = '0';
      this.enableDisableDBUser();
    });
  }

  // Function called to enabled user access in cognito
  enableUserAcces(username: string): Promise<string> {
    // Initialize Cognito Identity Service Provider with the appropriate region
    const cognitoIdentityServiceProvider =
      new AWS.CognitoIdentityServiceProvider({
        region: environment.iot.region,
      });

    // Set parameters for the user deletion request
    const params = {
      UserPoolId: environment.cognito.userPoolId,
      Username: username,
    };

    // Create a promise to handle the asynchronous operation
    return new Promise(async (resolve, reject) => {
      // Make an adminDeleteUser request to delete the specified user
      cognitoIdentityServiceProvider.adminEnableUser(
        params,
        (err: any, data: any) => {
          if (err) {
            // Log an error message if deletion fails
            console.error('Error deleting user:', err);
            // Reject the promise with the error
            reject(err);
          } else {
            // Initialize Cognito Identity Service Provider with the appropriate region
            const cognitoIdentityServiceProvider2 =
              new AWS.CognitoIdentityServiceProvider({
                region: environment.iot.region,
              });
            // If we are able to enable user in cognito we need to set his attribute enable to '1' so we know he's enabled
            const updateParams = {
              UserPoolId: environment.cognito.userPoolId,
              Username: username,
              UserAttributes: [
                {
                  Name: 'custom:enable',
                  Value: '1',
                },
              ],
            };
            try {
              // Update user attributes in Cognito User Pool
              const enableResultUpdate = cognitoIdentityServiceProvider2
                .adminUpdateUserAttributes(updateParams)
                .promise();
              resolve('success');
            } catch (error) {
              this.checkForbidden(error);
              console.error(error);
            }
            // Resolve the promise if the user is deleted successfully
          }
        }
      );
      this.cognitoUserData.enabled = '1';
      this.enableDisableDBUser();
    });
  }

  // Function called when we create a user. to create it in cognito then send email invitation
  // This function will create a user with is username and email, then update it to set his information and finally set him into a group
  async UserSignUpAndSendTemporaryPassword(userType: string, userCurrentRole: string): Promise<any> {
    // Create an instance of CognitoIdentityServiceProvider using AWS SDK
    const cognitoIdentityServiceProvider =
      new AWS.CognitoIdentityServiceProvider({
        region: environment.iot.region,
      });

    // Extract user information from the form
    const username = this.cognitoUserData.username; // Get form username
    const temporaryPassword = this.generateTemporaryPassword(); // Generate a random temporary password
    const email = this.cognitoUserData.email; // Get form email address
    const phoneNumber = this.cognitoUserData.phone_number.replace(/\D/g, '');

    // Set custom attributes for client user
    this.cognitoUserData.custom_user_type = userType;
    this.cognitoUserData.custom_current_role = userCurrentRole;

    // Set parameters for creating a new user in Cognito User Pool
    const params = {
      UserPoolId: environment.cognito.userPoolId,
      Username: username,
      TemporaryPassword: temporaryPassword,
      UserAttributes: [
        {
          Name: 'email',
          Value: email,
        },
      ],
    };

    // Function called to check if there's already the user email in the cognito service
    const duplication = await firstValueFrom(
      from(this.checkForDuplicateEmailAddress())
    );
    if (duplication) {
      return 'duplicated';
    }
    // Choose one of the following options for MessageAction:
    // - 'SUPPRESS': Prevents an automatic welcome email from being sent.
    // - 'RESEND': Resends the welcome email.

    // Example usage:
    // MessageAction: 'SUPPRESS', // Use this option to suppress the welcome email
    // MessageAction: 'RESEND', // Use this option to resend the welcome email

    try {
      // Create a new user in Cognito User Pool
      const result = await cognitoIdentityServiceProvider
        .adminCreateUser(params)
        .promise();

      // Retrieve user details to get the 'sub'
      const userParams = {
        UserPoolId: environment.cognito.userPoolId,
        Username: username,
      };
      // Get bcck the instance of the user we just create
         await cognitoIdentityServiceProvider
        .adminGetUser(userParams)
        .promise().then(async (user)=>{
          if (user.UserAttributes) {
            // Call the function to set the params for the update of the user
            const paramsUpdateAttributes = this.getUpdateAttributeParams(userType);

            // Update user attributes in Cognito User Pool
            const resultUpdateAttributes = await cognitoIdentityServiceProvider
              .adminUpdateUserAttributes(paramsUpdateAttributes)
              .promise();

            // The 'sub' of the user can be found in user.UserAttributes
            const sub = user.UserAttributes.find(
              (attr: any) => attr.Name === 'sub'
            )?.Value;

            // If 'sub' is available, send data to Lambda function
            if (sub) {
              await this.addRolesForUser(sub, this.roleUserArray.join(','));
              this.userSub = sub
            }
            // Function called to add the created user to a group
            this.addUserToGroup(username, userType);
            await this.createDBUser();
          } else {
            console.error('User attributes are undefined.');
            // Handle the case where user.UserAttributes is undefined, such as throwing an error or returning early.
          }
        });


      // Return the result
      return result;
    } catch (error) {
      // Set clientUserCreated flag to false and log the error
      this.checkForbidden(error);
      this.clientUserCreated = false;
      console.error(error);
      return Promise.reject(error);
    }
  }

  // Function called to check in cognito if the entered email adress of the new user is already used
  async checkForDuplicateEmailAddress(): Promise<boolean> {
    if (this.cognitoUsersArray.length === 0) {
      this.getAllUsers();
    }
    let duplicated = false;
    console.log(this.cognitoUsersArray)
    for (const user of this.cognitoUsersArray) {
      if (this.cognitoUserData.email === user.email) {
        duplicated = true;
      }
    }
    return duplicated;
  }

  // function called by UserSignUpAndSendTemporaryPassword to get the proper param to update the cognito user
  getUpdateAttributeParams(userType: string) {
    // Initialize a variable for the specific Id that we need to create this user
    let name = '';
    let value = '';
    let phoneNumber = this.cognitoUserData.phone_number.replace(/\D/g, '');
    const username = this.cognitoUserData.username; // Get form username

    // Check from the received user type witch is the good attribute to send
    switch (userType) {
      case Types[0].label:
        name = 'custom:client_id';
        value = this.cognitoUserData.custom_client_id;
        break;

      case Types[1].label:
        name = 'custom:client_id';
        value = this.cognitoUserData.custom_client_id;
        break;

      case Types[2].label:
        name = 'custom:distributor_id';
        value = this.cognitoUserData.custom_distributor_id;
        break;

      case Types[3].label:
        name = 'custom:operations_id';
        value = this.cognitoUserData.custom_operator_id;
        break;
    }

    if(phoneNumber.length > 0){
      phoneNumber = '+1' + phoneNumber;
    }

    // Set parameters for updating user attributes
    const paramsUpdateAttributes = {
      UserPoolId: environment.cognito.userPoolId,
      Username: username,
      UserAttributes: [
        {
          Name: 'address',
          Value: this.cognitoUserData.address,
        },
        {
          Name: 'family_name',
          Value: this.cognitoUserData.family_name,
        },
        {
          Name: 'given_name',
          Value: this.cognitoUserData.given_name,
        },
        {
          Name: 'phone_number',
          Value: phoneNumber,
        },
        {
          Name: 'custom:user_type',
          Value: this.cognitoUserData.custom_user_type,
        },
        {
          Name: 'custom:current_role',
          Value: this.cognitoUserData.custom_current_role,
        },
        {
          Name: 'custom:enable',
          Value: '1',
        },
        {
          Name: name,
          Value: value,
        },
      ],
    };
    return paramsUpdateAttributes;
  }

  // Function to create a user role
  createUserRole(sub: string, role: string) {
    // Mapping of role names to numeric values
    const roleMappings: { [key: string]: number } = {
      _admin: 0,
      _client: 1,
      _distributor: 2,
      _collections: 3,
      _operations: 4,
      _driver: 5,
      _dispatch: 6,
      _maintenance: 7
    };

    // Get the numeric value for the specified role; default to 0 if role is not found
    const roleNumber = roleMappings[role] ?? 0;

    // Store the user's subject identifier (sub) and role number temporarily
    this.temporarySub = sub;
    this.temporaryRole = roleNumber;

    // Set HTTP headers for the request
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Adjust content type as needed
    });
    // Will call the lambda function in setThingClient url with the passed data then return a response
    return this.http.post(
      environment.api.stage + environment.api.route.createUserRole,
      {
        user: this.temporarySub,
        role: this.temporaryRole,
      },
      { headers: headers }
    );
  }

  // Function used when you update an user with the user-update pages
  async updateUserAttributesCognito(username: any) {
    // Remove non-numeric characters from the phone number
    const numericOnly = this.cognitoUserData.phone_number.replace(/\D/g, '');
    let formattedNumber = "";
    if(numericOnly.length > 10){
       formattedNumber = `+${numericOnly}`;
    }

    if(numericOnly.length == 10){
       formattedNumber = `+1${numericOnly}`;
    }

    this.cognitoUserData.phone_number = formattedNumber;

    // Extract phone number and create an instance of CognitoIdentityServiceProvider using AWS SDK
    const cognitoIdentityServiceProvider =
      new AWS.CognitoIdentityServiceProvider({
        region: environment.iot.region,
      });

    // Define attributes to update for the MUIRWOOD user
    const attributesToUpdate = [
      {
        Name: 'given_name',
        Value: this.cognitoUserData.given_name,
      },
      {
        Name: 'family_name',
        Value: this.cognitoUserData.family_name,
      },
      {
        Name: 'phone_number',
        Value: formattedNumber,
      },
      {
        Name: 'address',
        Value: this.cognitoUserData.address,
      },
    ];

    // Set parameters for updating user attributes
    const params = {
      UserPoolId: environment.cognito.userPoolId,
      Username: username,
      UserAttributes: attributesToUpdate,
    };

    try {
      await this.updateDBUser();
      // Update user attributes in Cognito User Pool
      const result = await cognitoIdentityServiceProvider
        .adminUpdateUserAttributes(params)
        .promise();

      return result;
    } catch (error) {
      // Log an error message if the update fails
      this.checkForbidden(error);
      console.error('User attributes update failed:', error);
      return null;
    }
  }

  /**
   * Format roles data and upload to DB
   * @param sub
   * @param roles
   */
  async addRolesForUser(sub: string, roles: string) {
    // Initialize a string to store the roles
    let rolesString: string = '';
    rolesString = roles;
    // Split the roles string into an array using ',' as the delimiter
    const rolesArray = rolesString.split(',');
    // Iterate through the roles array
    // marc fix this shit
    for (let i = 0; i < rolesArray.length; i++) {
      // Subscribe to the createUserRole function, passing the user sub and current role
      this.createUserRole(sub, rolesArray[i]).subscribe((response) => {});
    }
  }

  // Function calle dto verified that update preference values are a number 0 or 1 before updating
  public validateAndUpdatePreference(value: number): number {
    // Check if the value is a number and is between 0 and 1
    if (typeof value === 'number' && (value === 0 || value === 1)) {
      return value; // If correct, return the original value
    } else {
      return 0; // Otherwise, return 0
    }
  }

  // Function that made the call to API Gateway to update user alert preferences
  public async updateUserAlertPreferenceDB(user: string, userThings: any[]) {
    // Validate and update alert preferences using the provided function
    this.deviceUpdateEmail = this.validateAndUpdatePreference(this.deviceUpdateEmail);
    this.deviceUpdateSms = this.validateAndUpdatePreference(this.deviceUpdateSms);
    this.workOrderUpdateEmail = this.validateAndUpdatePreference(this.workOrderUpdateEmail);
    this.workOrderUpdateSms = this.validateAndUpdatePreference(this.workOrderUpdateSms);
    this.monthlyReportEmail = this.validateAndUpdatePreference(this.monthlyReportEmail);
    this.monthlyReportSms = this.validateAndUpdatePreference(this.monthlyReportSms);

    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
        'Content-Type':  'application/json' // Adjust content type as needed
    });

    // Construct the URL
    const url = environment.api.stage + environment.api.route.updateUserAlertPreferenceDB;

    // Construct the data payload
    const payload = {
        "device_update_email": this.deviceUpdateEmail,
        "device_update_sms": this.deviceUpdateSms,
        "workorder_update_email": this.workOrderUpdateEmail,
        "workorder_update_sms": this.workOrderUpdateSms,
        "monthly_report_email": this.monthlyReportEmail,
        "monthly_report_sms": this.monthlyReportSms,
        "prefered_language": this.preferedLanguage,
        "user_things": userThings,
        "user": user,
    };

    // Will call the lambda function in updateBinModel url with the passed data then return a response
    return this.http.post(url, payload, { headers: headers });
  }

  // Function that made a call to API Gateway to get all user preferences
  public async getUserAlertPreferenceDB(user: string) {
    // Get the url for the lambda function getBinModelById and pass the binModelId
    const url = environment.api.stage + environment.api.route.getUserAlertPreferenceDB + "&user=" + user;
    // Call the lambda fonction whit the url
    return this.http.get(url).pipe(
      map((response: any) => {
        this.array = response;

        this.array = this.array.forEach((element: any) => {
          this.switchJSONToIntForUserPreferenceResponse(element);
        });

        return response;
      }),
      catchError((error:any) => {
        this.checkForbidden(error);
        console.error('API Error:', error);
        throw error(error); // Re-throw the error for the calling code to handle
      }));
  }

  // Function called to create an array to transfert JSON string for integer when needed
  switchJSONToIntForUserPreferenceResponse(element: any){
    // Table of witch keys we need to check
    const keysToCheck = [
      'device_update_email',
      'device_update_sms',
      'workorder_update_email',
      'workorder_update_sms',
      'monthly_report_email',
      'monthly_report_sms'
    ];
    // Call the function to transfert all value of the keys in Integer
    this.keyToTransfert(keysToCheck, element);
  }

  // Function called to create an array to transfert JSON string for integer when needed
  switchJSONToIntForClientResponse(element: any){
    // Table of witch keys we need to check
    const keysToCheck = [
      'created',
      'modiifed'
    ];
    // Call the function to transfert all value of the keys in Integer
    this.keyToTransfert(keysToCheck, element);
  }

  // Function that take the keys and change string values to int
  keyToTransfert(keysToCheck: any, element: any){
    keysToCheck.forEach((key: any) => {
      // Vérifier si la clé existe dans l'élément et si sa valeur est une chaîne
      if (typeof element[key] === 'string') {
          // Convertir la valeur de la clé en entier
          element[key] = parseInt(element[key], 10);
      }
    });
  }

  //Updates User attributes after authenticating the current user, returns boolean for error message handling used in user-edit page
  public async updateUserAttributes(updateUserAttributes: UpdatedAttributes): Promise<any> {
    try {
      await this.updateDBUser();
      // Get the current user object
      const userObj = await Auth.currentAuthenticatedUser();
      // Try to do the update on the current user object and will return true
      const update = await Auth.updateUserAttributes(userObj, {
        email: updateUserAttributes.email,
        phone_number: updateUserAttributes.phone_number,
        given_name: updateUserAttributes.given_name,
        family_name: updateUserAttributes.family_name,
        middle_name: updateUserAttributes.middle_name,
        picture: updateUserAttributes.picture,
        address: updateUserAttributes.address,
        'custom:current_role': updateUserAttributes.role,
      });

      return true;
    } catch (error) {
      //Log errors
      this.checkForbidden(error);
      console.error('Error: ', error);
      return false;
    }
  }

  //Resets password as part of the forgot-password workflow. requires a reset code emailed to the user via forgotPassword()
  public async resetPassword(username: string, code: string, newPassword: string): Promise<any> {
    try {
      const passwordReset = Auth.forgotPasswordSubmit(
        username,
        code,
        newPassword
      );
      return passwordReset;
    } catch (error) {
      this.checkForbidden(error);
      console.error('Error: ', error);
      return false;
    }
  }

  // Sign-in function
  public async signIn(user: IUser): Promise<any> {
    try {
      //Sign In with AWS Cognito using AWS Amplify
      const userObject = await Auth.signIn(user.email, user.password, {
        // Specify the token scopes you need
        scopes: 'openid email profile aws.cognito.signin.user.admin',
      });
      // Set is own property to be abble to reconnect whit the userObject at the first signIn of the user in changePassword function
      this.userObject = userObject;

      //If Sign In was successful, check the response from AWS
      if (userObject) {
        switch (userObject.challengeName) {
          case 'NEW_PASSWORD_REQUIRED':
            //Handle first time sign-in with temporary passwords
            //Set global challengeName(AWS Response message)
            this.challengeName = userObject.challengeName;
            this.oldPassword = user.password;

            //Return string for conditional logic
            return 'change-password';
            break;

          case 'SMS_MFA':
          case 'SOFTWARE_TOKEN_MFA':
            this.challengeName = userObject.challengeName;
            return 'MFA-required';
            break;

          default:
            //If the Sign-In response was standard, try to fetch the current session
            try {
              const currentSession = await Auth.currentSession().then(
                (session) => {
                  this.authenticationSubject.next(true); //set authenticationSubject
                  // Use the user session to get temporary AWS credentials
                  this.getKeys();
                }
              );

              return userObject; // Sign-in successful
            } catch (error) {
              //Log Session Error
              this.checkForbidden(error);
              console.error('Error getting session:', error);
              return false;
            }
            break;
        }
      } else {
        this.authenticationSubject.next(false); //set authenticationSubject
        return false;
      }
    } catch (error) {
      this.checkForbidden(error);
      //Log Sign In Error
      console.error('Error signing in:', error);
      this.authenticationSubject.next(false); //set authenticationSubject
      return false; // Sign-in failed
    }
  }

  //Signout to end Active Session
  public async signOut(): Promise<any> {
    return await Auth.signOut().then(() => {
      //Update authenticationSubject to prevent navigation without an active user
      this.authenticationSubject.next(false);
    });
  }

  // Function that resend validation code to the user
  async resendValidationCode() {
    const user = await Auth.currentAuthenticatedUser();

    // Send a SMS to the phone number of the user that is provide by cognito
    await Auth.verifyUserAttribute(user, 'email');
  }

  //Handles all password changes functionality
  public async changePassword(user: IUser, newPassword: string): Promise<any> {
    try {
      //Handle first-time login
      if (this.challengeName === 'NEW_PASSWORD_REQUIRED') {
        const newPasswordResult = await Auth.completeNewPassword(
          this.userObject,
          newPassword
        )
          .then(async (result) => {
            if (result) {
              // Set the instance of the user
              const user = await Auth.currentAuthenticatedUser();
              // Send a SMS to the phone number of the user that is provide by cognito
              await Auth.verifyUserAttribute(user, 'email');
            }
          })
          .catch((error) => {
            this.checkForbidden(error);
            console.error('Error: ', error);
          });
      } else {
        //Standard password change for an active and authenticated user
        // Get the currently authenticated user
        await Auth.currentAuthenticatedUser()
          .then((userObj) => {
            // Change user password
            Auth.changePassword(userObj, user.password, newPassword)
              .then(() => {
                return true;
              })
              .catch((error) => {
                this.checkForbidden(error);
                console.error('Error : ', error);
                return false;
              });
          })
          .catch((error) => {
            this.checkForbidden(error);
            console.error('Error : ', error);
            return false;
          });
      }

      //Empty challengeName to stabilize conditional logic for AWS responses
      this.challengeName = '';

      return true;
    } catch (error) {
      //Log Error and return false to handle errors in the relevant component
      this.checkForbidden(error);
      console.error('Error in cognito service:', error);
      return false;
    }
  }

  // Function that verify the code send by email to the user
  async validateEmail(confirmationCode: string) {
    // Set the instance of the current user
    const user = await Auth.currentAuthenticatedUser();
    // Call the function that check the code provide by cognito and send to the user by email
    await Auth.verifyUserAttributeSubmit(user, 'email', confirmationCode)
      .then((result) => {
        this.emailValidationResult = result;
      })
      .catch((error) => {
        this.checkForbidden(error);
        console.error(error);
      });
  }

  // Function that verify the code send by phone number to the user
  async validatePhoneNumber(confirmationCode: string) {
    // Set the instance of the current user
    const user = await Auth.currentAuthenticatedUser();
    // Call the function that check the code provide by cognito and send to the user by phone number
    await Auth.verifyUserAttributeSubmit(user, 'phone_number', confirmationCode)
      .then((result) => {
        this.phoneNumberValidationResult = result;
      })
      .catch((error) => {
        this.checkForbidden(error);
        console.error(error);
      });
  }

  // Function called to sign-in when MFA is activate on cognito user
  async MFASignIn(user: IUser, MFACode: string) {
    try {
      //Sign In with AWS Cognito using AWS Amplify
      const userObject = await Auth.signIn(user.email, user.password, {
        // Specify the token scopes you need
        scopes: 'openid email profile aws.cognito.signin.user.admin',
      });
      // Will make the confirm sign-in if SMS MFA is active for the user
      if (this.challengeName === 'SMS_MFA') {
        // Await confirmation from cognito if user enter the good MFA code
        const newUser = await Auth.confirmSignIn(
          userObject,
          MFACode,
          'SMS_MFA'
        ).then((result) => {});
      }
      // Will make the confirm sign-in if TOTP MFA is active for the user
      if (this.challengeName === 'SOFTWARE_TOKEN_MFA') {
        // Await confirmation from cognito if user enter the good MFA code
        await Auth.confirmSignIn(userObject, MFACode, 'SOFTWARE_TOKEN_MFA')
          .then((confirmation) => {
            // Return the confirmation if so
            return confirmation;
          })
          .catch((error) => {
            this.wrongMFACode.next();
            console.error(error);
            return 'no-MFA-sign-in';
          });
      }
      return '';
    } catch (error: any) {
      this.checkForbidden(error);
      console.error('Error on verification of MFA code :', error);
      return 'no-MFA-sign-in';
    }
  }

  // Function called when user want to sign-up whit SMS MFA code /////////// Not in use for now since no SMS was send from cognito
  async signUpMFACodeSMS(codeMFA: string) {
    try {
      // Get current user authentification
      const user = await Auth.currentAuthenticatedUser();
      // Send to aws the MFA code that he receive in SMS and verified if it's the good code that was send to his phone number
      await Auth.verifyUserAttributeSubmit(user, 'phone_number', codeMFA);
      // If the verifyUserAttributeSubmit work it will automatically activate this user to be authentificated by MFA when he log-in
      await Auth.setPreferredMFA(user, 'SMS_MFA');
      // Trigger a function that will display a message t the user on success
      this.MFASignUpSuccess.next();
    } catch (error) {
      // trigger a function in lgin component to display a message ribon to the user
      this.wrongMFACode.next();
      this.checkForbidden(error);
      console.error('Error in the confirmation of the user: ' + error);
    }
  }

  // Function called  in the sign-up MFA window when user click submit and try to verified if the TOTP token work between his authenticate app and cognito
  async singUpMFACodeTOTP(MFACode: string) {
    try {
      // Get the current CognitoUser
      const user = await Auth.currentAuthenticatedUser();

      // Verify the TOTP token that user enter if cognito can see it
      await Auth.verifyTotpToken(user, MFACode)
        .then((confirmation) => {
          this.confirmMFAMessage = 'mfaSignUpTOTPSuccess';
          // If the verified token is good, confirmation will be success and we'll set the MFA preference of the user to SOFTWARE_TOKEN_MFA
          Auth.setPreferredMFA(user, 'SOFTWARE_TOKEN_MFA');
          // Redirect the user to the user-edit page
          this.router.navigate(['/user-edit']);
          // Trigger a function that will display a message t the user on success
          this.MFASignUpSuccess.next();
        })
        .catch((error) => {
          this.checkForbidden(error);
          console.error(error);
          // Trigger a function in the login component to display a message ribon to the user
          this.wrongMFACode.next();
        });
    } catch (error) {
      this.checkForbidden(error);
      console.error(error);
    }
  }

  // Function that send a SMS to the user so he will be able to activate the SMS_MFA
  /////////////////// TODO: will need to be continued when we get access to SMS from cognito /////////////////////
  async sendSMS() {
    try {
      // Set the instance of the user
      const user = await Auth.currentAuthenticatedUser();
      // Send a SMS to the phone number of the user that is provide by cognito
      await Auth.verifyUserAttribute(user, 'phone_number');
    } catch (error) {
      this.checkForbidden(error);
      console.error('Error on sending SMS: ' + error);
    }
  }

  // Function that get a QR code
  async getQrCode() {
    Auth.currentAuthenticatedUser()
      .then(async (user) => {
        // Call the function to get a secret code from cognito and put it into qrCode
        const qrCode = await Auth.setupTOTP(user);
        // Set the url string to get a readable QR code from lib qrcode
        const qrCodeUrl =
          'otpauth://totp/' +
          user.username +
          '?secret=' +
          qrCode +
          '&issuer=IFM&algorithm=SHA1&digits=6&period=30';
        // Call function toDataUrl from lib qrcode to get an imageUrl of the qr code readable for the user
        qrcode
          .toDataURL(qrCodeUrl)
          .then((imageUrl: any) => {
            this.qrCodeImgActive = true;
            // Set the result in a variable that will be used in HTML to display the Qr code to the user
            this.imgUrl = imageUrl;
          })
          .catch((error: any) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
        this.checkForbidden(error);
      });
  }

  // Get user MFA preference
  async getUserMFAPreference() {
    // Get the instence of the user
    await Auth.currentAuthenticatedUser().then((cognitoUser) => {
      // Get user MFA preference if this user is allready set
      Auth.getPreferredMFA(cognitoUser, { bypassCache: true })
        .then((result) => {
          this.userMFAPreference = result; // Set variable to the preference of MFA of the user
        })
        .catch((err) => {
          this.checkForbidden(error);
          console.error(err);
        });
    });
  }

  // Get the user type (custom attributes of cognito) to implement the bin create page whit the good selects
  async getUserType() {
    // Get the instance of the current user
    await Auth.currentAuthenticatedUser().then((userObject) => {
      // Set the user type variable that bin create and update will use
      this.userType = userObject.attributes['custom:user_type'];

      // Switch between user type to get the client or distributor ID and set it to is own variable
      switch (this.userType) {
        case 'muirwood':
          // set the clientId variable to the userObject attribute
          this.clientId = userObject.attributes['custom:client_id'];
          break;
        case 'distributor':
          // Set the distributorId variable to the userObject attribute
          this.distributorId = userObject.attributes['custom:distributor_id'];
          break;
        case 'client':
          // set the clientId variable to the userObject attribute
          this.clientId = userObject.attributes['custom:client_id'];
          break;
        case 'operator':
          // Set the operatorId variable to the userobject attrbute
          this.operatorId = userObject.attributes['custom:operations_id'];
          break;
      }
    });
  }

  // Function that call the lambda function to get all distributor info's
  getDistributorsInfos(): Promise<any[]> {
    return new Promise<any[]>((resolve) => {
      // Call the function that call the lambda function
      this.getDistributorsInfosLambda().subscribe((response) => {
        // Put the array of distributors returned by the lambda function into distributors array
        this.distributorsInfosArray = response;
        resolve(this.distributorsInfosArray);
      });
    });
  }

  // Function that call the lambda funciton to get all clients info's
  getClientsInfos(): Promise<any[]> {
    return new Promise<any[]>((resolve) => {
      // Call the function that call lambda function
      this.getClientsInfosLambda().subscribe((response) => {
        // Put the array returned by lambda function into clients array
        this.clientsInfosArray = response;
        resolve(this.clientsInfosArray);
      });
    });
  }

  // Function that call the lambda function and receive all Distirbutor's infos
  getDistributorsInfosLambda() {
    // Initiate url whit the url of lambda fonction getDistributors
    const url = environment.api.stage + environment.api.route.getDistributors;
    // Call the lambda fonction whit the url
    return this.http.get(url).pipe(
      map((response) => {
        return response; // Return response data
      }),
      catchError((error) => {
        console.error('API Error : ' + error);
        throw error;
      })
    );
  }

  // Function that cl the lambda function and receive all client's infos
  getClientsInfosLambda() {
    // Initiate url with the url of the lambda function getClients
    const url = environment.api.stage + environment.api.route.getClients;
    // Call the lambda function with the url
    return this.http.get(url).pipe(
      map((response) => {
        this.array = response;

        this.array = this.array.forEach((element: any) => {
          this.switchJSONToIntForClientResponse(element);
        });
        return response; // Return response data
      }),
      catchError((error) => {
        console.error('API error : ' + error);
        throw error;
      })
    );
  }

  // Adds User to Cognito User Group. Called during User creation.
  async addUserToGroup(username: string, groupName: string) {
    try {
      //Declare Cognito Identity Provider class
      const cognitoIdentityServiceProvider =
        new AWS.CognitoIdentityServiceProvider();

      //Set reqiored parameters for the API call
      const params = {
        UserPoolId: environment.cognito.userPoolId,
        Username: username,
        GroupName: groupName,
      };

      // Call adminAddUserToGroup API to add the user to the group
      await cognitoIdentityServiceProvider
        .adminAddUserToGroup(params)
        .promise();
    } catch (error) {
      //log error in console
      this.checkForbidden(error);
      console.error('Error adding user to group:', error);
    }
  }

  // Function used by SAML log-in
  async federatedSignIn(accessToken: string, expires: number, name: string, email: string ) {
    return Auth.federatedSignIn(
    "login.microsoftonline.com/c0b50357-f018-4f60-bf08-47342010bf9b/v2.0",
      {
        token: accessToken,
        expires_at: expires,
      },
      {
        name : name,
        email: email
      }
    );
  }

  // Function called from getAllUser to get the proper filter of user by the id of the current user
  getFilterString(kind: string){
    let id = '';

    switch(kind){
      case 'distributor':
        id = "custom:distributor_id=\"" + this.distributorId + "\"";
        break;
      case 'client':
        id = "custom:client_id=\"" + this.clientId + "\"";
        break;
      case 'operations  ':
        id = "custom:operator_id=\"" + this.operatorId + "\"";
        break;
    }
    return id;
  }

  // Function called to set cognito user in data base
  async createDBUser(){
    // Call the function that call the lambda function
    this.createDBUserLambda().subscribe((response) => {
      this.successMessage = response; // Return response from lambda and put it in success message so bin-list or bin-model-list and will be able to read it
      if(this.successMessage.message === 'success'){
        this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
      }else{
        this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
      }
    });
  }

  // Function called to update cognito user in data base
 async updateDBUser(){
    // Call the function that made the call to lambda function and wait for is response
    this.updateDBUserLambda().subscribe((response) => {
      this.successMessage = response;
      if(this.successMessage.message === 'success'){
        this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
      }else{
        this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
      }
    });
  }

  // Function called to enable/disable user access in data base
 async enableDisableDBUser(){
    // Call the function that made the call to lambda function and wait for is response
    this.enableDisableDBUserLambda().subscribe((response) => {
      this.successMessage = response;
      if(this.successMessage.message === 'success'){
        this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
      }else{
        this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
      }
    });
  }

  // Function that call lambda to create user in data base
  createDBUserLambda(){
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate, 'yyyyMMdd', 'en_US');
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Will call the lambda function in createBin url whit the passed data then return a response
    return this.http.post(environment.api.stage + environment.api.route.createUser, {
      // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        "user": this.userSub,
        "username": this.cognitoUserData.username,
        "given_name": this.cognitoUserData.given_name,
        "family_name": this.cognitoUserData.family_name,
        "phone_number": this.cognitoUserData.phone_number,
        "email": this.cognitoUserData.email,
        "address": this.cognitoUserData.address,
        "user_type": this.cognitoUserData.custom_user_type,
        "current_role": this.cognitoUserData.custom_current_role,
        "client_id": this.cognitoUserData.custom_client_id,
        "distributor_id": this.cognitoUserData.custom_distributor_id,
        "operator_id": this.cognitoUserData.custom_operator_id,
        "enable": this.cognitoUserData.enabled,
        "created": formattedDate,
        "modified": formattedDate
      }, { headers: headers }
    );
  }

  // Function that call lambda to update user in data base
  updateDBUserLambda(){
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate, 'yyyyMMdd', 'en_US');

    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Call lambda fucntion whit the url of updateBin  and return the response
    return this.http.post(environment.api.stage + environment.api.route.updateUser, {
      // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
      "user": this.cognitoUserData.sub,
      "given_name": this.cognitoUserData.given_name,
      "family_name": this.cognitoUserData.family_name,
      "phone_number": this.cognitoUserData.phone_number,
      "address": this.cognitoUserData.address,
      "current_role": this.cognitoUserData.custom_role,
      "modified": formattedDate
      }, {headers : headers}
    );
  }

  // Function that call lambda to enable/disable user access in data base
  enableDisableDBUserLambda(){
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate, 'yyyyMMdd', 'en_US');

    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Call lambda fucntion whit the url of updateBin  and return the response
    return this.http.post(environment.api.stage + environment.api.route.enableDisableUser, {
      // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
      "user": this.cognitoUserData.sub,
      "enable": this.cognitoUserData.enabled,
      "modified": formattedDate
      }, {headers : headers}
    );
  }

  // function that sing-out user if there's an error with his credentials
  async checkForbidden(error: any){
    this.verifySession();
    // Your asynchronous operation that may result in a 403 error
    if (error.toString().includes('Forbidden') || error.toString().includes('Expired')) {
      // Handle 403 error here
      await Auth.signOut().then(()=>{
        let theme = localStorage.getItem('theme') ?? "light";
        theme = theme?.replaceAll(/["\\]/g, '') ?? "light";
        // Clear the local storage
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem("theme",'"'+theme+'"')
        if(this.router.url !== '/login'){ this.router.navigate(['/login']);}
      });

    } else {
      this.configure();
      // Handle other errors
      console.error('An error occurred:', error);
    }
  }

  // Function that get all user datas from DB
  getUserData(sub:string){
     // Define the HTTP headers with content type
     const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    const url =environment.api.stage + environment.api.route.getUser +'&user='+sub
    return this.http.get(url,{headers});
  }
}
