import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ThemeService } from '../service/theme.service';
import { NavigationService } from '../service/navigation.service';
import { SystemMessageService } from '../service/system-message.service';
import { DispatcherControlCenterComponent } from '../dispatcher-control-center/dispatcher-control-center.component';
import { OperatorService } from '../service/operator.service';
import { DriverInfo } from "../service/driver.service";

export interface assignedRoute {
  routeId?: string;
  driverId: string;
  routeStartDate: string;
  comments?: string;
}

@Component({
  selector: 'app-assign-route-form-modal',
  templateUrl: './assign-route-form-modal.component.html',
  styleUrls: ['./assign-route-form-modal.component.css', '../../global-elements.css' ]
})

export class AssignRouteFormModalComponent implements OnInit {

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  private startTouchY: number = 0;
  public routeStart: string = "";
  public routeComments: string = "";
  public driverSelected: any;
  public driverId: string = "";


  constructor(
    private localStorageService: LocalStorageService,
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private router: Router,
    public theme: ThemeService,
    public navigationService: NavigationService,
    public systemMessage: SystemMessageService,
    public dispatcherControlCenter: DispatcherControlCenterComponent, //TODO Replace with service?
    public operatorService: OperatorService,
    // public cognitoService: CognitoService,
    // public roleService: RoleService,
    // public clientService: ClientService,
    // public validationService: ValidationService,
    // private activatedRoute: ActivatedRoute,
    // private iotService: IotService,
    // private distributorService: DistributorsService,
    // private binService: BinsService,
    // public workOrderService: WorkOrderService,
  ) {

    // this.cognitoService.confirmValidUser();
    // this.roleService.getRoles();

    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null){
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
  }

  drivers: DriverInfo[] = this.operatorService.operatorDriversArray;

  ngOnInit(): void {

  }

  cancel() {
    this.dispatcherControlCenter.showAssignRouteModal = false
  }

  assignRoute(driver: any) {
    // Log the selected driver (for debugging purposes)
    // console.log(driver);

    // Get the start date for the route, the selected truck ID, and the selected driver ID
    const routeStartDate = this.routeStart;
    const truckId = this.dispatcherControlCenter.selectedTruck?.truck_id ?? "";
    const driverId = driver;

    // Use switch with true to check the conditions sequentially
    switch (true) {

      case !truckId:
        this.systemMessage.selectRibbon('danger', "Missing truck ID");
      return

      case !driverId:
        this.systemMessage.selectRibbon('danger', "Missing driver ID");
      return

      case !routeStartDate:
        this.systemMessage.selectRibbon('danger', "Missing route start date");
      return;

      default:
      break;
    }

    // create the new assigned route
    const newAssignedRoute: assignedRoute = {
      routeId: truckId,
      driverId: driverId,
      routeStartDate: routeStartDate,
      comments: this.routeComments || ''
    };

    // console.log(newAssignedRoute)

    try {
      // to do assignRouteToApi
      this.systemMessage.selectRibbon('success', "routeAssigned");
      this.dispatcherControlCenter.showAssignRouteModal = false

    } catch (error) {
      // Handle and log any errors that occur during the process
      console.error("Error: ", error);
    }

  }

  selectedDriverChange(event: any) {
    // console.log(event)
  }

  updateRouteStart(event: any) {
    // Store the current date and time
    // const currentDate = new Date();

    // Get the date and time string from the user input (e.g., from a datetime picker)
    const selectedDateTimeString = event.target.value;

    // Create a Date instance using the user-selected date and time string
    const selectedDateTime = new Date(selectedDateTimeString);

    // Convert the selected date and time to a numeric value representing milliseconds since January 1, 1970
    var selectedDateTimeAsNumbers = selectedDateTime.getTime();

    // Store the numeric representation as a string in the routeStart variable
    this.routeStart = selectedDateTimeAsNumbers.toString();

  }

   // Function triggered when touch starts for scrolling
   startScroll(event: TouchEvent) {
    // Store the initial Y coordinate of the touch
    this.startTouchY = event.touches[0].clientY;
  }

  // Function to handle scrolling during touch movement
  scrollTextarea(event: TouchEvent) {
    // Calculate the change in Y coordinate since the last touch event
    const deltaY = event.touches[0].clientY - this.startTouchY;

    // Update the startTouchY for the next calculation
    this.startTouchY = event.touches[0].clientY;

    // Get the textarea element by its ID ('descricao')
    const textarea = document.getElementById('descricao') as HTMLTextAreaElement;

    // Update the scrollTop property of the textarea to simulate scrolling
    textarea.scrollTop += deltaY;
  }

  // Function triggered when touch ends, resetting the startTouchY
  stopScroll() {
    // Reset the startTouchY to 0 when touch ends
    this.startTouchY = 0;
  }

}
