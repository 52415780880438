<app-system-message></app-system-message>
<article [ngClass]="theme.getThemeClass()">
    <gridster [ngClass]="theme.getThemeClass()" [options]="gridsterOptions" style="height: 100vh!important;">
      <ng-container *ngFor="let item of navigationItemsArray" >
      <gridster-item  [item]="item" *ngIf="item.name !== 'sidenavMenu.HOME'">
        <!-- Your tile content goes here -->
        <nav [ngClass]="theme.getThemeClass()" class="dashboard-tile">
          <header class="dashboard-tile-header">
            <h6 class="dashboard-tile-title"><a [routerLink]="['/', item.routeUrl]">{{item.name | translate}}</a></h6>
            <span class="dashboard-tile-action-container">

              <a class="nav-link e" href="javascript:void(0);" style="border: none;"  data-bs-toggle="dropdown">
                <i class="fa fa-bars"></i>
              </a>
              <ul [ngClass]="theme.getThemeClass()" class="ulDrop dropdown-menu dropdown-menu-light">
                <!-- Menu Items -->
                <ng-container *ngFor="let action of item.actions">
                  <li *ngIf="action.title" class="actionsTitle">{{ action.title | translate }}</li>
                  <ng-container *ngFor="let option of action.options">
                    <li class="actionsOption">
                      <a (click)="handleLinkClick($event, option.route)" [ngClass]="theme.getThemeClass()" style="cursor: pointer;" [ngClass]="{'disabled-link': action.title === 'graphs'}"  >
                        <i class="textAlignCenter" [ngClass]="option.icon"></i>
                        <span class="customMarginLeft1">
                          {{ option.name | translate }}
                        </span>
                      </a>
                    </li>
                  </ng-container>
                </ng-container>
              </ul>
            </span>
          </header>
          <div [ngClass]="theme.getThemeClass()"  class="dashboard-tile-content">

             <!-- <app-loading-element></app-loading-element> -->
             <nav *ngIf="!dashboardService.stopLoading && item.name !== 'sidenavMenu.ZONES'" class="loading-gif">
              <!-- Container with box styles and fade-in-out animation -->
                <div class="box fade-in-out" >
                  <!-- Font Awesome spinner icon with custom styles -->
                  <div class="fa-3x mb-2">
                      <i class="fa-solid fa-spinner fa-spin-pulse fa-2xl color"></i>
                  </div>
                  <!-- Logo container with dynamic theme class and navigation link -->
                  <div>
                      <a [ngClass]="theme.getThemeClass()"
                      class=" hide-too-small-screen navbar-brand px-1" href="javascript:void(0);" data-bs-toggle="dropdown">
                          <!-- Logo image with custom styles -->
                          <img class="logo" src="../assets/muirwood-studio.png">
                      </a>
                  </div>
                </div>
              </nav>
            <!-- Chart section -->
            <app-pie-chart *ngIf="item.name === 'sidenavMenu.client.IOT'"></app-pie-chart>
            <app-bar-chart  *ngIf="item.name === 'sidenavMenu.client.BINS'"></app-bar-chart>
            <app-bar-chart-admin *ngIf="item.name === 'sidenavMenu.client.ADMIN'"></app-bar-chart-admin>
            <app-pie-chart-single *ngIf="item.name === 'sidenavMenu.client.WORKORDER'"></app-pie-chart-single>
          </div>
        </nav>
      </gridster-item>
    </ng-container>
    </gridster>
  </article>
