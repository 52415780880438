import { Component } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { DispatchService } from '../service/dispatch.service';
import {ClientInterface} from '../service/dispatch.service';

@Component({
  selector: 'dispatch-contact-modal',
  templateUrl: './dispatch-contact-modal.component.html',
  styleUrl: './dispatch-contact-modal.component.css'
})
export class DispatchContactModalComponent {
  public clientContact:ClientInterface = {
    active: '',
    address: '',
    auto_collect: '',
    checked: false,
    client_id: '',
    client_logo: null,
    client_name: '',
    created: null,
    email: '',
    legal_name: '',
    modified: null,
    mws: '',
    phone_number: ''
  }
  public communiation:boolean = false;
  public position:boolean = true;
  
  constructor(
    public theme: ThemeService,
    public dispatchService: DispatchService
  ){}

  ngOnInit(){
    this.clientContact = this.dispatchService.clientContact
 }

 navigate(tab:number){
  switch(tab){
    case 1:
      this.communiation = false
      this.position = true
      break;
    case 2:
      this.communiation = true
      this.position = false
      break;

  }
 }
  closeModal(){
    this.dispatchService.isContactModalOpen = false;
  }
}
