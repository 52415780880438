import { Component } from '@angular/core';
import { NotificationService } from '../service/notification.service';
import { ThemeService } from '../service/theme.service';
import { NotificationStatuses } from "../constants/notification-statuses";

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent {
  public NotificationStatuses = NotificationStatuses; // Expose to the html

  constructor(
    public notificationService: NotificationService,
    public theme: ThemeService,
    ){ }

  public setNotificationAsRead(notificationId: string){
    this.notificationService.updateNotificationStatus(notificationId, NotificationStatuses.READ);
  }

  public getTimestampDisplay(timestamp: number): string {
    // Implement logic to format timestamp in hours/days
    // You can use libraries like moment.js or Angular DatePipe for this
    // For now simply format to EasternTime
    return NotificationListComponent.formatTimestampToEasternTime(timestamp);
  }

  // Function that take a timestamp and set it to a unix date
  private static formatTimestampToEasternTime(timestamp: number): string {
    if (!timestamp) return '';

    const timestampMilliseconds = timestamp * 1000;
    const date = new Date(timestampMilliseconds);

    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });

    return formatter.format(date);
  }
}
