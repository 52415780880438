<!-- Google Maps API script -->
<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCyzMRxB8Re27NYcuI8KDFCEucI0GwHLHA"></script>

<!-- App for the sytem message ribbon showed on CRUD -->
<app-system-message class="system-message"></app-system-message>

<!-- Container for the IOT component -->
<div
  [ngClass]="theme.getThemeClass()"
  class="container-fluid iotComponentDiv"
  (window:scroll)="onScroll()"
>
  <!-- Menu IOT COMPONENT -->
  <header>
    <nav
      [ngClass]="theme.getThemeClass()"
      class="navbar navbar-expand-lg navbar-light bg-light"
      style="
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
      "
    >
    
      <!-- Navigation links for IOT component on top nav-bar -->
      <div
        style="width: 100%; justify-content: center"
        class="navbar-nav flex-nowrap"
        id="navbarNavAltMarkup"
      >
        <!-- Links for different sections -->
        <a
          class="nav-item nav-link"
          href="javascript:void(0)"
          (click)="devicesClicked()"
          [ngClass]="{ active: isDevicesActive }"
        >
          {{ "iotNavButtonDevices" | translate }}
        </a>
        <a
          class="nav-item nav-link hide-small-screen"
          href="javascript:void(0)"
          (click)="technicalClicked()"
          [ngClass]="{ active: isTechnicalActive }"
        >
          {{"technical" | translate}}
        </a>
        <!-- Hides two buttons to fit small screen view with the class "hide-small-screen" -->
        <a
          class="nav-item nav-link hide-small-screen"
          href="javascript:void(0)"
          (click)="reportsClicked()"
          [ngClass]="{ active: isConnectorsActive }"
        >
          {{ "iotNavButtonReports" | translate }}
        </a>
        <a
          class="nav-item nav-link hide-small-screen"
          href="javascript:void(0)"
          (click)="shadowFeedClicked()"
          [ngClass]="{ active: isShadowFeedActive }"
        >
          {{ "shadowFeed" | translate }}
        </a>
      </div>
      <!-- Show two buttons to fit small screen view -->
      
      <div
        [ngClass]="theme.getThemeClass()"
        class="navbar-nav flex-nowrap divButtonsSmallScreen"
        id="navbarNavAltMarkup"
      >
        <a
          class="nav-item nav-link show-small-screen hide-big-screen"
          href="javascript:void(0)"
          (click)="reportsClicked()"
          [ngClass]="{ active: isConnectorsActive }"
        >
          {{ "iotNavButtonReports" | translate }}
        </a>
      </div>
      <div
        [ngClass]="theme.getThemeClass()"
        class="navbar-nav flex-nowrap divButtonsSmallScreen"
        id="navbarNavAltMarkup"
      >
        <a
          class="nav-item nav-link show-small-screen hide-big-screen"
          href="javascript:void(0)"
          (click)="shadowFeedClicked()"
          [ngClass]="{ active: isShadowFeedActive }"
        >
          {{ "shadowFeed" | translate }}
        </a>
      </div>
    </nav>
  </header>
  <!-- DEVICES VIEW -->
   
  <div
  [ngClass]="theme.getThemeClass()"
    id="rowContent"
    class="rowContentDevices"
    *ngIf="mainContent === 'devices'"
  >
    <!-- Map div, only showed when on device tab -->
    <div [hidden]="hideMap" id="columMap" class="hide-small-screen columMap" *ngIf="mainContent === 'devices'">
      <article [ngClass]="theme.getThemeClass()" class="mapHeader">
        <div>
          <p id="lastExpanded">{{ lastExpanded }}</p>
          <p id="lastExpandedAddress">{{ address | translate }}</p>
        </div>
        <p
          (click)="closeMap()"
          id="closeMap"
          title="{{ 'iotModalButtonClose' | translate }}"
        >
          <i class="fa fa-times" aria-hidden="true"></i>
        </p>
      </article>
      <div
        #mapContainer
        id="mapDesktop"
        class="mapDesktop"
      ></div>
    </div>
    <section id="columList" class="hide-small-screen columList table-big-sreen">
      
      <article
        [ngClass]="theme.getThemeClass()"
        class="table-container"
        *ngIf="mainContent === 'devices'"
      >
        <!-- Filter and sort Section -->
        <div class="table-responsive">
          <nav
            colspan="5"
            id="search-component"
            class="input-group searchDiv"
            *ngIf="mainContent === 'devices'"
          >
            <input
              #searchBoxDevice
              type="search"
              id="search-box-device"
              [(ngModel)]="deviceFilter"
              (input)="this.search()"
              class="form-control rounded searchInput"
              placeholder="{{ 'iotSearchPlaceHolderFilter' | translate }}"
            />
            <div class="sort-by-group">
              <label class="sort-by-group-label">{{
                "deviceGroup" | translate
              }}</label>
              <ng-select
                [(ngModel)]="this.filterThingGroup"
                class="form-control sort-by-group-select"
                name="thingGroup"
                (change)="sortByGroupChange()"
              >
                <ng-option value="" selected>{{
                  "allGroups" | translate
                }}</ng-option>
                <ng-option value="dev" selected>DEV</ng-option>
                <ng-option value="poc" selected>POC</ng-option>
                <ng-option value="prd" selected>PRD</ng-option>
              </ng-select>
            </div>
          </nav>
          <!-- Table header and button action to create new device -->
          <article
            [ngClass]="theme.getThemeClass()"
            class="table-header"
            *ngIf="mainContent === 'devices'"
          >
            <span class="left"
              >{{ "userlistTableHeaderDeviceList" | translate }} ({{
                iotService.devicesArray.length
              }})
              <a (click)="refreshList()"
                ><i class="scale-0-8 fas fa-sync"></i
              ></a>
            </span>
            <span class="right">
              <button
                [ngClass]="theme.getThemeClass()"
                class="btn btn-dropdown"
                id="buttonDropdown"
                routerLink="/device-create"
              >
                {{ "newIot" | translate }}
              </button>
            </span>
          </article>
                                          <!-- ****************** Table device view on desktop ******************* -->
          <table class="table table-hover">
            <!-- Table column header section -->
            <thead>
              <!-- Thing name -->
              <tr>

                <!-- Device header -->
                <th
                  [ngClass]="theme.getThemeClass()"
                  scope="col"
                  class="table-header-device"
                >
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('thingName')"
                    href="javascript:void(0)"
                  >
                    {{ "iotTabHeaderDevice" | translate }} </a
                  >
                  <!-- IMG arrow up/down on sorting -->
                  <img
                    *ngIf="
                      this.sortDirection === 'asc' &&
                      this.sortBy === 'thingName'
                    "
                    src="./assets/arrow-up.png"
                    width="10px"
                    height="10px"
                  /><img
                    *ngIf="
                      this.sortDirection === 'desc' &&
                      this.sortBy === 'thingName'
                    "
                    src="./assets/arrow-down.png"
                    width="10px"
                    height="10px"
                  />
                </th>

                <!-- Version header -->
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('version')"
                    href="javascript:void(0)"
                  >
                    {{ "version" | translate }}
                  </a>
                  <!-- IMG arrow up/down on sorting -->
                  <img
                    *ngIf="
                      this.sortDirection === 'asc' && this.sortBy === 'version'
                    "
                    src="./assets/arrow-up.png"
                    width="10px"
                    height="10px"
                  /><img
                    *ngIf="
                      this.sortDirection === 'desc' && this.sortBy === 'version'
                    "
                    src="./assets/arrow-down.png"
                    width="10px"
                    height="10px"
                  />
                </th>
                <!-- Client association of device. Sort not implemented -->
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    href="javascript:void(0)"
                  >
                    {{"_client" | translate}}
                  </a>
                </th>
                <!-- Disrtibutor association on device. Sort not implemented -->
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    href="javascript:void(0)"
                  >
                    {{"_distributor" | translate}}
                  </a>
                </th>
                <!-- Bin model association of device. Sort not implemented -->
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    href="javascript:void(0)"
                  >
                    {{"binModel" | translate}}
                  </a>
                </th>
                <!-- Bin association of device. Sort not implemented -->
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    href="javascript:void(0)"
                  >
                    {{"binHeader" | translate}}
                  </a>
                </th>
                <!-- Fill level of the bin associated to the device -->
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('filled')"
                    href="javascript:void(0)"
                  >
                    {{ "filled" | translate }}
                  </a>
                  <!-- IMG arrow up/down on sorting -->
                  <img
                    *ngIf="
                      this.sortDirection === 'asc' && this.sortBy === 'filled'
                    "
                    src="./assets/arrow-up.png"
                    width="10px"
                    height="10px"
                  /><img
                    *ngIf="
                      this.sortDirection === 'desc' && this.sortBy === 'filled'
                    "
                    src="./assets/arrow-down.png"
                    width="10px"
                    height="10px"
                  />
                </th>
                <!-- Volume of the bin/bin model associated to the device. Sort not implemented -->
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    href="javascript:void(0)"
                  >
                    {{"volume" | translate}}
                  </a>
                </th>
                <!-- Market segment of the bin associated to the device. Sort not implemented -->
                <th [ngClass]="theme.getThemeClass()">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    href="javascript:void(0)"
                  >
                    {{"market" | translate}}
                  </a>
                </th>
                <!-- Usage of the bin associated to the device. Sort not implemented -->
                <th [ngClass]="theme.getThemeClass()">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    href="javascript:void(0)"
                  >
                    {{"binListViewUsage" | translate}}
                  </a>
                </th>
                <!-- Status of the bin associated to the device -->
                <th
                  [ngClass]="theme.getThemeClass()"
                  scope="col"
                  class="table-header-status"
                >
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('status')"
                    href="javascript:void(0)"
                  >
                    {{ "iotTabHeaderStatus" | translate }}
                  </a>
                  <!-- IMG arrow up/down on sorting -->
                  <img
                    *ngIf="
                      this.sortDirection === 'asc' && this.sortBy === 'status'
                    "
                    src="./assets/arrow-up.png"
                    width="10px"
                    height="10px"
                  /><img
                    *ngIf="
                      this.sortDirection === 'desc' && this.sortBy === 'status'
                    "
                    src="./assets/arrow-down.png"
                    width="10px"
                    height="10px"
                  />
                </th>
                <!-- State of device. Sorted on last update date -->
                <th [ngClass]="theme.getThemeClass()">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('date')"
                    href="javascript:void(0)"
                  >
                    {{ "state" | translate }}
                  </a>
                  <!-- IMG arrow up/down on sorting -->
                  <img
                    *ngIf="
                      this.sortDirection === 'asc' && this.sortBy === 'date'
                    "
                    src="./assets/arrow-up.png"
                    width="10px"
                    height="10px"
                  /><img
                    *ngIf="
                      this.sortDirection === 'desc' && this.sortBy === 'date'
                    "
                    src="./assets/arrow-down.png"
                    width="10px"
                    height="10px"
                  />
                </th>
                <!-- Last activity of the device -->
                <th
                  [ngClass]="theme.getThemeClass()"
                  class="hide-small-screen table-header-last-activity"
                  scope="col"
                >
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('date')"
                    href="javascript:void(0)"
                  >
                    {{ "iotTabHeaderLastActivity" | translate }} </a
                  >
                  <!-- IMG arrow up/down on sorting -->
                  <img
                    *ngIf="
                      this.sortDirection === 'asc' && this.sortBy === 'date'
                    "
                    src="./assets/arrow-up.png"
                    width="10px"
                    height="10px"
                  /><img
                    *ngIf="
                      this.sortDirection === 'desc' && this.sortBy === 'date'
                    "
                    src="./assets/arrow-down.png"
                    width="10px"
                    height="10px"
                  />
                </th>

                <!-- Ressources header -->
                <th
                  [ngClass]="theme.getThemeClass()"
                  class="hide-small-screen table-header-ressources"
                  scope="col"
                >
                  {{ "iotTabHeaderResources" | translate }}
                </th>
              </tr>
            </thead>
            <!-- Table content -->
            <tbody>
              <!-- Table populated with data from array (deviceArray) of devices in iotService -->
              <ng-container
                *ngFor="
                  let deviceRow of iotService.devicesArray.slice(
                    0,
                    loadedItems + itemsPerPage
                  );
                  let i = index
                "
              >
                <tr #minhadiv class="rowTableData">
                  <!-- Thing name or device name -->
                  <td [ngClass]="theme.getThemeClass()">
                    {{ deviceRow.thingName }}
                  </td>
                  <!-- Version of the device -->
                  <td [ngClass]="theme.getThemeClass()">
                    {{ deviceRow.dat.fdv }}
                  </td>
                  <!-- Client associated -->
                  <td [ngClass]="theme.getThemeClass()">
                    {{ deviceRow.bin?.client ?? "N/A" }}
                  </td>
                  <!-- Distributor associated -->
                  <td [ngClass]="theme.getThemeClass()">
                    {{ deviceRow.bin?.legal_name ?? "N/A" }}
                  </td>
                  <!-- Bin model associated -->
                  <td [ngClass]="theme.getThemeClass()">
                    {{ deviceRow.bin?.bin_model_number ?? "N/A" }}
                  </td>
                  <!-- Bin associated -->
                  <td [ngClass]="theme.getThemeClass()">
                    {{ deviceRow.bin?.bin_name ?? "N/A" }}
                  </td>
                  <!-- Fill percentage of the bin associated -->
                  <td [ngClass]="theme.getThemeClass()">
                    {{
                      calculateFillPercentage(
                        deviceRow.bin?.bin_height,
                        deviceRow.dat.dst
                      )
                    }}%
                  </td>
                  <!-- Volume of the bin associated -->
                  <td [ngClass]="theme.getThemeClass()">
                    {{
                      deviceRow.bin?.bin_height
                        ? deviceRow.bin?.total_volume
                        : "N/A"
                    }}
                  </td>
                  <!-- Market segment of the bin associated -->
                  <td [ngClass]="theme.getThemeClass()">
                    {{
                      binService.getBinMarket(
                        deviceRow.bin?.market_segment ?? ""
                      ) | translate
                    }}
                  </td>
                  <!-- Usage of the bin associated -->
                  <td [ngClass]="theme.getThemeClass()">
                    {{
                      binService.getBinUsage(deviceRow.bin?.bin_usage ?? "")
                        | translate
                    }}
                  </td>
                  <!-- Status of the device -->
                  <td [ngClass]="theme.getThemeClass()">
                    <div
                      [ngClass]="{
                        healthy:
                          verifyStatus(deviceRow.thingName) === 'Healthy',
                        unhealthy:
                          verifyStatus(deviceRow.thingName) === 'Unhealthy',
                        inactive:
                          verifyStatus(deviceRow.thingName) === 'Inactive'
                      }"
                    >
                      {{ verifyStatus(deviceRow.thingName) | translate }}
                    </div>
                  </td>
                  <!-- State of the device -->
                  <td [ngClass]="theme.getThemeClass()" class="light-td" (mouseover)="showStateInfo[i] = true"
                  (mouseleave)="showStateInfo[i] = false">
                    <div
                      class="trafic-light-box"

                    >
                      <!-- Traffic light used for user to easily see if device have communicated for long time -->
                      <div class="traffic-light">
                        <div
                          class="light"
                          [ngClass]="{
                            'light-green': deviceRow.heartbeat && deviceRow.heartbeat.color === 'green',
                            'light-yellow': deviceRow.heartbeat && deviceRow.heartbeat.color === 'yellow',
                            'light-red': deviceRow.heartbeat && deviceRow.heartbeat.color === 'red'
                          }"
                        ></div>
                      </div>
                      <!-- Info bubble on mouse over displaying number of beat miss, when he was suppose to comunicate and his sleep time config -->
                        <div
                        class="info-state-box"
                        [ngClass]="theme.getThemeClass()"
                        *ngIf="showStateInfo[i]"
                      >
                        <div class="state-info-layer">
                          <label class="state-title">
                            {{ "beatMiss" | translate }} :
                          </label>
                          <label class="state-info">
                            <span *ngIf="this.iotService.isBeatMissMoreThenOneHundred(deviceRow.heartbeat?.beat_miss)">+ </span>
                            {{ deviceRow.heartbeat?.beat_miss || 0 }}
                          </label>
                        </div>
                        <div>
                          <label class="state-title">
                            {{ "suposeToCommunicate" | translate }} :
                          </label>
                          <label class="state-info">
                            {{
                              deviceRow.heartbeat?.supose_to_communicate
                            }}
                          </label>
                        </div>
                        <div>
                          <label class="state-title">
                            {{ "sleepTimeConfig" | translate }} :
                          </label>
                          <label class="state-info">
                            {{
                              this.iotService.convertSecondToHour(
                                deviceRow.config?.slp
                              ) | translate
                            }}
                          </label>
                        </div>
                      </div>
                      <!-- End of the bubble -->
                    </div>
                  </td>
                  <!-- Last communication date/time -->
                  <td
                    class="hide-small-screen"
                    [ngClass]="theme.getThemeClass()"
                  >
                    <a
                      class="link-shadow-feed"
                      (click)="goToShadowFeed(deviceRow.thingName)"
                    >
                      {{ deviceRow.date }}
                    </a>
                  </td>
                  <!-- Ressources section displayed with little icons link -->
                  <td [ngClass]="theme.getThemeClass()">
                    <div class="divButton">
                      <!-- Show the map with pin of the ddevice position -->
                      <a href="javascript:void(0)" (click)="showMap(deviceRow)">
                        <img
                          [title]="'showLocation' | translate"
                          class="iconMap"
                          src="./assets/mappoint.png"
                          alt="icon of a map"
                          height="20"
                          width="20"
                        />
                      </a>
                      <!-- Show modal for device configurations -->
                      <a
                        href="javascript:void(0)"
                        (click)="
                          this.iotService.showConfig(deviceRow.thingName)
                        "
                      >
                        <img
                          [title]="'iotModalViewConfig' | translate"
                          class="iconParam"
                          src="./assets/settings.png"
                          alt="icon of param"
                          height="20"
                          width="20"
                          style="color: gray"
                        />
                      </a>
                      <!-- Show modal with device notes -->
                      <a
                        href="javascript:void(0)"
                        (click)="
                          this.showDeviceNotes(deviceRow.thingName);
                          this.modal.showNotes(deviceRow.thingName)
                        "
                      >
                        <i class="fa-solid fa-file-pen note-icon"></i>
                      </a>
                      <!-- Show modal with device shadow -->
                      <a
                        href="javascript:void(0)"
                        (click)="
                          this.modal.showShadow(
                            this.iotService.getDeviceShadow(
                              deviceRow.thingName
                            ),
                            deviceRow.thingName
                          )
                        "
                      >
                        <img
                          [title]="'shadow' | translate"
                          class="iconParam"
                          src="./assets/json-icon.png"
                          alt="icon of json"
                          height="20"
                          width="20"
                          style="color: gray"
                        />
                      </a>
                      <!-- Download a certificate for the device -->
                      <a
                        href="javascript:void(0)"
                        (click)="
                          this.getCertificateFolderUrl(deviceRow.thingName)
                        "
                      >
                        <img
                          [title]="'generateCertificate' | translate"
                          class="iconCert"
                          src="./assets/grey-certificate-48.png"
                          alt="icon of certificate"
                          height="25"
                          width="25"
                          style="color: gray"
                        />
                      </a>
                      <!-- Show a modal for device association -->
                      <a
                        href="javascript:void(0)"
                        (click)="
                          this.showRelation(deviceRow.thingName)
                        "
                      >
                        <img
                          [title]="'deviceRelationship' | translate"
                          class="iconCert"
                          src="./assets/relationship.png"
                          alt="icon of relationship"
                          height="25"
                          width="25"
                          style="color: gray"
                        />
                      </a>
                      <!-- Send user to device statistic page -->
                      <a [routerLink]="['/', 'device-statistics', deviceRow.thingName]" (click)="storeFilters()">
                        <img
                          [title]="'showReports' | translate"
                          class="iconStat"
                          src="./assets/bar-chart-48.png"
                          alt="icon of a chart"
                          height="25"
                          width="25"
                          style="color: gray"
                        />
                      </a>
                      <!-- Show modal delete device -->
                      <a
                        href="javascript:void(0)"
                        (click)="
                          this.modal.showDeviceModal = true;
                          this.iotService.ShowDeleteThingModal(
                            deviceRow.thingName
                          )
                        "
                      >
                        <i class="fa-solid fa-trash delete-device"></i>
                      </a>
                      <!-- Show modal to reset device and his data to initial values -->
                      <a
                        href="javascript:void(0)"
                        (click)="initializeDeviceForProduction(deviceRow.thingName)"
                      >
                        <i [title]="'initializeDeviceIcon' | translate" class="fa-solid fa-arrows-rotate refresh-device"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </article>
    </section>
  </div>

  <!-- Section with deferent modal used in iotComponent.html -->
  <app-device-modal-config
    *ngIf="this.iotService.showDeviceConfigModal"
    class="appDeviceConfigModal"
  ></app-device-modal-config>
  <app-device-modal
    *ngIf="this.modal.showDeviceModal"
    class="appDeviceModal"
  ></app-device-modal>
  <app-device-modal-graphs
  *ngIf="this.iotService.showDeviceTechnicalGraphs"
  class="appDeviceTechnicalGraphs"
  ></app-device-modal-graphs>


  <!-- DEVICES VIEW @ MOBILE -->
  <div class="show-small-screen hide-big-screen width100">
    <!-- mapMobile -->
    <div
      [hidden]="hideMap"
      id="mapMobileContainer"
      class="show-small-screen columMap"
    >
      <article [ngClass]="theme.getThemeClass()" class="mapHeaderMobile">
        <div>
          <p id="lastExpandedMobile">{{ lastExpanded }}</p>
          <p id="lastExpandedAddressMobiles">{{ address | translate }}</p>
        </div>

        <p
          (click)="closeMap()"
          id="closeMapMobile"
          title="{{ 'iotModalButtonClose' | translate }}"
        >
          <i class="fa fa-times" aria-hidden="true"></i>
        </p>
      </article>
      <div
        #mapContainer
        id="mapMobile"
        class="mapMobile"
      ></div>
    </div>
    <section
      [ngClass]="theme.getThemeClass()"
      class="table-container"
      *ngIf="mainContent === 'devices'"
    >
      <article class="table-responsive">
        <!-- Section for list filter -->
        <div
          colspan="5"
          id="search-component"
          class="input-group searchDiv"
          *ngIf="mainContent === 'devices'"
        >
          <input
            #searchBoxMobile
            type="search"
            id="search-box-mobile"
            [(ngModel)]="deviceFilter"
            (input)="this.search()"
            class="form-control rounded searchInput"
            placeholder="{{ 'iotSearchPlaceHolderFilter' | translate }}"
          />
        </div>
        <!-- Header of the table with create device button -->
        <nav
          [ngClass]="theme.getThemeClass()"
          class="table-header"
          *ngIf="mainContent === 'devices'"
        >
          <span class="left"
            >{{ "userlistTableHeaderDeviceList" | translate }} ({{
              iotService.devicesArray.length
            }})
            <a (click)="refreshList()"><i class="scale-0-8 fas fa-sync"></i></a>
          </span>
          <span class="right scale-0-9">
            <button
              [ngClass]="theme.getThemeClass()"
              class="btn btn-dropdown"
              id="buttonDropdown"
              routerLink="/device-create"
            >
              {{ "newIot" | translate }}
            </button>
          </span>
        </nav>
        <!-- Device table in mobile -->
        <table class="table table-hover">
          <thead>
            <tr>
              <!-- Device column -->
              <th [ngClass]="theme.getThemeClass()" scope="col">
                <a
                  [ngClass]="theme.getThemeClass()"
                  (click)="this.sortItems('thingName')"
                  href="javascript:void(0)"
                >
                  {{ "iotTabHeaderDevice" | translate }} </a
                >
                <!-- IMG arrow up/down depend on sort -->
                <img
                  *ngIf="
                    this.sortDirection === 'asc' && this.sortBy === 'thingName'
                  "
                  src="./assets/arrow-up.png"
                  width="10px"
                  height="10px"
                /><img
                  *ngIf="
                    this.sortDirection === 'desc' && this.sortBy === 'thingName'
                  "
                  src="./assets/arrow-down.png"
                  width="10px"
                  height="10px"
                />
              </th>
              <!-- Bin status -->
              <th [ngClass]="theme.getThemeClass()" scope="col">
                {{ "iotTabHeaderStatus" | translate }}
              </th>
              <!-- Ressources -->
              <th [ngClass]="theme.getThemeClass()" scope="col">
                <i class="fas fa-search-location iconSearchMobile"></i>
              </th>
            </tr>
          </thead>
          <!-- table populated with data from array of devices -->
          <ng-container
            *ngFor="
              let deviceRow of iotService.devicesArray.slice(
                0,
                loadedItems + itemsPerPage
              );
              let i = index
            "
          >
            <tbody>
              <tr class="rowTableDataMobile">
                <!-- Device name. <td>click event to open device details on mobile view -->
                <td
                  [ngClass]="theme.getThemeClass()"
                  (click)="toggleDeviceDetails(deviceRow.thingName)"
                >
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#myModal"
                    href="javascript:void(0)"
                    style="display: block"
                    (click)="
                      this.modal.getStatus(verifyStatus(deviceRow.thingName))
                    "
                    >{{ deviceRow.thingName }}</a
                  >
                  <img
                    class="deviceInfoToggler {{ deviceRow.thingName }}"
                    src="./assets/arrow-up.png"
                    width="10px"
                    height="10px"
                  />
                </td>
                <!-- Bin status -->
                <td [ngClass]="theme.getThemeClass()">
                  <div
                    [ngClass]="{
                      healthy: verifyStatus(deviceRow.thingName) === 'Healthy',
                      unhealthy:
                        verifyStatus(deviceRow.thingName) === 'Unhealthy',
                      inactive: verifyStatus(deviceRow.thingName) === 'Inactive'
                    }"
                  >
                    {{ verifyStatus(deviceRow.thingName) | translate }}
                  </div>
                </td>
                <!-- Ressources -->
                <td [ngClass]="theme.getThemeClass()">
                  <!-- Show map -->
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#myModal"
                    href="javascript:void(0)"
                    (click)="showMap(deviceRow)"
                  >
                    <img
                      class="iconMap"
                      src="./assets/mappoint.png"
                      alt="icon of a map"
                      height="20"
                      width="20"
                    />
                  </a>
                  <!-- Show device modal config -->
                  <a
                    href="javascript:void(0)"
                    (click)="this.iotService.showConfig(deviceRow.thingName)"
                  >
                    <img
                      class="iconParam"
                      src="./assets/settings.png"
                      alt="icon of param"
                      height="20"
                      width="20"
                      style="color: gray"
                    />
                  </a>
                  <!-- Show modal with device notes -->
                  <a
                    href="javascript:void(0)"
                    (click)="
                      this.showDeviceNotes(deviceRow.thingName);
                      this.modal.showNotes(deviceRow.thingName)
                    "
                  >
                    <i class="fa-solid fa-file-pen note-icon"></i>
                  </a>
                  <!-- Show device shadow modal -->
                  <a
                    href="javascript:void(0)"
                    (click)="
                      this.modal.showShadow(
                        this.iotService.getDeviceShadow(deviceRow.thingName),
                        deviceRow.thingName
                      )
                    "
                  >
                    <img
                      class="iconParam"
                      src="./assets/json-icon.png"
                      alt="icon of json"
                      height="20"
                      width="20"
                      style="color: gray"
                    />
                  </a>
                  <!-- Download certificate -->
                  <a
                    href="javascript:void(0)"
                    (click)="this.getCertificateFolderUrl(deviceRow.thingName)"
                  >
                    <img
                      [title]="'generateCertificate' | translate"
                      class="iconCert"
                      src="./assets/grey-certificate-48.png"
                      alt="icon of certificate"
                      height="25"
                      width="25"
                      style="color: gray"
                    />
                  </a>
                  <!-- Show device association -->
                  <a
                    href="javascript:void(0)"
                    (click)="
                      this.showRelation(deviceRow.thingName)
                    "
                  >
                    <img
                      [title]="'deviceRelationship' | translate"
                      class="iconCert"
                      src="./assets/relationship.png"
                      alt="icon of relationship"
                      height="25"
                      width="25"
                      style="color: gray"
                    />
                  </a>
                  <!-- Send user to device statistic page -->
                  <a [routerLink]="['/', 'device-statistics', deviceRow.thingName]">
                    <img
                      [title]="'showReports' | translate"
                      class="iconStat"
                      src="./assets/bar-chart-48.png"
                      alt="icon of a chart"
                      height="25"
                      width="25"
                      style="color: gray"
                    />
                  </a>
                  <!-- Show device delete modal -->
                  <a
                    href="javascript:void(0)"
                    (click)="
                      this.modal.showDeviceModal = true;
                      this.iotService.ShowDeleteThingModal(deviceRow.thingName)
                    "
                  >
                    <i class="fa-solid fa-trash delete-device"></i>
                  </a>
                  <!-- Show modal to reset device and his data to initial values -->
                  <a
                    href="javascript:void(0)"
                    (click)="initializeDeviceForProduction(deviceRow.thingName)"
                  >
                    <i [title]="'initializeDeviceIcon' | translate" class="fa-solid fa-arrows-rotate refresh-device"></i>
                  </a>
                </td>
              </tr>
            </tbody>
            <!-- Section that is displayed when user click on <td>thing name to display device informations on mobile view -->
            <div
              [ngClass]="theme.getThemeClass()"
              class="deviceInfo hidden width100"
              id="{{ deviceRow.thingName }}"
            >
              <h4 [ngClass]="theme.getThemeClass()" name="deviceName">
                {{ deviceRow.thingName }}
              </h4>
              <div
                style="
                  display: flex;
                  flex-flow: row wrap;
                  justify-content: center;
                  gap: 0;
                "
                class="width100"
              >
                <!-- Power input of device -->
                <div [ngClass]="theme.getThemeClass()">
                  <label for="vcc">{{ "VCC" | translate }}</label>
                  <p name="vcc" *ngIf="deviceRow.dat.vcc; else dataUnavailable">
                    {{ deviceRow.dat.vcc }}
                  </p>
                </div>
                <!-- Battery -->
                <div [ngClass]="theme.getThemeClass()">
                  <label for="battery">{{
                    "iotModalHeaderBattery" | translate
                  }}</label>
                  <p
                    name="battery"
                    *ngIf="deviceRow.dat.bat; else dataUnavailable"
                  >
                    {{ deviceRow.dat.bat }}V
                  </p>
                </div>
                <!-- Device version -->
                <div [ngClass]="theme.getThemeClass()">
                  <label for="version">{{ "version" | translate }}</label>
                  <p
                    name="vesion"
                    *ngIf="deviceRow.dat.fdv; else dataUnavailable"
                  >
                    {{ deviceRow.dat.fdv }}
                  </p>
                </div>
                <!-- Device location -->
                <div [ngClass]="theme.getThemeClass()">
                  <label for="location">
                    {{ "binListViewLocation" | translate }}</label
                  >
                  <ng-container *ngIf="deviceRow.bin; else dataUnavailable">
                    <p
                      name="location"
                      *ngIf="
                        deviceRow?.bin?.bin_location &&
                        isString(deviceRow?.bin?.bin_location)
                      "
                    >
                      {{ deviceRow.bin.bin_location.split(",")[0] }}
                    </p>
                    <p
                      name="location"
                      *ngIf="
                        deviceRow?.bin?.bin_location &&
                        isString(deviceRow?.bin?.bin_location)
                      "
                    >
                      {{ deviceRow.bin.bin_location.split(",")[1] }}
                    </p>
                  </ng-container>
                </div>
                <!-- Signal power -->
                <div [ngClass]="theme.getThemeClass()">
                  <label for="signal">{{ "signal" | translate }}</label>
                  <p
                    name="signal"
                    *ngIf="deviceRow.dat.sig; else dataUnavailable"
                  >
                    {{ deviceRow.dat.sig }}
                  </p>
                </div>
                <!-- Local temperature of the device -->
                <div [ngClass]="theme.getThemeClass()">
                  <label for="temp ">{{ "temperature" | translate }}</label>
                  <p
                    name="temp"
                    *ngIf="deviceRow.dat.tm2; else dataUnavailable"
                  >
                    {{ deviceRow.dat.tm2 }}°C
                  </p>
                </div>
                <!-- Fill level of the bin -->
                <div [ngClass]="theme.getThemeClass()">
                  <label for="fill">{{ "filled" | translate }}</label>
                  <p
                    name="fill"
                    *ngIf="
                      deviceRow.bin?.bin_height && deviceRow.dat.dst;
                      else dataUnavailable
                    "
                  >
                    {{
                      calculateFillPercentage(
                        deviceRow.bin?.bin_height,
                        deviceRow.dat.dst
                      )
                    }}
                  </p>
                </div>
                <!-- Bin height -->
                <div [ngClass]="theme.getThemeClass()">
                  <label for="height"> {{ "height" | translate }}</label>
                  <p
                    name="height"
                    *ngIf="deviceRow.bin?.bin_height; else dataUnavailable"
                  >
                    {{ deviceRow.bin?.bin_height }}
                  </p>
                </div>
                <!-- Distance between device  and bottom of the bin -->
                <div [ngClass]="theme.getThemeClass()">
                  <label for="distance">{{
                    "deviceConfigModalDistance" | translate
                  }}</label>
                  <P
                    name="distance"
                    *ngIf="deviceRow.dat.dst; else dataUnavailable"
                    >{{ deviceRow.dat.dst }}</P
                  >
                </div>
              </div>
            </div>
            <ng-template #dataUnavailable>
              <p>(N/A)</p>
            </ng-template>
            <ng-template #nothing> </ng-template>
          </ng-container>
        </table>
        <div class="spacer"></div>
      </article>
    </section>
  </div>

  <!-- Technical VIEW -->
  <div
    id="rowContent"
    class="rowContentDevices"
    [ngClass]="theme.getThemeClass()"
    *ngIf="mainContent === 'technical'"
  >
    <section id="columList" class="hide-small-screen columList table-big-sreen">
      <article
        [ngClass]="theme.getThemeClass()"
        class="table-container"
        *ngIf="mainContent === 'technical'"
      >
        <div class="table-responsive">
          <!-- Section for filter and sort the list -->
          <nav
            colspan="5"
            id="search-component"
            class="input-group searchDiv"
            *ngIf="mainContent === 'technical'"
          >
            <input
              #searchBoxTechnical
              type="search"
              id="search-box-technical"
              [(ngModel)]="deviceFilter"
              (input)="this.search()"
              class="form-control rounded searchInput"
              placeholder="{{ 'iotSearchPlaceHolderFilter' | translate }}"
            />
            <div class="sort-by-group">
              <label class="sort-by-group-label">{{
                "deviceGroup" | translate
              }}</label>
              <ng-select
                [(ngModel)]="this.filterThingGroup"
                class="form-control sort-by-group-select"
                name="thingGroup"
                (change)="sortByGroupChange()"
              >
                <ng-option value="" selected>{{
                  "allGroups" | translate
                }}</ng-option>
                <ng-option value="dev" selected>DEV</ng-option>
                <ng-option value="poc" selected>POC</ng-option>
                <ng-option value="prd" selected>PRD</ng-option>
              </ng-select>
            </div>
          </nav>
          <!-- End of filter and sort section -->

          <!-- Table header and action button to create new device -->
          <article
            [ngClass]="theme.getThemeClass()"
            class="table-header"
            *ngIf="mainContent === 'technical'"
          >
            <span class="left"
              >{{ "userlistTableHeaderDeviceList" | translate }} ({{
                iotService.devicesArray.length
              }})
              <a (click)="refreshList()"
                ><i class="scale-0-8 fas fa-sync"></i
              ></a>
            </span>
            <span class="right">
              <button
                [ngClass]="theme.getThemeClass()"
                class="btn btn-dropdown"
                id="buttonDropdown"
                routerLink="/device-create"
              >
                {{ "newIot" | translate }}
              </button>
            </span>
          </article>
          <table class="table table-hover">
            <!-- Section for table column header -->
            <thead>
              <tr>
                <!-- Device name -->
                <th
                  [ngClass]="theme.getThemeClass()"
                  scope="col"
                  class="table-header-device"
                >
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('thingName')"
                    href="javascript:void(0)"
                  >
                    {{ "iotTabHeaderDevice" | translate }} </a
                  >
                  <!-- IMG aroow up/down on sorting the column -->
                  <img
                    *ngIf="
                      this.sortDirection === 'asc' &&
                      this.sortBy === 'thingName'
                    "
                    src="./assets/arrow-up.png"
                    width="10px"
                    height="10px"
                  /><img
                    *ngIf="
                      this.sortDirection === 'desc' &&
                      this.sortBy === 'thingName'
                    "
                    src="./assets/arrow-down.png"
                    width="10px"
                    height="10px"
                  />
                </th>
                <!-- Device version -->
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('version')"
                    href="javascript:void(0)"
                  >
                    {{ "version" | translate }}
                  </a>
                  <!-- IMG arrow up/down on sorting the column -->
                  <img
                    *ngIf="
                      this.sortDirection === 'asc' && this.sortBy === 'version'
                    "
                    src="./assets/arrow-up.png"
                    width="10px"
                    height="10px"
                  /><img
                    *ngIf="
                      this.sortDirection === 'desc' && this.sortBy === 'version'
                    "
                    src="./assets/arrow-down.png"
                    width="10px"
                    height="10px"
                  />
                </th>
                <!-- Distance between device and bottom of the bin -->
                <th [ngClass]="theme.getThemeClass()">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('distance')"
                    href="javascript:void(0)"
                  >
                    {{ "deviceConfigModalDistance" | translate }}
                  </a>
                  <!-- IMG arrow up/down on sorting the column -->
                  <img
                    *ngIf="
                      this.sortDirection === 'asc' && this.sortBy === 'distance'
                    "
                    src="./assets/arrow-up.png"
                    width="10px"
                    height="10px"
                  /><img
                    *ngIf="
                      this.sortDirection === 'desc' &&
                      this.sortBy === 'distance'
                    "
                    src="./assets/arrow-down.png"
                    width="10px"
                    height="10px"
                  />
                </th>
                <!-- Local temperature of the device -->
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('temperature')"
                    href="javascript:void(0)"
                  >
                    {{ "temperature" | translate }}
                  </a>
                  <!-- IMG arrow up/down on sorting the column -->
                  <img
                    *ngIf="
                      this.sortDirection === 'asc' && this.sortBy === 'signal'
                    "
                    src="./assets/arrow-up.png"
                    width="10px"
                    height="10px"
                  /><img
                    *ngIf="
                      this.sortDirection === 'desc' && this.sortBy === 'signal'
                    "
                    src="./assets/arrow-down.png"
                    width="10px"
                    height="10px"
                  />
                </th>
                <!-- Batterie voltage -->
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    href="javascript:void(0)"
                  >
                    VB1
                  </a>
                </th>
                <!-- Battery -->
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('battery')"
                    href="javascript:void(0)"
                  >
                    {{ "iotModalHeaderBattery" | translate }}
                  </a>
                  <!-- IMG arrow up/down on sorting the column -->
                  <img
                    *ngIf="
                      this.sortDirection === 'asc' && this.sortBy === 'battery'
                    "
                    src="./assets/arrow-up.png"
                    width="10px"
                    height="10px"
                  /><img
                    *ngIf="
                      this.sortDirection === 'desc' && this.sortBy === 'battery'
                    "
                    src="./assets/arrow-down.png"
                    width="10px"
                    height="10px"
                  />
                </th>
                <!-- Voltage to the input of the device -->
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('vcc')"
                    href="javascript:void(0)"
                  >
                    <!-- heremofo -->
                    {{ "VCC" | translate }}
                  </a>
                  <!-- IMG arrow up/down on sorting the column -->
                  <img
                    *ngIf="
                      this.sortDirection === 'asc' && this.sortBy === 'vcc'
                    "
                    src="./assets/arrow-up.png"
                    width="10px"
                    height="10px"
                  /><img
                    *ngIf="
                      this.sortDirection === 'desc' && this.sortBy === 'vcc'
                    "
                    src="./assets/arrow-down.png"
                    width="10px"
                    height="10px"
                  />
                </th>
                <!-- SIM operator -->
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('signal')"
                    href="javascript:void(0)"
                  >
                    {{"oper" | translate}}
                  </a>
                </th>
                <!-- Signal power -->
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('signal')"
                    href="javascript:void(0)"
                  >
                    {{ "signal" | translate }}
                  </a>
                </th>
                <!-- Network communication time -->
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('nct')"
                    href="javascript:void(0)"
                  >
                    NCT
                  </a>
                </th>

                <!-- Production status header -->
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('prodStatus')"
                    href="javascript:void(0)"

                  >
                    {{"deviceProdStatus" | translate}}
                  </a>
                </th>

                <!-- State Header -->
                <th [ngClass]="theme.getThemeClass()">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('date')"
                    href="javascript:void(0)"
                  >
                    {{ "state" | translate }}
                  </a>
                  <!-- IMG arrow up/down on sorting the column -->
                  <img
                    *ngIf="
                      this.sortDirection === 'asc' && this.sortBy === 'date'
                    "
                    src="./assets/arrow-up.png"
                    width="10px"
                    height="10px"
                  /><img
                    *ngIf="
                      this.sortDirection === 'desc' && this.sortBy === 'date'
                    "
                    src="./assets/arrow-down.png"
                    width="10px"
                    height="10px"
                  />
                </th>
                <!-- Device last communication -->
                <th
                  [ngClass]="theme.getThemeClass()"
                  class="hide-small-screen table-header-last-activity"
                  scope="col"
                >
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('date')"
                    href="javascript:void(0)"
                  >
                    {{ "iotTabHeaderLastActivity" | translate }} </a
                  >
                  <!-- IMG arrow up/down on sorting the column -->
                  <img
                    *ngIf="
                      this.sortDirection === 'asc' && this.sortBy === 'date'
                    "
                    src="./assets/arrow-up.png"
                    width="10px"
                    height="10px"
                  /><img
                    *ngIf="
                      this.sortDirection === 'desc' && this.sortBy === 'date'
                    "
                    src="./assets/arrow-down.png"
                    width="10px"
                    height="10px"
                  />
                </th>
                <!-- Ressources -->
                <th
                  [ngClass]="theme.getThemeClass()"
                  class="hide-small-screen table-header-ressources"
                  scope="col"
                >
                  {{ "iotTabHeaderResources" | translate }}
                </th>
              </tr>
            </thead>

            <tbody>
              <!-- table populated with data from array of devices -->
              <ng-container
                *ngFor="
                  let deviceRow of iotService.devicesArray.slice(
                    0,
                    loadedItems + itemsPerPage
                  );
                  let i = index
                "
              > 
                <tr #minhadiv class="rowTableData">
                  <!-- Device name -->
                  <td [ngClass]="theme.getThemeClass()">
                    {{ deviceRow.thingName }}
                  </td>
                  <!-- Device version -->
                  <td [ngClass]="theme.getThemeClass()">
                    {{ deviceRow.dat.fdv }}
                  </td>
                  <!-- Distance between device and bottom of the bin -->
                  <td [ngClass]="theme.getThemeClass()">
                    {{ deviceRow.dat.dst }}
                  </td>
                  <!-- Local temperature of the device -->
                  <td [ngClass]="theme.getThemeClass()" (click)="this.iotService.showTechnicalGraphs(deviceRow.thingName)">
                    {{ deviceRow.dat.tm2 }}°C
                  </td>
                  <!-- Batteriy voltage -->
                  <td [ngClass]="theme.getThemeClass()" (click)="this.iotService.showTechnicalGraphs(deviceRow.thingName)">
                    {{ iotService.validateVb1(deviceRow.dat.vb1 ?? 0) }}
                  </td>
                  <!-- Battery -->
                  <td [ngClass]="theme.getThemeClass()" (click)="this.iotService.showTechnicalGraphs(deviceRow.thingName)">
                    {{ deviceRow.dat.bat }}V
                  </td>
                  <!-- Voltage to the input of the device -->
                  <td [ngClass]="theme.getThemeClass()" (click)="this.iotService.showTechnicalGraphs(deviceRow.thingName)">
                    {{ deviceRow.dat.vcc }}
                  </td>
                  <!-- SIM operator -->
                  <td [ngClass]="theme.getThemeClass()">
                    {{ simOperator(deviceRow.car.oper) }}
                  </td>
                  <!-- Signal power -->
                  <td [ngClass]="theme.getThemeClass()">
                    {{ deviceRow.dat.sig }}
                  </td>
                  <!-- Network communication time -->
                  <td [ngClass]="theme.getThemeClass()">
                    {{ deviceRow.dat.nct }}
                  </td>

                  <!-- Production status -->
                  <td [ngClass]="theme.getThemeClass()">                 
                    {{
                      this.iotService.ProductionStatus[deviceRow.prodStatus!.status_index].value | translate
                    }}
                    <a>
                      <img 
                        src="..\assets\update-pencil.png" 
                        height="20px;"
                        width= "20px;"
                        color="gray"
                        (click)="this.modal.showProdStatus(deviceRow.thingName, deviceRow.prodStatus?.status_index)"
                      />
                    </a>
                  </td>
                  
                  <!-- Device status -->
                  <td [ngClass]="theme.getThemeClass()" class="light-td"
                    (mouseover)="showStateInfo[i] = true"
                    (mouseleave)="showStateInfo[i] = false">
                    <div
                      class="trafic-light-box"
                    >
                      <!-- Traffic light for UI -->
                      <div class="traffic-light">
                        <div
                          class="light"
                          [ngClass]="{
                            'light-green': deviceRow.heartbeat && deviceRow.heartbeat.color === 'green',
                            'light-yellow': deviceRow.heartbeat && deviceRow.heartbeat.color === 'yellow',
                            'light-red': deviceRow.heartbeat && deviceRow.heartbeat.color === 'red'
                        }"
                        ></div>
                      </div>
                      <!-- Info bubble on mouse over show beat miss, when device supose to communicate next time and his sleep time configuration -->
                    <div
                    class="info-state-box"
                    [ngClass]="theme.getThemeClass()"
                    *ngIf="showStateInfo[i]"
                    >
                      <div class="state-info-layer">
                        <label class="state-title">
                          {{ "beatMiss" | translate }} :
                        </label>
                        <label class="state-info">
                          <span
                            *ngIf="this.iotService.isBeatMissMoreThenOneHundred(deviceRow.heartbeat?.beat_miss)"
                            >+ </span
                          >{{ deviceRow.heartbeat?.beat_miss || 0 }}
                        </label>
                      </div>
                      <div>
                        <label class="state-title">
                          {{ "suposeToCommunicate" | translate }} :
                        </label>
                        <label class="state-info">
                          {{
                            deviceRow.heartbeat?.supose_to_communicate
                          }}
                        </label>
                      </div>
                      <div>
                        <label class="state-title">
                          {{ "sleepTimeConfig" | translate }} :
                        </label>
                        <label class="state-info">
                          {{
                            this.iotService.convertSecondToHour(
                              deviceRow.config?.slp
                            ) | translate
                          }}
                        </label>
                      </div>
                    </div>
                    </div>


                  </td>
                  <!-- Device last communication -->
                  <td
                    class="hide-small-screen"
                    [ngClass]="theme.getThemeClass()"
                  >
                    <a
                      class="link-shadow-feed"
                      (click)="goToShadowFeed(deviceRow.thingName)"
                    >
                      {{ deviceRow.date }}
                    </a>
                  </td>
                  <!-- Ressources -->
                  <td [ngClass]="theme.getThemeClass()">
                    <div class="divButton">
                      <!-- Show device configs modal -->
                      <a
                        href="javascript:void(0)"
                        (click)="
                          this.iotService.showConfig(deviceRow.thingName)
                        "
                      >
                        <img
                          [title]="'iotModalViewConfig' | translate"
                          class="iconParam"
                          src="./assets/settings.png"
                          alt="icon of param"
                          height="20"
                          width="20"
                          style="color: gray"
                        />
                      </a>
                      <!-- Show device notes modal -->
                      <a
                        href="javascript:void(0)"
                        (click)="
                          this.showDeviceNotes(deviceRow.thingName);
                          this.modal.showNotes(deviceRow.thingName)
                        "
                      >
                        <i class="fa-solid fa-file-pen note-icon"></i>
                      </a>
                      <!-- Show device shadow modal -->
                      <a
                        href="javascript:void(0)"
                        (click)="
                          this.modal.showShadow(
                            this.iotService.getDeviceShadow(
                              deviceRow.thingName
                            ),
                            deviceRow.thingName
                          )
                        "
                      >
                        <img
                          [title]="'shadow' | translate"
                          class="iconParam"
                          src="./assets/json-icon.png"
                          alt="icon of json"
                          height="20"
                          width="20"
                          style="color: gray"
                        />
                      </a>
                      <!-- Download device certificate -->
                      <a
                        href="javascript:void(0)"
                        (click)="
                          this.getCertificateFolderUrl(deviceRow.thingName)
                        "
                      >
                        <img
                          [title]="'generateCertificate' | translate"
                          class="iconCert"
                          src="./assets/grey-certificate-48.png"
                          alt="icon of certificate"
                          height="25"
                          width="25"
                          style="color: gray"
                        />
                      </a>
                      <!-- Show device association modal -->
                      <a
                        href="javascript:void(0)"
                        (click)="
                          this.showRelation(deviceRow.thingName)
                        "
                      >
                        <img
                          [title]="'deviceRelationship' | translate"
                          class="iconCert"
                          src="./assets/relationship.png"
                          alt="icon of relationship"
                          height="25"
                          width="25"
                          style="color: gray"
                        />
                      </a>
                      <!-- Send user to device statistic page -->
                      <a (click)="setNavToDeviceStat(deviceRow.thingName)"
                      >
                        <img
                          [title]="'showReports' | translate"
                          class="iconStat"
                          src="./assets/bar-chart-48.png"
                          alt="icon of a chart"
                          height="25"
                          width="25"
                          style="color: gray"
                        />
                      </a>
                      <!-- Send user to graphs page -->
                      <a
                        href="javascript:void(0)"
                        (click)="
                          this.iotService.showTechnicalGraphs(deviceRow.thingName);
                        "
                        [ngClass]="this.iotService.showDeviceTechnicalGraphs ? 'disabled' : ' ' "
                      >
                      <i class="fa-solid fa-chart-line"></i>
                      </a>
                      <!-- Show delete device modal -->
                      <a
                        href="javascript:void(0)"
                        (click)="
                          this.modal.showDeviceModal = true;
                          this.iotService.ShowDeleteThingModal(
                            deviceRow.thingName
                          )
                        "
                      >
                        <i class="fa-solid fa-trash delete-device"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </article>
    </section>
  </div>

  <!-- Reports VIEW -->
  <div
    [ngClass]="theme.getThemeClass()"
    *ngIf="mainContent === 'reports'"
    class="divReportViewConfig"
  >
    <h4 class="filter-title">{{ "filters" | translate }}</h4>
    <nav class="filter-bar">
      <!-- Device Statistics Filters -->
      <div class="device-filter">
        <div class="form-group">
          <div class="force-column">
            <label class="form-label" id="deciveStats">{{
              "deviceStatistics" | translate
            }}</label>
            <!-- Select for different reports -->
            <ng-select
              disabled="true"
              [(ngModel)]="statistic"
              class="form-control ng-select"
            >
              <ng-option disabled value="Collections" selected>{{
                "collections" | translate
              }}</ng-option>
            </ng-select>
          </div>
        </div>

        <!-- Device Statistics Filters -->
        <!-- Device Date pickers -->
        <div class="device-filter">
          <div class="form-group">
            <div class="force-column">
              <label class="form-label">{{ "startingDate" | translate }}</label>
              <input [(ngModel)]="startDate" class="form-control" type="date" />
            </div>
          </div>

          <div class="form-group">
            <div class="force-column">
              <label class="form-label">{{ "endDate" | translate }}</label>
              <input [(ngModel)]="endDate" class="form-control" type="date" />
            </div>
          </div>
        </div>
        <!-- Switch section -->
        <div class="toggle-stack">
          <div class="toggle-box">
            <label class="devices-toggle">{{ "allDevices" | translate }}</label>
            <label class="markets-toggle">{{ "allMarkets" | translate }}</label>
          </div>

          <div class="filter-toggle">
            <!-- Switch for all things -->
            <div class="custom-control custom-switch cs-container">
              <!-- Rounded switch -->
              <label class="switch">
                <input
                  type="checkbox"
                  [ngModel]="allThings"
                  name="devices"
                  (change)="onChangeAllThings($event)"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <!-- Switch for all market segment -->
            <div class="custom-control custom-switch cs-container">
              <!-- Rounded switch -->
              <label class="switch">
                <input
                  type="checkbox"
                  [ngModel]="allMarkets"
                  name="markets"
                  (change)="onChangeAllMarkets($event)"
                  [disabled]="true"
                /> <!-- Remove disable at true to be able to use this switch -->
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <!-- Select section to get device one by one if all things is not selected -->
      <div *ngIf="!allThings" class="form-group">
        <div class="force-column">
          <label class="form-label">{{ "devices" | translate }}</label>
          <ng-select
            [(ngModel)]="selectedThings"
            class="form-control ng-select"
            [multiple]="true"
          >
            <ng-container *ngFor="let option of this.things">
              <ng-option value="{{ option.id }}"> {{ option.name }}</ng-option>
            </ng-container>
          </ng-select>
        </div>
      </div>

      <!-- Select section to get market segment one by one -->
      <!-- Select doesn't work yet, remove disable=true on switch for all market segment -->
      <div *ngIf="!allMarkets" class="form-group">
        <div class="force-column">
          <label class="form-label">{{ "marketSegments" | translate }}</label>
          <ng-select
            disabled="true"
            [(ngModel)]="selectedMarkets"
            [multiple]="true"
            class="form-control ng-select"
          >
            <ng-option disabled value="All" selected>{{
              "allMarkets" | translate
            }}</ng-option>
          </ng-select>
        </div>
      </div>
    </nav>
    <!-- action button segment -->
    <div class="btn-holder col-3">
      <!-- Button print PDF -->
      <button
        [ngClass]="theme.getThemeClass()"
        class="form-control btn btn-primary btn-bloc btn-second"
        (click)="compileReport()"
        [disabled]="pdf.generatingPDF"
      >
        <span *ngIf="!pdf.generatingPDF">
          {{ "printPDF" | translate }}
        </span>
        <div
          class="spinner-border spinner-position spinner-button"
          role="status"
          *ngIf="pdf.generatingPDF"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
      <!-- Button import CSV -->
      <button
        [ngClass]="theme.getThemeClass()"
        class="form-control btn btn-primary btn-bloc btn-second"
        (click)="exportCSVReports()"
        [disabled]="generatingCSV"
      >
        <span *ngIf="!generatingCSV">
          {{ "exportCSV" | translate }}
        </span>
        <div
          class="spinner-border spinner-position spinner-button"
          role="status"
          *ngIf="generatingCSV"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
    </div>
  </div>

  <!-- Graph Canvas for PDF Report -->
  <div id="canvas-holder" style="width: 300px; overflow-y: auto; height: 0px">
    <canvas #tonnageCanvas></canvas>
    <canvas #usageCanvas></canvas>
    <canvas #marketCanvas></canvas>
    <canvas #countCanvas></canvas>
  </div>

  <!-- SHADOW FEED VIEW -->
  <div
    [ngClass]="theme.getThemeClass()"
    *ngIf="mainContent === 'feed'"
    class="divShadowFeed"
  >
    <!-- Section for all filters -->
    <nav class="shadow-feed-header-nav">
      <div class="shadow-feed-header-div">
        <div class="device-filter">
          <!-- Date picker for start date -->
          <div class="form-group">
            <div class="force-column">
              <label class="form-label">{{ "startingDate" | translate }}</label>
              <input
                [(ngModel)]="startDateShadowFeed"
                class="form-control"
                type="date"
              />
            </div>
          </div>
          <!-- Date picker for end date -->
          <div class="form-group">
            <div class="force-column">
              <label class="form-label">{{ "endDate" | translate }}</label>
              <input
                [(ngModel)]="endDateShadowFeed"
                class="form-control"
                type="date"
              />
            </div>
          </div>
        </div>
        <div class="toggle-filter-box">
          <!-- Rounded switch to select all device -->
          <div class="toggle-stack-shadow-feed">
            <div class="filter-toggle">
              <!-- Rounded switch -->
              <label class="switch">
                <input
                  type="checkbox"
                  [ngModel]="allThingsShadowFeed"
                  name="devices"
                  (change)="onChangeAllThingsShadowFeed($event)"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="toggle-box-shadow-feed">
              <label class="devices-toggle">{{
                "allDevices" | translate
              }}</label>
            </div>
          </div>
          <!-- Button to made the research -->
          <button
            class="btn btn-search-shadow-feed"
            (click)="this.getShadowFeed()"
          >
            {{ "search" | translate }}
          </button>
        </div>
      </div>
      <!-- Section displayed if all things switch is not active -->
      <div *ngIf="!allThingsShadowFeed" class="form-group">
        <div class="force-column">
          <div class="label-search-box-shadow-feed">
            <label class="form-label">{{ "devices" | translate }}</label>
            <div class="search-box-shadow-feed">
              <a
                class="clear-search-box-shadow-feed"
                (click)="this.thingsShadowFeed.clearModel()"
              >
                {{ "clear" | translate }}
              </a>
            </div>
          </div>
          <!-- To select device one by one -->
          <ng-select
            [(ngModel)]="selectedThingsShadowFeed"
            class="form-control ng-select"
            [multiple]="true"
            #thingsShadowFeed
          >
            <ng-container *ngFor="let option of this.things">
              <ng-option value="{{ option.id }}"> {{ option.name }}</ng-option>
            </ng-container>
          </ng-select>
        </div>
      </div>
    </nav>
    <!-- Table section for results -->
    <section class="shadow-feed-table-section-desktop">
      <div class="horizontal-scroll-container">
        <table class="table table-hover table-shadow-feed">
          <thead>
            <tr>
              <!-- Last activity -->
              <th
                [ngClass]="theme.getThemeClass()"
                class="hide-small-screen table-header-last-activity"
                scope="col"
              >
                <a
                  [ngClass]="theme.getThemeClass()"
                  (click)="this.sortItemLog('date')"
                  href="javascript:void(0)"
                >
                  {{ "activity" | translate }}
                </a>
              </th>
              <!-- Futur development, we need to process the sleep time config for displaying it -->

              <!-- Device name -->
              <th
                [ngClass]="theme.getThemeClass()"
                scope="col"
                class="table-header-device"
              >
                <a
                  [ngClass]="theme.getThemeClass()"
                  (click)="this.sortItemLog('thing_name')"
                  href="javascript:void(0)"
                >
                  {{ "iotTabHeaderDevice" | translate }} </a
                >
                <!-- IMG arrow up/down showed on sort change -->
                <img
                  *ngIf="
                    this.sortDirection === 'asc' && this.sortBy === 'thingName'
                  "
                  src="./assets/arrow-up.png"
                  width="10px"
                  height="10px"
                /><img
                  *ngIf="
                    this.sortDirection === 'desc' && this.sortBy === 'thingName'
                  "
                  src="./assets/arrow-down.png"
                  width="10px"
                  height="10px"
                />
              </th>
              <!-- Local device temperature -->
              <th [ngClass]="theme.getThemeClass()" scope="col">
                <a
                  [ngClass]="theme.getThemeClass()"
                  (click)="this.sortItemLog('thing_name')"
                  href="javascript:void(0)"
                >
                  {{ "temperature" | translate }}
                </a>
              </th>
              <!-- Battery -->
              <th [ngClass]="theme.getThemeClass()" scope="col">
                <a
                  [ngClass]="theme.getThemeClass()"
                  (click)="this.sortItemLog('thing_name')"
                  href="javascript:void(0)"
                >
                  {{ "iotModalHeaderBattery" | translate }}
                </a>
              </th>
              <!-- Voltage input of device -->
              <th [ngClass]="theme.getThemeClass()" scope="col">
                <a
                  [ngClass]="theme.getThemeClass()"
                  (click)="this.sortItemLog('thing_name')"
                  href="javascript:void(0)"
                >
                  {{ "VCC" | translate }}
                </a>
              </th>
              <!-- Version of the devce -->
              <th [ngClass]="theme.getThemeClass()" scope="col">
                <a
                  [ngClass]="theme.getThemeClass()"
                  (click)="this.sortItemLog('thing_name')"
                  href="javascript:void(0)"
                >
                  {{ "version" | translate }}
                </a>
              </th>
              <!-- Signal power -->
              <th [ngClass]="theme.getThemeClass()" scope="col">
                <a
                  [ngClass]="theme.getThemeClass()"
                  (click)="this.sortItemLog('thing_name')"
                  href="javascript:void(0)"
                >
                  {{ "signal" | translate }}
                </a>
              </th>
              <!-- Network communication time -->
              <th [ngClass]="theme.getThemeClass()" scope="col">
                <a
                  [ngClass]="theme.getThemeClass()"
                  (click)="this.sortItemLog('thing_name')"
                  href="javascript:void(0)"
                >
                  NCT
                </a>
              </th>
              <!-- Distance between device and bottom of the bin -->
              <th [ngClass]="theme.getThemeClass()" scope="col">
                <a
                  [ngClass]="theme.getThemeClass()"
                  (click)="this.sortItemLog('thing_name')"
                  href="javascript:void(0)"
                >
                  {{ "deviceConfigModalDistance" | translate }}
                </a>
              </th>
            </tr>
          </thead>
          <!-- table populated with data from array of devices -->

          <tbody>
            <ng-container
              *ngFor="
                let deviceRow of thingsShadowLog.slice(
                  0,
                  loadedItemsShadowFeed + itemsPerPageShadowFeed
                );
                let i = index
              "
            >
              <tr #minhadiv class="rowTableData">
                <!-- Last activity -->
                <td class="hide-small-screen" [ngClass]="theme.getThemeClass()">
                  {{ deviceRow.timestamp }}
                </td>
                <!-- Device name -->
                <td [ngClass]="theme.getThemeClass()">
                  {{ deviceRow.thing_name }}
                </td>
                <!-- Local temperature of the device -->
                <td [ngClass]="theme.getThemeClass()">{{ deviceRow.tm2 }}°C</td>
                <!-- Battery -->
                <td [ngClass]="theme.getThemeClass()">{{ deviceRow.bat }}V</td>
                <!-- Voltage input of device -->
                <td [ngClass]="theme.getThemeClass()">
                  {{ deviceRow.vcc }}
                </td>
                <!-- Version of the device -->
                <td [ngClass]="theme.getThemeClass()">
                  {{ deviceRow.fdv }}
                </td>
                <!-- Signal power -->
                <td [ngClass]="theme.getThemeClass()">
                  {{ deviceRow.sig }}
                </td>
                <!-- Network communication time -->
                <td [ngClass]="theme.getThemeClass()">
                  {{ deviceRow.nct }}
                </td>
                <!-- Distance between device and bottom of the bin -->
                <td [ngClass]="theme.getThemeClass()">
                  {{ deviceRow.dst }}
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </section>
    <!--            For futur development    Mobile view for admin   -->
    <!-- Mobile view for shadow feed table -->
    <!-- <section class="shadow-feed-table-section-mobile">
    <table class="table table-hover ">
      <thead (click)="this.show = false">
        <tr>
          <th
            [ngClass]="theme.getThemeClass()"
            scope="col"
            class="table-header-device"
          >
            <a
              [ngClass]="theme.getThemeClass()"
              (click)="this.sortItems('thingName')"
              href="javascript:void(0)"
            >
              {{ "iotTabHeaderDevice" | translate }} </a
            ><img
              *ngIf="
                this.sortDirection === 'asc' &&
                this.sortBy === 'thingName'
              "
              src="./assets/arrow-up.png"
              width="10px"
              height="10px"
            /><img
              *ngIf="
                this.sortDirection === 'desc' &&
                this.sortBy === 'thingName'
              "
              src="./assets/arrow-down.png"
              width="10px"
              height="10px"
            />
          </th>
          <th
            [ngClass]="theme.getThemeClass()"
            class="table-header-last-activity"
            scope="col"
          >
            {{ "activity" | translate }}
          </th>
        </tr>
      </thead>

      <ng-container
        *ngFor="
          let deviceRow of thingsShadowLog.slice(
            0,
            loadedItems + itemsPerPage
          );
          let i = index
        "
      >
        <tbody>
          <tr #minhadiv class="rowTableData">
            <td [ngClass]="theme.getThemeClass()" (click)="toggleDeviceDetails(deviceRow.thingName)" >
              <a
                data-bs-toggle="modal"
                data-bs-target="#myModal"
                href="javascript:void(0)"
                style="display: block;"
                (click)="
                  this.modal.getStatus(verifyStatus(deviceRow.thingName))
                "
                >{{ deviceRow.thing_name }}</a
              >
              <img class="deviceInfoToggler {{deviceRow.thingName}}" src="./assets/arrow-up.png" width="10px" height="10px" >
            </td>
            <td
              [ngClass]="theme.getThemeClass()"
            >
              {{ deviceRow.timestamp }}
            </td>
          </tr>
        </tbody>
      </ng-container>
    </table>
  </section> -->
  </div>
</div>
