import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { IotComponent } from './iot/iot.component';
import { ConfigsComponent } from './configs/configs.component';
import { AdminMapComponent } from './admin-map/admin-map.component';
import { ClientCreateComponent } from './client-create/client-create.component';
import { ClientListComponent } from './client-list/client-list.component';
import { ClientEditComponent } from './client-edit/client-edit.component';
import { BinListComponent } from './bin-list/bin-list.component';
import { AdminComponent } from './admin/admin.component';
import { BinCreateComponent } from './bin-create/bin-create.component';
import { DeviceCreateComponent } from './device-create/device-create.component';
import { BinUpdateComponent } from './bin-update/bin-update.component';
import { BinModelCreateComponent } from './bin-model-create/bin-model-create.component';
import { BinModelUpdateComponent } from './bin-model-update/bin-model-update.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { EntitiesComponent } from './entities/entities.component';
import { TaxeTypeCreateComponent } from './taxe-type-create/taxe-type-create.component';
import { TaxeTypeUpdateComponent } from './taxe-type-update/taxe-type-update.component';
import { DistributorsCreateComponent } from './distributors-create/distributors-create.component';
import { DistributorsEditComponent } from './distributors-edit/distributors-edit.component';
import { UserCreateClientComponent } from './user-create-client/user-create-client.component';
import { UserCreateDistributorComponent } from './user-create-distributor/user-create-distributor.component';
import { UserCreateMuirwoodComponent } from './user-create-muirwood/user-create-muirwood.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { OperatorCreateComponent } from './operator-create/operator-create.component';
import { OperatorUpdateComponent } from './operator-update/operator-update.component';
import { UserUpdateClientComponent } from './user-update-client/user-update-client.component';
import { UserUpdateDistributorComponent } from './user-update-distributor/user-update-distributor.component';
import { UserUpdateMuirwoodComponent } from './user-update-muirwood/user-update-muirwood.component';
import { WorkOrderCreateComponent } from './work-order-create/work-order-create.component';

import { SystemMessageComponent } from './system-message/system-message.component';
import { WorkOrderListComponent } from './work-order-list/work-order-list.component';
import { UserCreateOperatorComponent } from './user-create-operator/user-create-operator.component';
import { UserUpdateOperatorComponent } from './user-update-operator/user-update-operator.component';


import { FederatedLoginComponent } from './federated-login/federated-login.component';
import { EntitiesLocationCreateComponent } from './entities-location-create/entities-location-create.component';
import { EntitiesLocationUpdateComponent } from './entities-location-update/entities-location-update.component';
import { LoadingElementComponent } from './loading-element/loading-element.component';
import { MapComponent } from './map/map.component';
import { DistributorBinsModelDashboardComponent } from './distributor-bins-model-dashboard/distributor-bins-model-dashboard.component';
import { DistributorAdminDashboardComponent } from './distributor-admin-dashboard/distributor-admin-dashboard.component';
import { DistributorIotDashboardComponent } from './distributor-iot-dashboard/distributor-iot-dashboard.component';
import { DeviceStatisticsComponent } from './device-statistics/device-statistics.component';
import { ClientThingListComponent } from './client-thing-list/client-thing-list.component';
import { BinDashboardComponent } from './bin-dashboard/bin-dashboard.component';
import { PdfGeneratorComponent } from './pdf-generator/pdf-generator.component';
import { ClientBinDashboardComponent } from './client-bin-dashboard/client-bin-dashboard.component';
import { ClientDashboardComponent } from './client-dashboard/client-dashboard.component';
import { DistributorWorkOrderDashboardComponent } from './distributor-work-order-dashboard/distributor-work-order-dashboard.component';
import { ClientUserListComponent } from './client-user-list/client-user-list.component';
import { ClientWorkOrderDashboardComponent } from './client-work-order-dashboard/client-work-order-dashboard.component';
import { ReportModalComponent } from './report-modal/report-modal.component';
import { UserEditPreferenceComponent } from './user-edit-preference/user-edit-preference.component';
import { RelationshipModalComponent } from './relationship-modal/relationship-modal.component';
import { TruckListComponent } from './truck-list/truck-list.component';
import { DriverActiveRouteComponent } from './driver-active-route/driver-active-route.component';
import { OperatorCreateTruckComponent } from './operator-create-truck/operator-create-truck.component';
import { OperatorUpdateTruckComponent } from './operator-update-truck/operator-update-truck.component';
import { JocelynTestComponent } from './jocelyn-test/jocelyn-test.component';
import { GGTestComponent } from './gg-test/gg-test.component';
import { OperatorMapComponent } from './operator-map/operator-map.component';
import { DriverRoutesComponent } from './driver-routes/driver-routes.component';
import { DriversComponent } from './drivers/drivers.component';
import { DispatcherControlCenterComponent } from './dispatcher-control-center/dispatcher-control-center.component';
import { AssignRouteFormModalComponent } from './assign-route-form-modal/assign-route-form-modal.component';
import { DispatchNetworkComponent } from './dispatch-network/dispatch-network.component';
import { DispatchWorkOrderComponent } from './dispatch-work-order/dispatch-work-order.component';
import { DispatchOverviewComponent } from './dispatch-overview/dispatch-overview.component';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full' },
  {path: 'login', component: LoginComponent},
  {path: 'login/:action', component: LoginComponent},
  {path: 'login/:action/:return', component: LoginComponent},
  {path: 'user-edit/:id', component: UserEditComponent},
  {path: 'user-edit', component: UserEditComponent},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'iot', component: IotComponent},
  {path: 'iot/:tab', component: IotComponent},
  {path: 'iot/:tab/:report', component: IotComponent},
  {path: 'iot/:tab/:report/:things', component: IotComponent},
  {path: 'iot/:tab/:report/:things/:start', component: IotComponent},
  {path: 'iot/:tab/:report/:things/:start/:end', component: IotComponent},
  {path: 'iot/:tab/:graphs/:things/:start/:end', component: IotComponent},
  {path: 'iot/:tab/:report/:things/:start/:end/:market', component: IotComponent},
  {path: 'iot/:tab/:report/:things/:start/:end/:market/:zones', component: IotComponent},
  {path: 'configs', component: ConfigsComponent},
  {path: 'zones', component: AdminMapComponent},
  {path: 'client-create', component: ClientCreateComponent},
  {path: 'client-edit', component: ClientEditComponent},
  {path: 'client-edit/:id', component: ClientEditComponent},
  {path: 'admin', component: AdminComponent},
  {path: 'bin-create', component: BinCreateComponent},
  {path: 'bin-update/:id', component: BinUpdateComponent},
  {path: 'bin-model-create', component: BinModelCreateComponent},
  {path: 'bin-model-update/:id', component: BinModelUpdateComponent},
  {path: 'device-create', component: DeviceCreateComponent},
  {path: 'entities', component: EntitiesComponent},
  {path: 'glenn', component: SystemMessageComponent},
  {path: 'distributors-create', component: DistributorsCreateComponent},
  {path: 'taxe-type-create', component: TaxeTypeCreateComponent},
  {path: 'taxe-type-update/:id', component: TaxeTypeUpdateComponent},
  {path: 'operator-create', component: OperatorCreateComponent},
  {path: 'operator-update/:id', component: OperatorUpdateComponent},
  {path: 'distributors-edit/:id', component: DistributorsEditComponent},
  {path: 'user-create-client', component: UserCreateClientComponent},
  {path: 'user-create-distributor', component: UserCreateDistributorComponent},
  {path: 'user-create-muirwood', component: UserCreateMuirwoodComponent},
  {path: 'user-update-client', component: UserUpdateClientComponent},
  {path: 'user-update-distributor', component: UserUpdateDistributorComponent},
  {path: 'user-update-muirwood', component: UserUpdateMuirwoodComponent},
  {path: 'work-order-create', component: WorkOrderCreateComponent},
  {path: 'work-order-create/:thing', component: WorkOrderCreateComponent},
  {path: 'work-order-create/:thing/:client', component: WorkOrderCreateComponent},
  {path: 'work-order-list', component: WorkOrderListComponent},
  {path: 'user-create-operator', component: UserCreateOperatorComponent},
  {path: 'user-update-operator', component: UserUpdateOperatorComponent},
  {path: 'loading', component: LoadingElementComponent},
  {path: 'distributor-bins-model-dashboard', component: DistributorBinsModelDashboardComponent},
  {path: 'entities-location-create', component: EntitiesLocationCreateComponent},
  {path: 'entities-location-update/:id', component: EntitiesLocationUpdateComponent},
  //{path: 'login', component: FederatedLoginComponent},
  {path: 'map', component: MapComponent},
  {path: 'distributor-admin-dashboard', component: DistributorAdminDashboardComponent},
  {path: 'distributor-iot-dashboard', component: DistributorIotDashboardComponent},
  {path: 'device-statistics', component: DeviceStatisticsComponent},
  {path: 'device-statistics/:thing', component: DeviceStatisticsComponent},
  {path: 'device-statistics/:thing/:start', component: DeviceStatisticsComponent},
  {path: 'device-statistics/:thing/:start/:end', component: DeviceStatisticsComponent},
  {path: 'device-statistics/:thing/:start/:end/:report', component: DeviceStatisticsComponent},
  {path: 'distributor-iot-dashboard', component: DistributorIotDashboardComponent},
  {path: 'client-thing-list', component: ClientThingListComponent},
  {path: 'bin-dashboard', component: BinDashboardComponent},
  {path: 'bin-dashboard/:id', component: BinDashboardComponent},
  {path: 'pdf', component: PdfGeneratorComponent},
  {path: 'client-bin-dashboard', component: ClientBinDashboardComponent},
  {path: 'client-dashboard', component: ClientDashboardComponent},
  {path: 'distributor-work-order-dashboard', component: DistributorWorkOrderDashboardComponent},
  {path: 'client-user-list', component: ClientUserListComponent},
  {path: 'client-work-order-dashboard', component: ClientWorkOrderDashboardComponent},
  {path: 'report-modal', component: ReportModalComponent},
  {path: 'user-edit-preference/:sub', component: UserEditPreferenceComponent},
  {path: 'user-edit-preference', component: UserEditPreferenceComponent},
  {path: 'truck-list', component: TruckListComponent},
  {path: 'my-routes', component: DriverActiveRouteComponent},
  {path: 'my-routes/:routeID', component: DriverActiveRouteComponent},
  {path: 'operator-create-truck', component: OperatorCreateTruckComponent},
  {path: 'operator-update-truck', component: OperatorUpdateTruckComponent},
  {path: 'operator-update-truck/:truckID', component: OperatorUpdateTruckComponent},
  {path: 'jocelyn-test', component: JocelynTestComponent},
  {path: 'gg-test', component: GGTestComponent},
  {path: 'dispatcher-control-center', component: DispatcherControlCenterComponent},
  {path: 'assign-route-form-modal', component: AssignRouteFormModalComponent},
  {path: 'operator-map', component: OperatorMapComponent},
  {path: 'driver-routes', component: DriverRoutesComponent},
  {path: 'drivers', component: DriversComponent},
  {path: 'dispatch-network', component: DispatchNetworkComponent},
  {path: 'dispatch-work-orders', component: DispatchWorkOrderComponent},
  {path: 'overview', component: DispatchOverviewComponent},
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { enableTracing: false, useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
