<app-system-message></app-system-message>
<!-- Container for the form with dynamic styling -->
<div [ngClass]="theme.getThemeClass()" class="container-fluido">
    <section [ngClass]="theme.getThemeClass()" class="card bg-light">
        <article class="card-body mx-auto article">
            <h4 class="card-title mt-0 text-center">{{ 'binModelUpdateViewBinModelUpdate' | translate }}</h4>
            <form>
                <!-- Submit button -->
                <nav class="form-group displayFlex">
                    <button [ngClass]="theme.getThemeClass()" type="submit" class="btn btn-block btnCancel">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                </nav>
            </form>
            <div>
              {{ this.response }}
            </div>
        </article>
      </section>
      <section>
        <button (click)="sendMessage()">Envoyer un message</button>

        <ul>
          <li *ngFor="let message of messages"><pre>{{ JSON.stringify(message, null, 2) }}</pre></li>
        </ul>
      </section>
</div>
<hr><hr><hr>
<div>
  <app-progress-tracker
    [taskGroups]="tasks"
  >
  </app-progress-tracker>
</div>
<hr><hr><hr>
<div>
  <br>
  {{ "test.nested.key" | translate }}
  <br>
  {{ "test.nested.even_more_nested.that_is_deep" | translate }}
  <br>
  {{ "test.less_nested" | translate }}
  <br>
  <br>
  <br>
</div>




