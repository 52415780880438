import { Component, Input } from '@angular/core';
import { ThemeService } from "../service/theme.service";
import { Observable } from "rxjs";

/**
 * Enum representing the possible statuses of a task.
 */
export enum TaskStatus {
  /**
   * Task is pending and not started yet
   */
  Pending = "P",

  /**
   * Task is currently being worked on
   */
  InProgress = "I",

  /**
   * Task has been completed
   */
  Completed = "C",

  /**
   * Task has been cancelled
   */
  Cancelled = "X",

  /**
   * Task has been delayed
   */
  Delayed = "D",
}

// Returns a TaskStatus from a string
export function formatTaskStatus(status: string): TaskStatus {
  switch (status) {
    case "C": return TaskStatus.Completed;
    case "I": return TaskStatus.InProgress;
    case "X": return TaskStatus.Cancelled;
    case "D": return TaskStatus.Delayed;
    case "P": return TaskStatus.Pending;

    default: return TaskStatus.Pending;
  }
}

/**
 * Interface representing a task with an id, name, and status.
 */
export interface Task {
  id: string;
  name$: Observable<string>;
  description$: Observable<string>;
  status: TaskStatus;
}

/**
 * Interface representing a group of tasks, each with an id, name, and a list of tasks.
 */
export interface TaskGroup {
  id: string;
  name$: Observable<string>;
  tasks: Task[];
}

@Component({
  selector: 'app-progress-tracker',
  templateUrl: './progress-tracker.component.html',
  styleUrls: ['./progress-tracker.component.css'],
})
export class ProgressTrackerComponent {
  // Expose type to the html
  public TaskStatus = TaskStatus;

  // Input property to receive task groups data
  @Input() taskGroups: TaskGroup[] = [];

  constructor(public themeService: ThemeService) { }

  public setTaskStatus(groupId: string, taskId: string, newStatus: TaskStatus){
    this.taskGroups.forEach((taskGroup) => {
      if(taskGroup.id === groupId){
        taskGroup.tasks.forEach((task) => {
          if(task.id === taskId)
            task.status = newStatus;
        });
      }
    });
  }

  /**
   * Returns the icon for a task based on its status.
   *
   * @param status - The status of the task
   * @returns The corresponding Material icon
   */
  getTaskStatusIcon(status: TaskStatus): string {
    switch (status) {
      case TaskStatus.Pending: return 'hourglass_empty'; // Material icon for pending tasks
      case TaskStatus.InProgress: return 'hourglass_top'; // Material icon for tasks in progress
      case TaskStatus.Completed: return 'check_circle'; // Material icon for completed tasks
      case TaskStatus.Delayed: return 'schedule'; // Material icon for completed tasks
      case TaskStatus.Cancelled: return 'cancel'; // Material icon for completed tasks
    }
  }

  /**
   * Returns the appropriate CSS class based on the task's status.
   *
   * @param status - The status of the task
   * @returns The corresponding CSS class
   */
  getTaskStatusClass(status: TaskStatus): string {
    switch (status) {
      case TaskStatus.Pending: return 'status-pending'; // Material icon for pending tasks
      case TaskStatus.InProgress: return 'status-in-progress'; // Material icon for tasks in progress
      case TaskStatus.Completed: return 'status-completed'; // Material icon for completed tasks
      case TaskStatus.Delayed: return 'status-delayed'; // Material icon for completed tasks
      case TaskStatus.Cancelled: return 'status-cancelled'; // Material icon for completed tasks
    }
  }

  /**
   * Returns the overall status of a task group based on the tasks inside.
   *
   * @param tasks - The list of tasks in the group
   * @returns The group status: 'Completed', 'In Progress', or 'Pending'
   */
  getGroupStatus(tasks: Task[]): TaskStatus {
    if (tasks.every(task => task.status === TaskStatus.Completed)) {
      return TaskStatus.Completed; // All tasks are completed
    }
    else if (tasks.some(task => task.status === TaskStatus.InProgress)) {
      return TaskStatus.InProgress; // Some tasks are in progress
    }
    return TaskStatus.Pending; // All tasks are still pending
  }

  /**
   * Determines whether a task group can be activated (based on the completion of previous groups).
   *
   * @param index - The index of the task group
   * @returns True if the group can be activated, false otherwise
   */
  canActivateGroup(index: number): boolean {
    if (index === 0) return true;
    return this.taskGroups[index - 1]?.tasks.every(task => task.status === TaskStatus.Completed);
  }
}
