<!-- Container -->
<article [ngClass]="theme.getThemeClass()">
  <!-- Gridster Parent Container -->
  <gridster
    [ngClass]="theme.getThemeClass()"
    [options]="gridsterOptions"
    style="height: 100vh !important"
  >
    <!-- Navigation Items -->
    <ng-container *ngFor="let item of navigationItemsArray">
      <!-- Tile -->
      <gridster-item [item]="item">
        <!-- Your tile content goes here -->
        <nav [ngClass]="theme.getThemeClass()" class="dashboard-tile">
          <header class="dashboard-tile-header">
            <h6 class="dashboard-tile-title">
              <a
                style="color: inherit; text-decoration: none; cursor: pointer"
                [routerLink]="['/', item.routeUrl]"
                >{{ item.name | translate }}</a
              >
            </h6>
            <span class="dashboard-tile-action-container">
              <a
                class="nav-link e"
                href="javascript:void(0);"
                style="border: none"
                data-bs-toggle="dropdown"
              >
                <i class="fa fa-bars"></i>
              </a>
              <ul
                [ngClass]="theme.getThemeClass()"
                class="ulDrop dropdown-menu dropdown-menu-light"
              >
                <!-- Menu Items -->
                <li>
                  <a
                    [ngClass]="theme.getThemeClass()"
                    class="dropdown-item"
                    [routerLink]="['/', item.routeUrl]"
                  >
                    <i class="textAlignCenter" [ngClass]="item.icon"></i>
                    <span class="customMarginLeft1">
                      {{ item.name | translate }}
                    </span>
                  </a>
                </li>
              </ul>
            </span>
          </header>
          <div [ngClass]="theme.getThemeClass()" class="dashboard-tile-content">
            <!-- <app-loading-element></app-loading-element> -->
            <nav
              *ngIf="
                !dashboard.stopLoading && item.name !== 'sidenavMenu.ZONES'
              "
              class="loading-gif"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <!-- Container with box styles and fade-in-out animation -->
              <div class="box fade-in-out">
                <!-- Font Awesome spinner icon with custom styles -->
                <div class="fa-3x mb-2">
                  <i class="fa-solid fa-spinner fa-spin-pulse fa-2xl color"></i>
                </div>
                <!-- Logo container with dynamic theme class and navigation link -->
                <div>
                  <a
                    [ngClass]="theme.getThemeClass()"
                    class="hide-too-small-screen navbar-brand px-1"
                    href="javascript:void(0);"
                    data-bs-toggle="dropdown"
                  >
                    <!-- Logo image with custom styles -->
                    <img class="logo" src="../assets/muirwood-studio.png" />
                  </a>
                </div>
              </div>
            </nav>
            <!-- charts would go here if applicable -->
          </div>
        </nav>
      </gridster-item>
    </ng-container>
  </gridster>
</article>
