import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { SystemMessageService } from "./system-message.service";

export interface Route {
  route_id: string;
  truck_id?: string;
  creation_date: string;
  start_date?: string;
  end_date?: string;
  kilometer?: string;
  bin_usage: string;
  total_volume: number;
  threshold: number;

  geometry?: string;
  legal_name: string; //TODO Where is it set?

  stops: Stop[]; // Currently loaded separately

  // Need to be computed //TODO probably not the best approach
  formattedTotalTime: number,
  formattedTimestamp: string,

}

export interface Stop{
  truck_id: string;
  route_id: string;
  route_stop_id: string;
  index: number;
  bin_id: string;
  bin_gps: string;
  bin_address: string;
  status: string;

  approximate_time: string;
  completion_time: string;
  creation: string;
  modification: string;
  operator_id: string;
}

@Injectable({
  providedIn: 'root'
})

export class RouteService {
  public driverRoutesArray: any;
  public driverSelectedRoute: string = '';
  public driver_id: string = '';
  public stopsArray: Stop[] = [];

  constructor(
    private http: HttpClient,
    private systemMessageService: SystemMessageService,
  ) { }


  /**
   * Fetches today's routes for a specific operator by their ID.
   * Optionally includes route geometry data in the response.
   *
   * @param operatorId - The ID of the operator for whom to fetch today's routes.
   * @param fetchGeometry - Whether to include geometry data for the routes (default: false).
   * @returns A Promise resolving to a string containing the route data.
   */
  getTodayRoutesByOperatorId(operatorId: string, fetchGeometry: boolean = false): Promise<Route[]>{
    const url = environment.api.stage + environment.api.route.getTodayRoutes + "&operator_id=" + operatorId + "&fetch_geometry=" + fetchGeometry;
    return firstValueFrom(this.http.get<Route[]>(url));
  }

  /**
   * Fetches today's routes and updates the todayRoutesArray
   *
   * @returns A Promise resolving to an array of today's routes
   */
  getTodayRoutes(): Promise<Route[]> {
    const url = environment.api.stage + environment.api.route.getTodayRoutes;
    return firstValueFrom(this.http.get<Route[]>(url));
  }

  /**
   * Fetches and updates the driver routes array for a given truck_id
   *
   * @param truck_id The ID of the truck
   * @returns A Promise resolving to void
   */
  getRoutesIdByTruckId(truck_id: string): Promise<void> {//TODO fix return type
    const url = environment.api.stage + environment.api.route.getRoutesIdByTruckId;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return firstValueFrom(
      this.http.post<any[]>(url, { truck_id }, { headers })
    ).then((response) => {
      if (response) {
        this.driverRoutesArray = response;
      } else {
        throw new Error('No routes found for this truck');
      }
    });
  }

  /**
   * Returns a Promise of the route's stops, from the given route_id
   *
   * @param route_id
   */
  getRouteStopByRouteId(route_id: string): Promise<Stop[]>{
    const url = environment.api.stage + environment.api.route.getRouteStopByRouteId + '&route_id='+route_id;
    let temp = firstValueFrom(this.http.get<Stop[]>(url));
    temp.then(r => console.log(r));
    return temp;
  }

  /**
   * Returns a Promise of the stops details for a given route_id
   *
   * @param route_id The ID of the route
   * @returns A Promise resolving to the stops details
   */
  getStopsDetails(route_id: string): Promise<Stop[]> {
    const url = environment.api.stage + environment.api.route.getStopsDetails;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return firstValueFrom(
      this.http.post<Stop[]>(url, { route_id }, { headers })
    );
  }

  updateRouteStopHTTP(stop_id: string, status: string):Promise<boolean>{
    const url = environment.api.stage + environment.api.route.updateRouteStopStatus;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return new Promise((resolve)=>{
      this.http.post(url, { "stop_id": stop_id, "status": status }, { headers: headers })
        .subscribe({
          next: ()=>{
            resolve(true)
          },
          error: ()=>{
            resolve(false);
          }
        });
    })
  }

  /**
   * Call our API to log the stop, returns true on success and false on error.
   * @param route_stop_id
   * @param operator_id
   * @param status
   * @param route_stop_error
   * @param comment
   * @returns
   */
  logRouteStop(route_stop_id:string, operator_id:string, status:string, route_stop_error:string = '', comment:string = ''){
    const url = environment.api.stage + environment.api.route.logRouteStop;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return new Promise((resolve)=>{
      this.http.post(url, {
        "route_stop_id": route_stop_id,
        "operator_id": operator_id,
        "status": status,
        "route_stop_error": route_stop_error,
        "comment": comment
      },
      { headers: headers })
        .subscribe({
          next: ()=>{
            resolve(true);
            this.systemMessageService.selectRibbon('success', 'stopLoggedSuccess');
          },
          error: ()=>{
            resolve(false);
            this.systemMessageService.buttonBlocked = true;
            // If so, display a danger ribbon message with the corresponding key
            this.systemMessageService.selectRibbon('danger', 'stopLoggedError');
          }
        });
    })
  }

  /**
   * Set the start_date of the route in the DB
   * @param route_id
   * @returns
   */
  startRoute(route_id: string){
    const url = environment.api.stage + environment.api.route.startRoute;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return new Promise(()=>{
      this.http.post(url, {
        'route_id': route_id,
      }, {headers}).subscribe();
    })
  }

  /**
   * Set the end_date of the route in the DB
   * @param route_id
   * @returns
   */
  endRoute(route_id: string){
    const url = environment.api.stage + environment.api.route.endRoute;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return new Promise(()=>{
      this.http.post(url, {
        'route_id': route_id,
      }, {headers}).subscribe();
    })
  }

}
