<div [ngClass]="theme.getThemeClass()" class="container-fluid">
  <dispatch-contact-modal
  *ngIf=" this.dispatchService.isContactModalOpen" ></dispatch-contact-modal>
  <!-- Side nav -->
    
    <!-- Menu items -->
    <nav class="nav"  >
      <!-- to client list -->
      <nav
        (click)="navigate(1)"
        id="client"
        [ngClass]="{ 'active': activeTab === 'client' }"
        class="nav-item nav-link "
      >
        <span class="nav-item-text">Client List</span>
        <i class="fa-solid fa-users-line icon"></i>
      </nav>
      <!-- to device list -->
      <nav
        (click)="navigate(2)"
        id="iot"
        [ngClass]="{ 'active': activeTab === 'device' }" 
        class="nav-item nav-link "
      >
      <span class="nav-item-text">Device List</span>
        <i class="fa-solid fa-tachograph-digital icon"></i>
      </nav>
      <!-- to associated device -->
      <!-- <nav
        (click)="navigate(3)"
        id="route"
        [ngClass]="{ 'active': activeTab === 'associations' }" 
        class="nav-item nav-link"
      >
      <span class="nav-item-text">Associated Users</span>
        <i class="fa-solid fa-address-book"></i>
      </nav> -->
      <!-- to overview map -->
      <nav
        (click)="navigate(4); adjustSideBar()"
        id="map"
        [ngClass]="{ 'active': activeTab === 'map' }" 
        class="nav-item nav-link"
      >
      <span class="nav-item-text">map</span>
      <i class="fa-solid fa-map-location-dot"></i>
      </nav>
    </nav>
  
  <!-- Section container -->
  <div class="section-container">
    <!-- Client list section -->
    <div id="clients" class="tab" [hidden]="!isClientsTabOpen">
      <div class="toTop">
        <a (click)="scrollToTop()"><i class="fa-solid fa-circle-arrow-up"></i></a>
      </div>
      <section
        [ngClass]="theme.getThemeClass()"
        class="table-container width100"
      >
      
        <article id="clientTop" class="table-responsive minMarginBotton">
          <!-- Search input component, shown when mainContent is 'clients' -->
          <header [ngClass]="theme.getThemeClass()" class="table-header">
            <h2 class="mobile">
              {{ "clientList" | translate }} ({{
                this.clientArray.length
              }})
            </h2>
          </header>
         
          <header id="search-component" class="input-group">
            <input
              #searchBox
              type="search"
              id="search-box"
              [(ngModel)]="clientSearch"
              (input)="search()"
              class="form-control rounded"
              placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}"
              aria-label="Search"
              aria-describedby="search-addon"
            />
          </header>
          <nav id="search-component" class="nav-sort-filter">
            <!-- Section for filter and sort list -->

            <ng-select
              class="form-control ng-select sort-by-select"
              [(ngModel)]="this.filterBy"
              (change)="onFilterByChange()"
              placeholder="{{ 'filterBy' | translate }}"
              [ngClass]="{ 'has-value': this.filterBy }"
            >
              <ng-option value="" disabled selected>{{
                "filterBy" | translate
              }}</ng-option>
              <ng-option value="username">{{
                "cognitoLoginUsername" | translate
              }}</ng-option>
              <ng-option value="given_name">{{
                "givenName" | translate
              }}</ng-option>
              <ng-option value="family_name">{{
                "familyName" | translate
              }}</ng-option>
            </ng-select>

            <ng-select
              class="form-control ng-select sort-by-select"
              [(ngModel)]="this.sortBy"
              (change)="onSortChange('select', $event)"
              placeholder="{{ 'sortBy' | translate }}"
              [ngClass]="{ 'has-value': this.sortBy }"
            >
              <ng-option value="" disabled selected>{{
                "sortBy" | translate
              }}</ng-option>
              <ng-option value="username">{{
                "cognitoLoginUsername" | translate
              }}</ng-option>
              <ng-option value="given_name">{{
                "givenName" | translate
              }}</ng-option>
              <ng-option value="family_name">{{
                "familyName" | translate
              }}</ng-option>
            </ng-select>
            <div class="card-title mt-0 status">
              
                <label class="switch">
                  <input
                    type="checkbox"
                    [ngModel]="this.ascDesc"
                    (change)="onSortChange('switch', $event)"
                  />
                  <span class="slider round">
                    <span class="toggle-switch-label-off">{{
                      "asc" | translate
                    }}</span>
                    <span class="toggle-switch-label-on">{{
                      "desc" | translate
                    }}</span>
                  </span>
                </label>
             
              
              <label class="toggleSelectAll">
                <input
                  style="display: block; margin: 0 auto"
                  type="checkbox"
                  (change)="toggleSelectAll($event)"
                  [checked]="areAllChecked()"
                />
                <span>{{'selectALl'| translate}}</span>
              </label>
              <label class="toggleSelectClient">
                <input
                  style="display: block; margin: 0 auto"
                  type="checkbox"
                  (change)="toggleAssociatedClient($event)"
                />
                <span> {{ "associatedClient" | translate }} </span>
              </label>
              <div style="width: 128px; height: 38px"
              *ngIf="hasCheckedClient() && !this.showingAssociations"
              >
                <button
                  
                  [ngClass]="theme.getThemeClass()"
                  class="btn btn-dropdown"
                  (click)="associateClients()"
                >
                  {{ "dispatchAssociate" | translate }}
                </button>
              </div>
              <div style="width: 128px; height: 38px"
              *ngIf="hasAssociatedClient()"
              >
                <button
                 
                  [ngClass]="theme.getThemeClass()"
                  class="btn btn-dropdown"
                  (click)="dissociateClients()"
                >
                  {{ "dispatchDissociate" | translate }}
                </button>
              </div>
            </div>
          </nav>
          <div class="grid-container">
            <section
              [ngClass]="theme.getThemeClass(true)"
              class="gridster"
              id="clientGridster"
              [ngStyle]="getColumnStyle()"
              style="height: 100%"
            >
              <ng-container *ngFor="let item of clientArray; let i = index">
                <article
                  class="gridster-item"
                  [ngClass]="item.checked ? 'selected' : ''"
                >
                  <!-- Your tile content goes here -->
                  <nav
                    [ngClass]="theme.getThemeClass(true)"
                    class="dashboard-tile"
                  >
                    <header
                      class="dashboard-tile-header"
                      (click)="select(item)"
                    >
                      <h6 class="dashboard-tile-title">
                        {{ item.given_name }} {{ item.family_name }}
                      </h6>
                      <input
                        class="clientSelector"
                        type="checkbox"
                        [(ngModel)]="item.checked"
                        name=""
                        id="{{ item.client_id }}"
                      />
                    </header>
                    <hr />
                    <div class="tiles-container">
                      <div class="titles-items">
                        <i class="fa fa-user"></i
                        ><label>{{ item.client_name }}</label>
                      </div>
                      <div
                        class="titles-items ellipsis-container"
                        (click)="displayAddress(i)"
                        style="cursor: pointer"
                      >
                        <span class="i"
                          ><i class="fa-solid fa-location-dot pointer"></i
                        ></span>
                        <label
                          style="cursor: pointer"
                          *ngIf="item.address; else noneApplicable"
                          id="{{ i }}"
                          class="address"
                          >{{ item.address }}</label
                        >
                      </div>
                      <div class="titles-items">
                        <i class="fa-solid fa-phone"></i
                        ><label
                          *ngIf="item.phone_number; else noneApplicable"
                          >{{ item.phone_number }}</label
                        >
                      </div>
                      <div class="titles-items">
                        <i class="fa-solid fa-at"></i
                        ><label>{{ item.email }}</label>
                      </div>
                      <div class="titles-items">
                        <i class="fa-solid fa-tachograph-digital"></i>
                        <label>{{ getDevices(item.client_id) }}</label>
                      </div>
                      <div class="titles-items">
                        <i class="fa-solid fa-address-book"></i>
                        <button (click)="openModal(item)" >contact client</button>
                      </div>
                    </div>
                  </nav>
                </article>
              </ng-container>
              
            </section>
          </div>
        </article>
      </section>
    </div>
    <div
      [ngClass]="theme.getThemeClass()"
      id="devices"
      class="tab"
      *ngIf="isDevicesTabOpen"
    >
    
    <div class="toTop">
      <a (click)="scrollToTop()"><i class="fa-solid fa-circle-arrow-up"></i></a>
    </div>
   
      <section
        [ngClass]="theme.getThemeClass()"
        class="table-container width100"
      >
      
        <article  id="deviceTop" class="table-responsive minMarginBotton">
          <!-- Search input component, shown when mainContent is 'clients' -->
          <header [ngClass]="theme.getThemeClass()" class="table-header">
            <h2 class="mobile">
              {{ "userlistTableHeaderDeviceList" | translate }} ({{
                this.allThings.length
              }})
            </h2>
          </header>
          
          <header id="search-component" class="input-group">
            <input
              #searchBox
              type="search"
              id="search-box"
              [(ngModel)]="deviceSearch"
              (input)="search(1)"
              class="form-control rounded"
              placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}"
              aria-label="Search"
              aria-describedby="search-addon"
            />
          </header>
          <nav id="search-component" class="nav-sort-filter">
            <ng-select
              placeholder="{{ 'filterBy' | translate }}"
              [ngClass]="{ 'has-value': this.statusFilter }"
              (input)="onStatusFilterChange()"
              class="form-control ng-select sort-by-select"
              [(ngModel)]="statusFilter"
              (ngModelChange)="onStatusFilterChange()"
            >
              <!-- <ng-option value="" disabled selected>{{ "filterBy" | translate }}</ng-option> -->
              <ng-option value="" disabled selected class="sort-by-header">{{
                "filterBy" | translate
              }}</ng-option>
              <!-- FILTER BY STATUS -->
              <ng-option value="Healthy">{{ "Healthy" | translate }}</ng-option>
              <ng-option value="Unhealthy">{{
                "Unhealthy" | translate
              }}</ng-option>
              <ng-option value="Inactive">{{
                "Inactive" | translate
              }}</ng-option>
            </ng-select>

            <ng-select
              class="form-control ng-select sort-by-select"
              [(ngModel)]="sortDeviceBy"
              (change)="onSortDeviceChange('select', $event)"
              [ngClass]="{ 'has-value': this.sortDeviceBy }"
              placeholder="{{ 'sortBy' | translate }}"
            >
              <ng-option value="" disabled selected>{{
                "sortBy" | translate
              }}</ng-option>
              <ng-option value="lastUpdate"
                >{{ " " }}{{ "LastActivity" | translate }}</ng-option
              >
              <ng-option value="battery"
                >{{ " " }}{{ "iotModalHeaderBattery" | translate }}</ng-option
              >
              <ng-option value="fill"
                >{{ " " }}{{ "fillRate" | translate }}</ng-option
              >
            </ng-select>

            <div class="card-title mt-0 status">
              <label class="switch">
                <input
                  type="checkbox"
                  [ngModel]="this.ascDesc"
                  (change)="onSortDeviceChange('switch', $event)"
                />
                <span class="slider round">
                  <span class="toggle-switch-label-off">{{
                    "asc" | translate
                  }}</span>
                  <span class="toggle-switch-label-on">{{
                    "desc" | translate
                  }}</span>
                </span>
              </label>
              <label class="toggleSelectAll">
                <input
                  style="display: block; margin: 0 auto"
                  type="checkbox"
                  (change)="toggleAssociatedClientDevice($event)"
                />
                <span> {{ "associatedClient" | translate }} </span>
              </label>
              <div style="width: 128px; height: 38px">
                <button
                  *ngIf="hasCheckedClient()"
                  [ngClass]="theme.getThemeClass()"
                  class="btn btn-dropdown"
                  (click)="associateClients()"
                >
                  {{ "dispatchAssociate" | translate }}
                </button>
              </div>
            </div>
          </nav>
          <div class="grid-container">
            <section
              [ngClass]="theme.getThemeClass(true)"
              class="gridster"
              id="thingsGridster"
              
              style="height: 100%"
            >
              <ng-container *ngFor="let thing of allThings; let i = index">
                <article class="gridsterItemClientThing">
                  <!-- Your tile content goes here -->
                  <nav
                    [ngClass]="theme.getThemeClass()"
                    class="dashboardThing-tile"
                  >
                    <header class="dashboard-tile-header">
                      <!-- Light for device state -->
                      <div
                        class="mouseover-div"
                        (mouseenter)="this.showStateInfo[i] = true"
                        (mouseleave)="this.showStateInfo[i] = false"
                        (touchstart)="showStateInfo[i] = true"
                        (touchend)="showStateInfo[i] = false"
                      >
                        <div class="trafic-light-box">
                          <div class="traffic-light">
                            <div
                              class="light"
                              [ngClass]="{
                                'light-green':
                                  thing.heartbeat &&
                                  thing.heartbeat.color === 'green',
                                'light-yellow':
                                  thing.heartbeat &&
                                  thing.heartbeat.color === 'yellow',
                                'light-red':
                                  thing.heartbeat &&
                                  thing.heartbeat.color === 'red'
                              }"
                            ></div>
                          </div>
                        </div>
                        <h6 class="dashboardThing-tile-title">
                          {{ thing.thing_name | translate }}
                        </h6>

                        <!-- Info bubble on mouse over -->
                        <div
                          class="info-state-box"
                          [ngClass]="theme.getThemeClass()"
                          *ngIf="showStateInfo[i]"
                        >
                          <div class="state-info-layer">
                            <label class="state-title">
                              {{ "beatMiss" | translate }} :
                            </label>
                            <label class="state-info">
                              <span
                                *ngIf="
                                  this.iotService.isBeatMissMoreThenOneHundred(
                                    thing.heartbeat?.beat_miss
                                  )
                                "
                                >+
                              </span>
                              {{ thing.heartbeat.beat_miss }}
                            </label>
                          </div>
                          <div class="state-info-layer">
                            <label class="state-title">
                              {{ "suposeToCommunicate" | translate }} :
                            </label>
                            <label class="state-info">
                              {{ thing.heartbeat.supose_to_communicate }}
                            </label>
                          </div>
                          <div class="state-info-layer">
                            <label class="state-title">
                              {{ "sleepTimeConfig" | translate }} :
                            </label>
                            <label class="state-info">
                              {{
                                this.iotService.convertSecondToHour(
                                  thing.sleep_time
                                ) | translate
                              }}
                            </label>
                          </div>
                        </div>
                      </div>

                      <i
                        *ngIf="thing.battery > 100"
                        class="fa-solid fa-plug-circle-bolt"
                      ></i>
                      <span class="dashboard-tile-action-container">
                        <a
                          class="nav-link e"
                          href="javascript:void(0);"
                          data-bs-toggle="dropdown"
                        >
                          <i class="fa fa-bars"></i>
                        </a>
                        <ul
                          [ngClass]="theme.getThemeClass()"
                          class="dropdown-menu dropdown-menu-lg-end"
                        >
                          <!-- Menu Items -->
                          <li>
                            <a
                              [ngClass]="theme.getThemeClass()"
                              class="dropdown-item"
                              style="cursor: pointer"
                              (click)="setNavToDeviceStat(thing.thing_name)"
                            >
                              <i
                                class="textAlignCenter"
                                [ngClass]="thing.icon"
                              ></i>
                              <span class="customMarginLeft1">
                                {{ "deviceStatistics" | translate }}
                              </span>
                            </a>
                          </li>
                          <!--  <li [ngClass]="{'closed': thing.bin_id === ''}">
                              <a (click)="routeToUpdateBin(thing.bin_id)" [ngClass]="theme.getThemeClass()" class="dropdown-item" href="javascript:void(0)">
                                <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                                <span class="customMarginLeft1" [ngClass]="{'closed': thing.bin_id === ''}">
                                  {{ 'viewBin' | translate }}
                                </span>
                              </a>
                            </li> -->
                          <!-- <li>
                              <a (click)="exportCSV(thing)" [ngClass]="theme.getThemeClass()" class="dropdown-item" href="javascript:void(0)">
                                <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                                <span class="customMarginLeft1">
                                  {{ 'exportCSV' | translate }}
                                </span>
                              </a>
                            </li> -->
                          <!-- <li>
                              <a (click)="this.iotService.showConfig(thing.thing_name)" [ngClass]="theme.getThemeClass()" class="dropdown-item" href="javascript:void(0)">
                                <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                                <span class="customMarginLeft1">
                                  {{ 'iotModalViewConfig' | translate }}
                                </span>
                              </a>
                            </li> -->
                          <!--   <li>
                              <a routerLink="/work-order-create/{{thing.thing_name}}" [ngClass]="theme.getThemeClass()" class="dropdown-item" href="javascript:void(0)">
                                <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                                <span class="customMarginLeft1">
                                  {{ 'createWorkOrder' | translate }}
                                </span>
                              </a>
                            </li> -->
                          <!--  <li>
                              <a (click)="this.associateDevice(thing.thing_name, 'client')" [ngClass]="theme.getThemeClass()" class="dropdown-item" href="javascript:void(0)">
                                <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                                <span class="customMarginLeft1">
                                  {{ 'clientAssociation' | translate }}
                                </span>
                              </a>
                            </li> -->
                          <!--  <li>
                              <a (click)="this.associateDevice(thing.thing_name, 'bin')" [ngClass]="theme.getThemeClass()" class="dropdown-item" href="javascript:void(0)">
                                <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                                <span class="customMarginLeft1">
                                  {{ 'binAssociation' | translate }}
                                </span>
                              </a>
                            </li> -->
                        </ul>
                      </span>
                    </header>
                    <hr />
                    <div
                      [ngClass]="theme.getThemeClass()"
                      class="dashboardThing-tile-content"
                    >
                      <div class="marketLabel">
                        {{ thing.device?.bin.bin_usage | translate }}
                        {{ thing.device.bin.market_segment | translate }}
                      </div>
                      <div class="itemsDisplay">
                        <div class="firstRow">
                          <!-- HEALTHY -->
                          <div class="itemsColum">
                            <div class="fa-3x">
                              <i
                                *ngIf="thing.status === 'Healthy'"
                                class="fa-solid fa-heart-pulse greenC"
                                [title]="'Healthy'"
                              ></i>
                              <i
                                *ngIf="thing.status === 'Inactive'"
                                class="fa-solid fa-heart-pulse grayC"
                                [title]="'Inactive'"
                              ></i>
                              <i
                                *ngIf="thing.status === undefined"
                                class="fa-solid fa-heart-pulse grayC"
                                [title]="'Inactive'"
                              ></i>
                              <i
                                *ngIf="thing.status === 'Unhealthy'"
                                class="fa-solid fa-heart-pulse fa-fade redC"
                                [title]="'Unhealthy'"
                              ></i>
                            </div>
                            <div class="iconLabel">
                              {{ thing.status | translate }}
                            </div>
                          </div>

                          <!-- BATTERY -->
                          <div class="itemsColum">
                            <div class="fa-3x">
                              <i
                                *ngIf="thing.status === 'Inactive'"
                                class="fa-solid fa-battery-empty grayC"
                                [title]="'Inactive'"
                              ></i>
                              <i
                                [ngStyle]="getBatteryLevel(thing.battery)"
                                *ngIf="
                                  thing.battery > 3.5 &&
                                  thing.status != 'Inactive'
                                "
                                class="fa-solid fa-battery-full"
                                [title]="thing.battery + '%'"
                              ></i>
                              <i
                                [ngStyle]="getBatteryLevel(thing.battery)"
                                *ngIf="
                                  thing.battery <= 3.5 &&
                                  thing.battery > 2.5 &&
                                  thing.status != 'Inactive'
                                "
                                class="fa-solid fa-battery-three-quarters"
                                [title]="thing.battery + '%'"
                              ></i>
                              <i
                                [ngStyle]="getBatteryLevel(thing.battery)"
                                *ngIf="
                                  thing.battery <= 2.5 &&
                                  thing.battery > 1.5 &&
                                  thing.status != 'Inactive'
                                "
                                class="fa-solid fa-battery-half"
                                [title]="thing.battery + 'V'"
                              ></i>
                              <i
                                [ngStyle]="getBatteryLevel(thing.battery)"
                                *ngIf="
                                  thing.battery <= 1.5 &&
                                  thing.battery > 0 &&
                                  thing.status != 'Inactive'
                                "
                                class="fa-solid fa-battery-quarter fa-fade"
                                [title]="thing.battery + '%'"
                              ></i>
                              <i
                                [ngStyle]="getBatteryLevel(thing.battery)"
                                *ngIf="
                                  thing.battery == 0 &&
                                  thing.status != 'Inactive'
                                "
                                class="fa-solid fa-battery-empty fa-fade"
                                [title]="thing.battery + '%'"
                              ></i>
                              <i
                                [ngStyle]="getBatteryLevel(thing.battery)"
                                *ngIf="
                                  thing.battery == null ||
                                  thing.battery == undefined
                                "
                                class="fa-solid fa-battery-empty grayC"
                                [title]="'Inactive'"
                              ></i>
                            </div>
                            <div
                              *ngIf="
                                thing.battery >= 0 &&
                                thing.battery <= 100 &&
                                thing.status != 'Inactive'
                              "
                              class="iconLabel"
                            >
                              {{ thing.battery + "%" | translate }}
                            </div>
                            <div *ngIf="thing.battery > 100" class="iconLabel">
                              {{ "charging" | translate }}
                            </div>
                            <div
                              *ngIf="thing.status === 'Inactive'"
                              class="iconLabel"
                            >
                              {{ "Inactive" | translate }}
                            </div>
                          </div>
                          <!-- FILL LEVEL -->
                          <div class="itemsColum">
                            <div class="fa-3x">
                              <i
                                [ngStyle]="getFillLevelStyle(thing.fill_level)"
                                *ngIf="thing.status === 'Healthy'"
                                class="fa-solid fa-trash-can grayC"
                                [title]="thing.fill_level"
                              ></i>
                              <i
                                [ngStyle]="getFillLevelStyle(thing.fill_level)"
                                *ngIf="thing.status === 'Unhealthy'"
                                class="fa-solid fa-trash-can fa-fade grayC"
                                [title]="thing.fill_level"
                              ></i>
                              <i
                                *ngIf="
                                  thing.status === 'Inactive' ||
                                  thing.status === undefined
                                "
                                class="fa-solid fa-trash-can grayC"
                                [title]="thing.fill_level"
                              ></i>
                            </div>
                            <div class="iconLabel">
                              {{ thing.fill_level | translate }}
                            </div>
                          </div>
                          <!-- TONNAGE -->
                          <div class="itemsColum">
                            <div class="fa-3x">
                              <nav class="fadeIn first">
                                <img
                                  style="height: 35px; width: 35px"
                                  class="logo"
                                  src="../assets/tonne-5123.webp"
                                />
                              </nav>
                            </div>
                            <div class="iconLabel">
                              {{ thing.tonnage + " T" }}
                            </div>
                          </div>
                        </div>
                        <div class="secondRow">
                          <div class="locationLabel">
                            <i class="fa-solid fa-map-pin locationLabel"></i
                            >{{ thing.location }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </nav>
                </article>
              </ng-container>
              
            </section>
          </div>
        </article>
        
      </section>
    </div>
    <div style="height: 50px !important;"></div>
   
    <div id="report" class="tab" [hidden]="!isMapTabOpen">
      
        <section id="device-list" #scroll>
          <!-- <header id="search-component" class="input-group">
            <input
              #searchBox
              type="search"
              id="search-box"
              [(ngModel)]="deviceSearch"
              (input)="search(1)"
              class="form-control rounded"
              placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}"
              aria-label="Search"
              aria-describedby="search-addon"
            />
          </header> -->
          <nav id="search-component-map" class="nav-sort-filter">
            <ng-select
              placeholder="{{ 'filterBy' | translate }}"
              [ngClass]="{ 'has-value': this.statusFilter }"
              (input)="onStatusFilterChange()"
              class="form-control ng-select sort-by-select"
              [(ngModel)]="statusFilter"
              (ngModelChange)="onStatusFilterChange()"
            >
              <!-- <ng-option value="" disabled selected>{{ "filterBy" | translate }}</ng-option> -->
              <ng-option value="" disabled selected class="sort-by-header">{{
                "filterBy" | translate
              }}</ng-option>
              <!-- FILTER BY STATUS -->
              <ng-option value="Healthy">{{ "Healthy" | translate }}</ng-option>
              <ng-option value="Unhealthy">{{
                "Unhealthy" | translate
              }}</ng-option>
              <ng-option value="Inactive">{{
                "Inactive" | translate
              }}</ng-option>
            </ng-select>

            <ng-select
              class="form-control ng-select sort-by-select"
              [(ngModel)]="sortDeviceBy"
              (change)="onSortDeviceChange('select', $event)"
              [ngClass]="{ 'has-value': this.sortDeviceBy }"
              placeholder="{{ 'sortBy' | translate }}"
            >
              <ng-option value="" disabled selected>{{
                "sortBy" | translate
              }}</ng-option>
              <ng-option value="lastUpdate"
                >{{ " " }}{{ "LastActivity" | translate }}</ng-option
              >
              <ng-option value="battery"
                >{{ " " }}{{ "iotModalHeaderBattery" | translate }}</ng-option
              >
              <ng-option value="fill"
                >{{ " " }}{{ "fillRate" | translate }}</ng-option
              >
            </ng-select>

            <div class="card-title mt-0 status">
              <label class="switch">
                <input
                  type="checkbox"
                  [ngModel]="this.ascDesc"
                  (change)="onSortDeviceChange('switch', $event)"
                />
                <span class="slider round">
                  <span class="toggle-switch-label-off">{{
                    "asc" | translate
                  }}</span>
                  <span class="toggle-switch-label-on">{{
                    "desc" | translate
                  }}</span>
                </span>
              </label>
              <label class="toggleSelectAll">
                <input
                  style="display: block; margin: 0 auto"
                  type="checkbox"
                  (change)="toggleAssociatedClientDevice($event)"
                />
                <span> {{ "associatedClient" | translate }} </span>
              </label>
              <div style="width: 128px; height: 38px">
                <button
                  *ngIf="hasCheckedClient()"
                  [ngClass]="theme.getThemeClass()"
                  class="btn btn-dropdown"
                  (click)="associateClients()"
                >
                  {{ "dispatchAssociate" | translate }}
                </button>
              </div>
            </div>
          </nav>
         
          <div class="mapThingContainer">
            <ng-container *ngFor="let thing of mapThings; let i = index">
              <article  class="mapThingsList" >
                <!-- Your tile content goes here -->
                <nav
                  [ngClass]="theme.getThemeClass()"
                  class="dashboardThing-tile"
                >
                  <header class="dashboard-tile-header"
                    (click)="addMarker(thing)"
                  >
                    <!-- Light for device state -->
                    <div
                      class="mouseover-div"
                      (mouseenter)="this.showStateInfo[i] = true"
                      (mouseleave)="this.showStateInfo[i] = false"
                      (touchstart)="showStateInfo[i] = true"
                      (touchend)="showStateInfo[i] = false"
                    >
                      <div class="trafic-light-box">
                        <div class="traffic-light">
                          <div
                            class="light"
                            [ngClass]="{
                              'light-green':
                                thing.heartbeat &&
                                thing.heartbeat.color === 'green',
                              'light-yellow':
                                thing.heartbeat &&
                                thing.heartbeat.color === 'yellow',
                              'light-red':
                                thing.heartbeat &&
                                thing.heartbeat.color === 'red'
                            }"
                          ></div>
                        </div>
                      </div>
                      <h6 class="dashboardThing-tile-title">
                        {{ thing.thing_name | translate }}
                      </h6>
  
                      <!-- Info bubble on mouse over -->
                      <div
                        class="info-state-box"
                        [ngClass]="theme.getThemeClass()"
                        *ngIf="showStateInfo[i]"
                      >
                        <div class="state-info-layer">
                          <label class="state-title">
                            {{ "beatMiss" | translate }} :
                          </label>
                          <label class="state-info">
                            <span
                              *ngIf="
                                this.iotService.isBeatMissMoreThenOneHundred(
                                  thing.heartbeat?.beat_miss
                                )
                              "
                              >+
                            </span>
                            {{ thing.heartbeat.beat_miss }}
                          </label>
                        </div>
                        <div class="state-info-layer">
                          <label class="state-title">
                            {{ "suposeToCommunicate" | translate }} :
                          </label>
                          <label class="state-info">
                            {{ thing.heartbeat.supose_to_communicate }}
                          </label>
                        </div>
                        <div class="state-info-layer">
                          <label class="state-title">
                            {{ "sleepTimeConfig" | translate }} :
                          </label>
                          <label class="state-info">
                            {{
                              this.iotService.convertSecondToHour(
                                thing.sleep_time
                              ) | translate
                            }}
                          </label>
                        </div>
                      </div>
                    </div>
  
                    <i
                      *ngIf="thing.battery > 100"
                      class="fa-solid fa-plug-circle-bolt"
                    ></i>
                    <span class="dashboard-tile-action-container">
                      <a
                        class="nav-link e"
                        href="javascript:void(0);"
                        data-bs-toggle="dropdown"
                      >
                        <i class="fa fa-bars"></i>
                      </a>
                      <ul
                        [ngClass]="theme.getThemeClass()"
                        class="dropdown-menu dropdown-menu-lg-end"
                      >
                        <!-- Menu Items -->
                        <li>
                          <a
                            [ngClass]="theme.getThemeClass()"
                            class="dropdown-item"
                            style="cursor: pointer"
                            (click)="setNavToDeviceStat(thing.thing_name)"
                          >
                            <i
                              class="textAlignCenter"
                              [ngClass]="thing.icon"
                            ></i>
                            <span class="customMarginLeft1">
                              {{ "deviceStatistics" | translate }}
                            </span>
                          </a>
                        </li>
                        <!--  <li [ngClass]="{'closed': thing.bin_id === ''}">
                            <a (click)="routeToUpdateBin(thing.bin_id)" [ngClass]="theme.getThemeClass()" class="dropdown-item" href="javascript:void(0)">
                              <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                              <span class="customMarginLeft1" [ngClass]="{'closed': thing.bin_id === ''}">
                                {{ 'viewBin' | translate }}
                              </span>
                            </a>
                          </li> -->
                        <!-- <li>
                            <a (click)="exportCSV(thing)" [ngClass]="theme.getThemeClass()" class="dropdown-item" href="javascript:void(0)">
                              <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                              <span class="customMarginLeft1">
                                {{ 'exportCSV' | translate }}
                              </span>
                            </a>
                          </li> -->
                        <!-- <li>
                            <a (click)="this.iotService.showConfig(thing.thing_name)" [ngClass]="theme.getThemeClass()" class="dropdown-item" href="javascript:void(0)">
                              <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                              <span class="customMarginLeft1">
                                {{ 'iotModalViewConfig' | translate }}
                              </span>
                            </a>
                          </li> -->
                        <!--   <li>
                            <a routerLink="/work-order-create/{{thing.thing_name}}" [ngClass]="theme.getThemeClass()" class="dropdown-item" href="javascript:void(0)">
                              <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                              <span class="customMarginLeft1">
                                {{ 'createWorkOrder' | translate }}
                              </span>
                            </a>
                          </li> -->
                        <!--  <li>
                            <a (click)="this.associateDevice(thing.thing_name, 'client')" [ngClass]="theme.getThemeClass()" class="dropdown-item" href="javascript:void(0)">
                              <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                              <span class="customMarginLeft1">
                                {{ 'clientAssociation' | translate }}
                              </span>
                            </a>
                          </li> -->
                        <!--  <li>
                            <a (click)="this.associateDevice(thing.thing_name, 'bin')" [ngClass]="theme.getThemeClass()" class="dropdown-item" href="javascript:void(0)">
                              <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                              <span class="customMarginLeft1">
                                {{ 'binAssociation' | translate }}
                              </span>
                            </a>
                          </li> -->
                      </ul>
                    </span>
                  </header>
                  <hr />
                  <div
                    [ngClass]="theme.getThemeClass()"
                    class="dashboardThing-tile-content"
                  >
                    <div class="marketLabel">
                      {{ thing.device?.bin.bin_usage | translate }}
                      {{ thing.device.bin.market_segment | translate }}
                    </div>
                    <div class="itemsDisplay">
                      <div class="firstRow">
                        <!-- HEALTHY -->
                        <div class="itemsColum">
                          <div class="fa-3x">
                            <i
                              *ngIf="thing.status === 'Healthy'"
                              class="fa-solid fa-heart-pulse greenC"
                              [title]="'Healthy'"
                            ></i>
                            <i
                              *ngIf="thing.status === 'Inactive'"
                              class="fa-solid fa-heart-pulse grayC"
                              [title]="'Inactive'"
                            ></i>
                            <i
                              *ngIf="thing.status === undefined"
                              class="fa-solid fa-heart-pulse grayC"
                              [title]="'Inactive'"
                            ></i>
                            <i
                              *ngIf="thing.status === 'Unhealthy'"
                              class="fa-solid fa-heart-pulse fa-fade redC"
                              [title]="'Unhealthy'"
                            ></i>
                          </div>
                          <div class="iconLabel">
                            {{ thing.status | translate }}
                          </div>
                        </div>
  
                        <!-- BATTERY -->
                        <div class="itemsColum">
                          <div class="fa-3x">
                            <i
                              *ngIf="thing.status === 'Inactive'"
                              class="fa-solid fa-battery-empty grayC"
                              [title]="'Inactive'"
                            ></i>
                            <i
                              [ngStyle]="getBatteryLevel(thing.battery)"
                              *ngIf="
                                thing.battery > 3.5 &&
                                thing.status != 'Inactive'
                              "
                              class="fa-solid fa-battery-full"
                              [title]="thing.battery + '%'"
                            ></i>
                            <i
                              [ngStyle]="getBatteryLevel(thing.battery)"
                              *ngIf="
                                thing.battery <= 3.5 &&
                                thing.battery > 2.5 &&
                                thing.status != 'Inactive'
                              "
                              class="fa-solid fa-battery-three-quarters"
                              [title]="thing.battery + '%'"
                            ></i>
                            <i
                              [ngStyle]="getBatteryLevel(thing.battery)"
                              *ngIf="
                                thing.battery <= 2.5 &&
                                thing.battery > 1.5 &&
                                thing.status != 'Inactive'
                              "
                              class="fa-solid fa-battery-half"
                              [title]="thing.battery + 'V'"
                            ></i>
                            <i
                              [ngStyle]="getBatteryLevel(thing.battery)"
                              *ngIf="
                                thing.battery <= 1.5 &&
                                thing.battery > 0 &&
                                thing.status != 'Inactive'
                              "
                              class="fa-solid fa-battery-quarter fa-fade"
                              [title]="thing.battery + '%'"
                            ></i>
                            <i
                              [ngStyle]="getBatteryLevel(thing.battery)"
                              *ngIf="
                                thing.battery == 0 &&
                                thing.status != 'Inactive'
                              "
                              class="fa-solid fa-battery-empty fa-fade"
                              [title]="thing.battery + '%'"
                            ></i>
                            <i
                              [ngStyle]="getBatteryLevel(thing.battery)"
                              *ngIf="
                                thing.battery == null ||
                                thing.battery == undefined
                              "
                              class="fa-solid fa-battery-empty grayC"
                              [title]="'Inactive'"
                            ></i>
                          </div>
                          <div
                            *ngIf="
                              thing.battery >= 0 &&
                              thing.battery <= 100 &&
                              thing.status != 'Inactive'
                            "
                            class="iconLabel"
                          >
                            {{ thing.battery + "%" | translate }}
                          </div>
                          <div *ngIf="thing.battery > 100" class="iconLabel">
                            {{ "charging" | translate }}
                          </div>
                          <div
                            *ngIf="thing.status === 'Inactive'"
                            class="iconLabel"
                          >
                            {{ "Inactive" | translate }}
                          </div>
                        </div>
                        <!-- FILL LEVEL -->
                        <div class="itemsColum">
                          <div class="fa-3x">
                            <i
                              [ngStyle]="getFillLevelStyle(thing.fill_level)"
                              *ngIf="thing.status === 'Healthy'"
                              class="fa-solid fa-trash-can grayC"
                              [title]="thing.fill_level"
                            ></i>
                            <i
                              [ngStyle]="getFillLevelStyle(thing.fill_level)"
                              *ngIf="thing.status === 'Unhealthy'"
                              class="fa-solid fa-trash-can fa-fade grayC"
                              [title]="thing.fill_level"
                            ></i>
                            <i
                            [ngStyle]="getFillLevelStyle(thing.fill_level)"
                              *ngIf="
                                thing.status === 'Inactive' ||
                                thing.status === undefined
                              "
                              class="fa-solid fa-trash-can grayC"
                              [title]="thing.fill_level"
                            ></i>
                          </div>
                          <div class="iconLabel">
                            {{ thing.fill_level | translate }}
                          </div>
                        </div>
                        <!-- TONNAGE -->
                        <div class="itemsColum">
                          <div class="fa-3x">
                            <nav class="fadeIn first">
                              <img
                                style="height: 35px; width: 35px"
                                class="logo"
                                src="../assets/tonne-5123.webp"
                              />
                            </nav>
                          </div>
                          <div class="iconLabel">
                            {{ thing.tonnage + " T" }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </article>
            </ng-container>
          </div>
          <div style="height: 50px !important;"></div>
        </section>
        <section id="map-container" class="map-container">
          <nav
          [ngClass]="theme.getThemeClass()"
          *ngIf="!mapLoaded"
          class="loading-gif"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <!-- Container with box styles and fade-in-out animation -->
          <div class="box fade-in-out">
            <!-- Font Awesome spinner icon with custom styles -->
            <div class="fa-3x mb-2">
              <i class="fa-solid fa-spinner fa-spin-pulse fa-2xl color"></i>
            </div>
            <!-- Logo container with dynamic theme class and navigation link -->
            <div>
              <a
                [ngClass]="theme.getThemeClass()"
                class="hide-too-small-screen navbar-brand px-1"
                href="javascript:void(0);"
                data-bs-toggle="dropdown"
              >
                <!-- Logo image with custom styles -->
                <img class="logo" src="../assets/muirwood-studio.png" />
              </a>
            </div>
          </div>
        </nav>
        </section>
    
    </div>
    </div>
  </div>
<ng-template #noneApplicable>
  <label>N/A</label>
</ng-template>
