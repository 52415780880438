import { Component, OnDestroy, OnInit } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { of } from 'rxjs';
import { ALL_MESSAGE_TYPES, WebSocketService, WebSocketSubscription, GenericUpdatesMessage } from '../service/websocket.service';
import { NotificationService } from "../service/notification.service";
import { TaskGroup, TaskStatus } from "../progress-tracker/progress-tracker.component";
import { NotificationStatuses } from "../constants/notification-statuses";

@Component({
  selector: 'app-gg-test',
  templateUrl: './gg-test.component.html',
  styleUrl: './gg-test.component.css'
})
export class GGTestComponent implements OnInit, OnDestroy {
  JSON = JSON; // Expose JSON object to the template

  // Hold websocket subscription
  private subscriptionTEST: WebSocketSubscription = { subscriber: "GGTestComponent", messageType: ALL_MESSAGE_TYPES, callback: msg => this.messages.push(msg) };

  public messages: any[] = [];
  public response: any;

  public tasks: TaskGroup[];

  constructor(private websocketService: WebSocketService, public theme: ThemeService, public notificationService: NotificationService) {
    this.tasks = [];
    let tg_id = 0;
    let t_id = 0;
    this.tasks.push(
      {
        id: "tg_" + tg_id,
        name$: of("Task group #" + tg_id++),
        tasks: [
          {
            id: "t_" + t_id,
            name$: of('Task #' + t_id++),
            status: TaskStatus.Completed,
            description$: of(""),
          },
          {
            id: "t_" + t_id,
            name$: of('Task #' + t_id++),
            status: TaskStatus.Completed,
            description$: of(""),
          },
          {
            id: "t_" + t_id,
            name$: of('Task #' + t_id++),
            status: TaskStatus.InProgress,
            description$: of(""),
          },
          {
            id: "t_" + t_id,
            name$: of('Task #' + t_id++),
            status: TaskStatus.Pending,
            description$: of(""),
          },
          {
            id: "t_" + t_id,
            name$: of('Task #' + t_id++),
            status: TaskStatus.Pending,
            description$: of(""),
          },
          {
            id: "t_" + t_id,
            name$: of('Task #' + t_id++),
            status: TaskStatus.Pending,
            description$: of(""),
          },
          {
            id: "t_" + t_id,
            name$: of('Task #' + t_id++),
            status: TaskStatus.Pending,
            description$: of(""),
          },
        ],
      },
      {
        id: "tg_" + tg_id,
        name$: of("Task group #" + tg_id++),
        tasks: [
          {
            id: "t_" + t_id,
            name$: of('Task #' + t_id++),
            status: TaskStatus.Completed,
            description$: of(""),
          },
          {
            id: "t_" + t_id,
            name$: of('Task #' + t_id++),
            status: TaskStatus.InProgress,
            description$: of(""),
          },
          {
            id: "t_" + t_id,
            name$: of('Task #' + t_id++),
            status: TaskStatus.Pending,
            description$: of(""),
          },
        ],
      },
      {
        id: "tg_" + tg_id,
        name$: of("Task group #" + tg_id++),
        tasks: [
          {
            id: "t_" + t_id,
            name$: of('Task #' + t_id++),
            status: TaskStatus.Pending,
            description$: of(""),
          },
          {
            id: "t_" + t_id,
            name$: of('Task #' + t_id++),
            status: TaskStatus.Pending,
            description$: of(""),
          },
          {
            id: "t_" + t_id,
            name$: of('Task #' + t_id++),
            status: TaskStatus.Pending,
            description$: of(""),
          },
        ],
      },
    );
  }

  ngOnInit(){
    this.websocketService.subscribe(this.subscriptionTEST);
  }

  ngOnDestroy(){
    this.websocketService.unsubscribe(this.subscriptionTEST);
  }

  sendMessage(){
    let message: GenericUpdatesMessage = {
      ACTION:'TEST',
      CONTENT: {
        CHANGES: [
          {
            NEW_STATUS: NotificationStatuses.NEW,
            IDs: [""],
          }
        ]
      },
    };
    this.websocketService.send(message);
  }
}

export module debug{

  export function removeMeta(obj: any) {
    for(let prop in obj) {
      if (prop === 'geometry')
        delete obj[prop];
      else if (typeof obj[prop] === 'object')
        removeMeta(obj[prop]);
    }

    return obj;
  }
}
