<div 
[ngClass]="theme.getThemeClass()"
class="main-container">
<div class="closeBTN">
 <span
 (click)="closeModal()" 
 ><i class="fa-solid fa-xmark"></i></span>
</div>
    <div class="modalBody">
        <aside>
            <ul class="navList">
                <li class="navItem" (click)="navigate(1)">Customer Name: {{clientContact.client_name}}</li>
                <li class="navItem" (click)="navigate(2)">Customer location: {{clientContact.address }}</li>
                <li class="navItem" (click)="navigate(3)">Customer phone: {{clientContact.phone_number}}</li>
            </ul>
        </aside>
        <section class="modelBody">
            <article class="modalTab" [hidden]="communiation">call </article>
            <article class="modalTab" [hidden]="position">location</article>
           <!--  <article class="modalTab"></article> -->
        </section>
    </div>
</div>