import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from "rxjs";
import {
  BaseMessage,
  GenericUpdatesMessage,
  WebSocketService,
  WebSocketSubscription,
  WebSocketTypeGuards
} from "./websocket.service";
import { CognitoService } from "./cognito.service";
import { NotificationStatuses } from "../constants/notification-statuses";

export interface Notification {
  id: string;
  type: string;
  title: string;
  body: string;
  timestamp: number;

  /**
   * Property that tracks whether the user has read (aka clicked) the notification or not
   * <br>
   * Possible values are 'N' (NEW), 'S' (SEEN), 'R'(READ)
   */
  status: string;

  // Currently unused
  icon: string;
  // Added property to track displayed state
  displayed: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private userSub: string = '';

  // List of all notifications for the current userSub
  public notifications: Notification[] = [];
  public unreadNotificationsCount: number = 0;

  // Subscription to listen to incoming "NOTIFICATION" messages
  private wsNotifications: WebSocketSubscription = {
    subscriber: "NotificationService",
    messageType: "NOTIFICATION",
    callback: this.notificationReceived.bind(this),
  };

  // Subscription to listen to incoming "ERROR_NOTIFICATION_STATUS_UPDATE" messages
  private wsNotificationUpdateErrors: WebSocketSubscription = {
    subscriber: "NotificationService",
    messageType: "ERROR_NOTIFICATION_STATUS_UPDATE",
    callback: (msg) => { console.error(msg) },
  };

  constructor(private http: HttpClient, private webSocketService: WebSocketService, private cognitoService: CognitoService) {
    this.webSocketService.subscribe(this.wsNotifications);
    this.webSocketService.subscribe(this.wsNotificationUpdateErrors);
    this.cognitoService.getCurrentUserSub().then(userSub => {
      this.userSub = userSub;
      this.loadNotifications();
    });
  }

  /**
   * Callback that handles incoming notifications from the websocket
   *
   * @param msg
   * @private
   */
  private notificationReceived(msg: BaseMessage) {
    if(WebSocketTypeGuards.isNotificationMsg(msg)){
      let newNotification: Notification = {
        id: msg.CONTENT.ID,
        type: "",
        title: msg.CONTENT.TITLE,
        body: msg.CONTENT.BODY,
        timestamp: msg.CONTENT.TIMESTAMP,
        displayed: false,
        icon: msg.CONTENT.ICON,
        status: NotificationStatuses.NEW,
      };
      this.notifications.push(newNotification);
    }

    this.recountNewNotifications();
  }

  /**
   * Recounts the number of unread notifications (this.unreadNotifications)
   */
  public recountNewNotifications(){
    this.unreadNotificationsCount = 0;
    this.notifications.forEach((notification: Notification) => {
      if(notification.status == NotificationStatuses.NEW)
        this.unreadNotificationsCount++;
    });
  }

  /**
   * Loads notifications from the server for current userSub
   */
  public loadNotifications(): void {
    const url = environment.api.stage + environment.api.route.getNotificationByUsersub + '&userSub=' + this.userSub;
    firstValueFrom(this.http.get<Notification[]>(url)).then(notifications => {
      if(notifications && Array.isArray(notifications)){
        notifications.forEach((notification: Notification) => {
          this.notifications.push({
            id: notification.id,
            title: notification.title,
            body: notification.body,
            type: notification.type,
            timestamp: notification.timestamp,
            status: notification.status,

            // Currently unused
            icon: '',
            displayed: false,
          });
        });

        this.recountNewNotifications();
      }
    }).catch(err => console.log(err));
  }

  /**
   * Marks all 'NEW' notifications as 'SEEN' (aka seen but not interacted with yet)
   */
  public markAllNewNotificationsAsSeen() {
    // Array to hold notifications getting updated
    let notificationList: Notification[] = [];

    // Select only notifications still marked as NEW
    this.notifications.forEach((notification) => {
      if(notification.status == NotificationStatuses.NEW) {
        notification.status = NotificationStatuses.SEEN;
        notificationList.push(notification);
      }
    });

    // Notify the server that those notifications changed status
    let payload: GenericUpdatesMessage = {
      ACTION: "NOTIFICATION_STATUS_UPDATE",
      CONTENT: {
        CHANGES: [
          {
            NEW_STATUS: NotificationStatuses.SEEN,
            IDs: notificationList.map<any>(notification => notification.id),
          },
        ],
      }
    };

    // Make sure payload contains at least one notification getting updated before sending to server
    if(payload.CONTENT.CHANGES[0]['IDs'].length != 0)
      this.webSocketService.send(payload);

    this.recountNewNotifications();
  }

  /**
   * This function sets the status on the notification matching the ID.
   * <br>
   * It then relays this information to the websocket server
   *
   * @param notificationId
   * @param newStatus
   */
  public updateNotificationStatus(notificationId: string, newStatus: string) {
    this.notifications.forEach(notification => {
      if(notification.id === notificationId){
        // Prevent useless updates
        if(notification.status == newStatus)
          return;

        notification.status = newStatus;

        // Notify the server that this notification changed status
        let payload: GenericUpdatesMessage = {
          ACTION: "NOTIFICATION_STATUS_UPDATE",
          CONTENT: {
            CHANGES: [
              {
                NEW_STATUS: newStatus,
                IDs: [notificationId],
              },
            ],
          }
        };
        this.webSocketService.send(payload);
      }
    });
  }
}

