<app-system-message></app-system-message>
<app-assign-route-form-modal class="appAssignRouteFormModal" *ngIf="showAssignRouteModal"> </app-assign-route-form-modal>
<app-loading-element *ngIf="loading" class="container-fluido componentBelow"></app-loading-element>

<!-- PAGE TITLE -->
<header *ngIf="!loading" [ngClass]="theme.getThemeClass()" class="container-fluid d-flex justify-content-center">
    <h2 class="pageLabel">{{"dispatchControlCenterTitle" | translate}}</h2>
</header>

<main *ngIf="!loading" [ngClass]="theme.getThemeClass()" cdkDropListGroup class="container-fluid">
    <section class="row mb-2">
        <!-- PAGE MAIN CONTENT -->
        <section  class="col-8">
            <!-- ROUTE LIST -->
            <h3 class="carrousel-label">{{"availableRoutes" | translate}}</h3>
            <nav class="scroll-container">
                <a class="linkScrollLeft" (click)="scrollLeft()"><div id="triangle-left"></div></a>
                <div  class="scrollable-content">
                    <ul #scrollContainer cdkDropList
                    id="routesList"
                    cdkDropListOrientation="horizontal"
                    class="horizontal-list flex-nowrap"
                    [cdkDropListData]="routeList"
                    (cdkDropListDropped)="drop($event)"
                    [ngClass]="{'empty-horizontal-list': routeArray.length === 0}">
                        <li *ngFor="let route of routeArray"
                        class="route-card"
                        cdkDrag
                        [cdkDragData]="route">
                            <a (click)="showInfoRoute(route)" class="text-decoration-none">
                                <article [ngClass]="theme.getThemeClass()"  class="card w-400 articleCard">
                                    <div class="card-body">
                                        <div class="rowRoutes">
                                            <div class="clientCardLabel">
                                                <p class="card-text ms-1 cardTitle">
                                                    <strong>
                                                        {{ route.legal_name }}
                                                    </strong>
                                                </p>
                                            </div>
                                            <div class="d-flex align-items-end">
                                                <div class="col-6">
                                                    <div class="subItems">
                                                        <p class="card-text mt-3">{{ "Waste Type" | translate }} </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-0 subItemsBig textOverflow">
                                                            <strong class="textOverflow" [title]="route.bin_usage | translate">
                                                                {{ route.bin_usage | translate }}
                                                            </strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-6 secondColumnCard">
                                                    <div class=" subItems">
                                                        <p class="card-text mt-2">{{ "cardStopsLabel" | translate }}</p>
                                                    </div>
                                                    <p class="mb-0 subItemsBig">
                                                        <strong>
                                                            {{ route.stops.length }}
                                                        </strong>
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="d-flex align-items-end">
                                                <div class="col-6">
                                                    <div class="subItems">
                                                        <p class="card-text mt-3">{{ "cardDateLabel" | translate }} </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-0 subItemsBig textOverflow">
                                                            <strong class="textOverflow" [title]="route.bin_usage | translate">
                                                                {{ route.formattedTimestamp }}
                                                            </strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-6 secondColumnCard">
                                                    <div class=" subItems">
                                                        <p class="card-text mt-2">{{ "Average Time" | translate }}</p>
                                                    </div>
                                                    <p class="mb-0 subItemsBig">
                                                        <strong>
                                                            <!-- {{ isArray(route) ? convertSecondsToMinutesOnly(route[0].approximate_time) : convertSecondsToMinutesOnly(route.approximate_time) }} -->
                                                            {{ calculateTotalTime(route) }} {{ "minutes" | translate }}


                                                        </strong>
                                                    </p>
                                                </div>
                                            </div>




                                            <div class="col-6 subItems">
                                                <!-- <p class="card-text mt-2">{{ "cardDateLabel" | translate }}</p> -->
                                            </div>
                                            <div class="d-flex">
                                                <p class="mb-0 subItemsBig">
                                                    <strong>
                                                         {{ route.formattedTimestamp }}
                                                    </strong>
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </article >
                            </a>
                        </li>
                    </ul>
                </div>
                <a (click)="scrollRight()" class="text-decoration-none"><div id="triangle-right"></div></a>
            </nav>
            <!-- TRUCK LIST -->
            <nav class="truck-columns-wrapper  mt-3 d-flex">
                <a (click)="scrollLeftTrucks()" class="text-decoration-none"><div id="triangle-left"></div></a>
                <ul class="truck-columns">
                    <li *ngFor="let truck of truckList" class="truck-column mb-">
                        <!-- Truck Title Row -->
                        <div class="truckItem d-flex justify-content-between align-items-center">
                            <h4 (click)="showInfoTruck(truck)" class="truckId" [title]="truck.nickname">{{ truck.nickname }}</h4>
                            <div class="d-flex align-items-center">
                                <div class="truckStatus me-3">
                                    <a  href="javascript:void(0)" (click)="toggleTruckLock(truck)" class="text-decoration-none">
                                        <svg *ngIf="truck.closedRoute" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-lock" viewBox="0 0 16 16">
                                            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2M5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1"/>
                                        </svg>
                                    </a>
                                    <a href="javascript:void(0)" (click)="toggleTruckLock(truck)" class="text-decoration-none">
                                        <svg *ngIf="!truck.closedRoute" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-unlock" viewBox="0 0 16 16">
                                            <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2M3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1z"/>
                                        </svg>
                                    </a>
                                </div>
                                <nav>
                                    <a class="nav-link e" href="javascript:void(0);" data-bs-toggle="dropdown">
                                        <i class="fa fa-bars hambIcon"></i>
                                    </a>
                                    <ul [ngClass]="theme.getThemeClass()" class="dropdown-menu dropdown-menu-lg-end">
                                        <li>
                                            <a [class.disabled-link]="truck.closedRoute" (click)="!truck.closedRoute && assignDriver(truck)" [ngClass]="theme.getThemeClass()" class="dropdown-item cursor pointer">
                                                <span class="dropItemSpan">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-truck mr10" viewBox="0 0 16 16">
                                                    <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5zm1.294 7.456A2 2 0 0 1 4.732 11h5.536a2 2 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456M12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2"/>
                                                </svg>
                                                {{ "dropDownAssignDriverLabel" | translate }}
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a [class.disabled-link]="truck.closedRoute" (click)="!truck.closedRoute && assignDriver(truck)" [ngClass]="theme.getThemeClass()" class="dropdown-item cursor pointer" href="javascript:void(0)">
                                                <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-patch-plus mr5" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5"/>
                                                    <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
                                                </svg>
                                                {{ "dropDownCreateWorkOrderLabel" | translate }}
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a [ngClass]="theme.getThemeClass()" class="dropdown-item cursor pointer" href="javascript:void(0)" >
                                                <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-right mr5" viewBox="0 0 16 16">
                                                <path d="M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/>
                                                </svg>
                                                {{"dropDownSendMessageLabel" | translate }}
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div class="section-triangle">
                            <a class="vertical-triangle text-decoration-none" (click)="scrollUp(truck.truck_id)"><div id="triangle-up"></div></a>
                            <a class="vertical-triangle text-decoration-none" (click)="scrollDown(truck.truck_id)"><div id="triangle-down"></div></a>
                        </div>
                        <!-- Route List @ Truck List -->
                        <ul cdkDropList
                        class="vertical-list mt-2"
                        [attr.data-truck-id]="truck.truck_id"
                        [cdkDropListData]="truck.routes"
                        (cdkDropListDropped)="drop($event)"
                        [ngClass]="{'empty-list': truck.routes.length === 0}"
                        [cdkDropListDisabled]="truck.closedRoute">
                            <li *ngFor="let route of truck.routes "
                            class="card mb-1"
                            cdkDrag
                            [cdkDragData]="route">
                                <article (click)="showInfoRoute(route)" class="card w-400 articleCard" [ngClass]="theme.getThemeClass()">
                                    <div class="card-body articleCard">
                                        <div class="rowRoutes">
                                            <div class="clientCardLabel">
                                                <p class="card-text ms-1 cardTitle">
                                                    <strong>
                                                        {{ route.legal_name }}
                                                    </strong>
                                                </p>
                                            </div>
                                            <div class="d-flex align-items-end">
                                                <div class="col-6 adjustSmallScreen">
                                                    <div class="subItems">
                                                        <p class="card-text mt-3">{{ "Waste Type" | translate }} </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-0 subItemsBig textOverflow">
                                                            <strong class="textOverflow" [title]="route.bin_usage | translate">
                                                                {{ route.bin_usage | translate }}
                                                            </strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-6 secondColumnCard">
                                                    <div class=" subItems">
                                                        <p class="card-text mt-2">{{ "cardStopsLabel" | translate }}</p>
                                                    </div>
                                                    <p class="mb-0 subItemsBig">
                                                        <strong>
                                                            {{ route.stops.length }}
                                                        </strong>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-end">
                                                <div class="col-6 adjustSmallScreen">
                                                    <div class="subItems">
                                                        <p class="card-text mt-3">{{ "cardDateLabel" | translate }} </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-0 subItemsBig textOverflow">
                                                            <strong class="textOverflow" [title]="route.bin_usage | translate">
                                                                {{ route.formattedTimestamp }}
                                                            </strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-6 secondColumnCard">
                                                    <div class=" subItems">
                                                        <p class="card-text mt-2">{{ "Average Time" | translate }}</p>
                                                    </div>
                                                    <p class="mb-0 subItemsBig">
                                                        <strong>
                                                            {{ calculateTotalTime(route) }} {{ "minutes" | translate }}
                                                        </strong>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </li>
                        </ul>
                    </li>
                </ul>
                <a (click)="scrollRightTrucks()" class="text-decoration-none"><div id="triangle-right"></div></a>
            </nav>
        </section >
        <!-- SIDE INFORMATION PANEL -->
        <aside class="col-4">
            <section class="info-panel card p-3" [ngClass]="theme.getThemeClass()">
                <article class="card-body" [ngClass]="theme.getThemeClass()">
                    <!-- ROUTE INFO SECTION -->
                    <div *ngIf="selectedRoute" [ngClass]="theme.getThemeClass()">
                        <div class="dumpster-container">
                            <div class="col-4">
                                <img src="../../assets/dumpsterbluepng.png" height="500" width="500" alt="garbage truck" class=" dumpster">
                            </div>
                            <div class="col-8 rightColumn">
                                <div> <!-- f line -->
                                    <p style="margin-left: -3rem;" class="card-text-panel titleSize">
                                        {{ selectedRoute.legal_name }}
                                    </p>
                                </div>
                                <div class="d-flex secondLine"> <!-- s line r column -->
                                    <div class="col-8 "> <!-- f item -->
                                        <p class="card-subtext-panel">
                                            {{ selectedRoute.bin_usage | translate }}
                                        </p>
                                    </div>
                                    <div> <!-- s item 2 row -->
                                        <p  class="card-subtext-panel">
                                            {{ selectedRoute.stops.length }} {{"stops" | translate}}
                                        </p>
                                    </div>
                                </div>
                                <div class="d-flex thirdLine" > <!-- s line r column -->
                                    <div class="col-8"> <!-- f item s column -->
                                        <p style="margin-left: -3rem; margin-top: -10px; font-size: 1rem;" class="card-subtext-panel">
                                            {{ calculateRouteVolume(selectedRoute) }} L
                                        </p>
                                    </div>
                                    <div> <!-- s item s row -->
                                        <p style="margin-left: -3rem; margin-top: -10px;" class="card-subtext-panel">
                                            {{ selectedRoute.formattedTimestamp }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="routeList-container">
                            <ul class="infoRouteContainer">
                                <li *ngFor="let stop of selectedRoute.stops; let i = index" >
                                    <div class="mt-1">
                                        <strong>
                                            #{{ i + 1 }}: {{ calculateRouteVolume(selectedRoute) }} L - {{ "Average Time: " | translate }}  {{ calculateTotalTime(selectedRoute) }} {{ "minutes" | translate }}<!-- {{ getBinUsage(route) | translate }} -  -->
                                        </strong>
                                    </div>
                                    <div class="mb-1"  >
                                        <strong>
                                            {{ stop.bin_address | translate }}
                                        </strong>
                                    </div>
                                    <div style="border-bottom: 1px solid #dee2e6;">

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- TRUCK INFO SECTION -->
                    <div *ngIf="selectedTruck" class="truckInfoContainer d-flex" [ngClass]="theme.getThemeClass()">
                        <div class="truckInfoTitleRow row w-100 align-items-center">

                            <div class="d-flex align-items-center col-6 p-0">
                              <div class="truckIdTile col-6 nickname">
                                {{ selectedTruck.nickname  }}
                              </div>
                              <div *ngIf="selectedTruck.status == '0'" class="green col-6 routeStatsTile ms-2 d-flex">
                                <i class="statusSizeIcon fas fa-directions fa-beat-fade">  </i>
                                <div class="status green ms-1">
                                    {{ "Available" | translate }}
                                </div>
                              </div>

                              <div *ngIf="selectedTruck.status == '4'" class="red col-6 routeStatsTile ms-2 d-flex">
                                <i class="statusSizeIcon fas fa-directions fa-beat-fade">  </i>
                                <div class="status red ms-1">
                                    {{ "On Route" | translate }}
                                </div>
                              </div>
                            </div>
                            <!-- Right-aligned buttons -->
                            <div class="col-6 d-flex justify-content-end p-0 buttonRowSmallScreen">
                                <button class="btn-primary chatDriverButton">
                                    <i class="fa-solid fa-phone hideSmallScreen"></i> {{ "Call Driver" | translate }}
                                </button>
                                <button class="btn-primary chatDriverButton">
                                <i class="fa-regular fa-comments hideSmallScreen"></i> {{ "Chat with Driver" | translate }}
                                </button>
                            </div>
                        </div>
                        <div class="truckInfoNavBar mt-2 row w-100 align-items-center" [ngClass]="theme.getThemeClass()">
                            <ul class="nav nav-tabs w-100" [ngClass]="theme.getThemeClass()">
                                <li class="nav-item" [ngClass]="theme.getThemeClass()">
                                    <a [ngClass]="theme.getThemeClass()" class="nav-link active" href="#vehicle-info" data-bs-toggle="tab">{{ "Vehicle Info" | translate }}</a>
                                </li>
                                <li [ngClass]="theme.getThemeClass()" class="nav-item">
                                    <a  [ngClass]="theme.getThemeClass()" class="nav-link" href="#documents" data-bs-toggle="tab">{{ "Route" | translate }}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="tab-content w-100">
                            <div class="tab-pane fade show active" id="vehicle-info">
                                <div class="vehicleInfoTitleRow">
                                    <div class="truckIdSubTile col-6 vehicleInfoTitle mt-1" >
                                        {{ "truckDriverName" | translate }} {{ getCurrentTruckDriverName(selectedTruck.driver_id) }}
                                    </div>
                                    <div class="truckIdSubTile col-6 vehicleInfoTitle" >
                                        {{ "Vehicle Total Capacity: " | translate }} {{ selectedTruck.capacity }}{{ "L" | translate }}
                                    </div>
                                    <div class="truckIdSubTile col-6 vehicleInfoTitle" >
                                        {{ "Estimated Fill: " | translate }} {{ calculatedWeight }}{{ "L" | translate }}
                                    </div>

                                    <div class="truck-container">
                                        <div class="progressHorizontal horizontal" [ngStyle]="{'transform': 'scaleX(' + (startLevel / 100) + ')'}"></div>
                                        <div class="progress-text">{{ fillLevelBigTruck }}%</div>
                                        <img src="../../assets/truckpng.png" height="500" width="500" alt="garbage truck" class="truck bigTruck">
                                    </div>
                                </div>

                            </div>
                            <div class="tab-pane fade mt-4" id="documents">
                                <app-progress-tracker [taskGroups]="selectedTruckItinerary"></app-progress-tracker>
                            </div>
                        </div>
                    </div>
                </article>
            </section>
        </aside>
    </section>
</main>


<!--
TODO Function calls to extract/change
-->
